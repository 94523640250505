<div class="input-group input-group-sm">
    <input type="number" class="form-control" [disabled]="disabled" *ngFor="let spec of specs; index as i"
           [(ngModel)]="displayValue[i]" (ngModelChange)="setValue($event, i)" placement="top"
           ngbTooltip="{{spec.description}}" placeholder="{{spec.name}}" style="text-align: right;">
    <div class="input-group-append" ngbDropdown>
        <button tabindex="-1" class="btn btn-outline-primary" ngbDropdownToggle>{{unit}}</button>
        <div class="dropdown-menu" ngbDropdownMenu>
            <button ngbDropdownItem *ngFor="let u of units" (click)="setUnit(u)">{{u}}</button>
        </div>
    </div>
</div>
