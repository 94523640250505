import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ParameterSchemaService} from '../services/parameter-schema.service';
import {isArray} from 'util';
import {ParameterSpec} from '../../../target/fabber-model';
import {UnitsService} from '../services/units/units.service';

@Component({
    selector: 'app-parameter-field',
    templateUrl: './parameter-field.component.html',
    styleUrls: ['./parameter-field.component.scss']
})
export class ParameterFieldComponent implements OnInit {

    @Input() type: string;
    @Input() field: string | string[];
    @Input() disabled = false;

    @Input()
    get object() {
        return this.objectValue;
    }

    set object(o: any) {
        this.objectValue = o;
        this.objectChange.emit(this.objectValue);
        this.getValue();
    }

    @Output() objectChange = new EventEmitter();

    private objectValue: any;
    private n: number;
    public specs: ParameterSpec[];
    public unit: string;
    public units: string[];
    public displayValue: number[];

    constructor(private parameterSchemaService: ParameterSchemaService, private unitsService: UnitsService) {
    }

    ngOnInit() {
        if (isArray(this.field)) {
            this.n = (this.field as string[]).length;
            this.specs = (this.field as string[]).map((f) => this.parameterSchemaService.getParameterSpec(this.type, f));
        } else {
            this.n = 1;
            this.specs = new Array<ParameterSpec>(this.parameterSchemaService.getParameterSpec(this.type, this.field as string));
        }
        this.displayValue = new Array<number>();
        this.units = new Array<string>();
        this.specs.forEach((spec) => {
            this.displayValue.push(null);
            this.unitsService.unitsForUnit(spec.unit).forEach((u) => {
                if (this.units.indexOf(u) < 0) {
                    this.units.push(u);
                }
            });
        });
        this.unit = this.unitsService.selectedForUnit(this.specs[0].unit);
        this.getValue();
    }

    setUnit(u: string) {
        const previousUnit = this.unit;
        if (u !== previousUnit) {
            this.unit = u;
            // Convert all display values
            this.displayValue = this.displayValue.map((v) => {
                if (v) {
                    return this.unitsService.convert(v, previousUnit, u);
                }
                return v;
            });
        }
    }

    getValue() {
        if (!this.displayValue) {
            return;
        }
        if (this.objectValue) {
            if (isArray(this.field)) {
                (this.field as string[]).forEach((f, i) => {
                    this.displayValue[i] = this.unitsService.convertQuantity(this.objectValue[f], this.unit);
                });
            } else {
                this.displayValue[0] = this.unitsService.convertQuantity(this.objectValue[this.field as string], this.unit);
            }
        } else {
            if (isArray(this.field)) {
                (this.field as string[]).forEach((f, i) => {
                    this.displayValue[i] = null;
                });
            } else {
                this.displayValue[0] = null;
            }
        }
    }

    setValue(value: number, i: number) {
        console.log(this.objectValue);
        if (this.objectValue) {
            let realValue;
            if (value) {
                realValue = '' + value + ' ' + this.unit;
            } else {
                realValue = null;
            }
            if (isArray(this.field)) {
                this.objectValue[(this.field as string[])[i]] = realValue;
            } else {
                this.objectValue[this.field as string] = realValue;
            }
            this.objectChange.emit(this.objectValue);
        }
    }

}
