<div class="input-group input-group-sm">
    <input type="number" class="form-control" placeholder="Length" [min]="minLengthValue" [max]="maxLengthValue"
           [disabled]="disabled" [ngModel]="lengthValue" (ngModelChange)="setLength($event)">
    <div class="input-group-prepend input-group-append">
        <span class="input-group-text">x</span>
    </div>
    <input type="number" class="form-control" placeholder="Width" [min]="minWidthValue" [max]="maxWidthValue"
           [disabled]="disabled" [ngModel]="widthValue" (ngModelChange)="setWidth($event)">
    <div class="input-group-append" ngbDropdown>
        <button class="btn btn-outline-primary" ngbDropdownToggle [disabled]="disabled">
            <fa-icon [icon]="['fas', 'list']"></fa-icon>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
            <button ngbDropdownItem *ngFor="let materialSheet of availableSizes"
                    (click)="setSize(materialSheet)">{{materialSheet.sheetSize.length | unit}}
                x {{materialSheet.sheetSize.width | unit}}</button>
        </div>
    </div>
    <div class="input-group-append" ngbDropdown>
        <button class="btn btn-outline-primary" ngbDropdownToggle [disabled]="disabled">{{unit}}</button>
        <div class="dropdown-menu" ngbDropdownMenu>
            <button ngbDropdownItem *ngFor="let u of units" (click)="setUnit(u)">{{u}}</button>
        </div>
    </div>
</div>
