import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export var AlertType;
(function (AlertType) {
    AlertType[AlertType["SUCCESS"] = 0] = "SUCCESS";
    AlertType[AlertType["INFO"] = 1] = "INFO";
    AlertType[AlertType["WARNING"] = 2] = "WARNING";
    AlertType[AlertType["DANGER"] = 3] = "DANGER";
})(AlertType || (AlertType = {}));
export var AlertTime;
(function (AlertTime) {
    AlertTime[AlertTime["SHORT"] = 4000] = "SHORT";
    AlertTime[AlertTime["LONG"] = 10000] = "LONG"; // ms
})(AlertTime || (AlertTime = {}));
export class AlertService {
    constructor() {
        this.parser = new DOMParser();
        this.status = new BehaviorSubject(null);
    }
    stripHTML(message) {
        const doc = this.parser.parseFromString(message, 'text/html');
        return doc.body.textContent || message;
    }
    getStatus() {
        return this.status;
    }
    show(alert) {
        this.status.next(alert);
    }
    alert(type, time, message) {
        console.log(message);
        if (typeof message === 'string') {
            this.show({ type: type, time: time, message: this.stripHTML(message) });
        }
        else {
            const messageObject = message;
            if (messageObject['error']) {
                this.alert(type, time, messageObject['error']);
            }
            else if (messageObject['statusText']) {
                this.alert(type, time, messageObject['statusText']);
            }
            else if (messageObject['message']) {
                this.alert(type, time, messageObject['message']);
            }
            else {
                this.alert(type, time, messageObject.toString());
            }
        }
    }
    warning(message) {
        this.alert(AlertType.WARNING, AlertTime.LONG, message);
    }
    error(message) {
        this.alert(AlertType.DANGER, AlertTime.LONG, message);
    }
    info(message) {
        this.alert(AlertType.INFO, AlertTime.LONG, message);
    }
    success(message) {
        this.alert(AlertType.SUCCESS, AlertTime.LONG, message);
    }
}
AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(); }, token: AlertService, providedIn: "root" });
