import * as i0 from "@angular/core";
export class FormHelperService {
    hasError(form, ...prop) {
        const control = this.getControl(form, ...prop);
        return !control.valid && control.touched;
    }
    getControl(form, ...prop) {
        if (prop.length === 1) {
            return form.controls[prop[0]];
        }
        return this.getControl(form.controls[prop[0]], ...prop.slice(1));
    }
}
FormHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormHelperService_Factory() { return new FormHelperService(); }, token: FormHelperService, providedIn: "root" });
