<div class="container mt-2" *ngIf="(bit$ | async) as bit">
    <h3><span *ngIf="editable(bit)">Edit</span><span *ngIf="!editable(bit)">View</span> Bit: {{bit.name}}</h3>
    <div class="form-group">
        <label for="inputName">Name</label>
        <app-text-field id="inputName" type="bit"
                        [field]="['name']" [(object)]="bit"
                        [disabled]="!editable(bit)"></app-text-field>
    </div>
    <div class="form-group">
        <label for="inputDiameter">Diameter (Cutting Edge and Shaft)</label>
        <app-parameter-field id="inputDiameter" type="bit" [field]="['diameter','shaftDiameter']" [(object)]="bit"
                             [disabled]="!editable(bit)"></app-parameter-field>
    </div>
    <div class="form-group">
        <label for="inputLength">Length (Cutting Edge and Total)</label>
        <app-parameter-field id="inputLength" type="bit" [field]="['cuttingEdgeLength', 'overallLength']"
                             [(object)]="bit" [disabled]="!editable(bit)"></app-parameter-field>
    </div>
    <div class="form-group">
        <h3>Materials</h3>
        <table class="table table-bordered table-sm">
            <thead>
            <tr>
                <th scope="col">Material</th>
                <th scope="col">Chipload</th>
                <th scope="col">Cutting Speed</th>
                <th scope="col">Pass Depth Multiplier</th>
                <th scope="col">Spindle Speed</th>
                <th scope="col" *ngIf="editable(bit)"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let material of bit.materialSettings; index as i">
                <td>
                    <app-text-field id="inputMaterial{{i}}" type="bitmaterial"
                                    [field]="['material']" [(object)]="material"
                                    [options]="allMaterials"
                                    [disabled]="!editable(bit)"></app-text-field>
                </td>
                <td>
                    <app-parameter-field id="inputChipload{{i}}" type="bitmaterial"
                                         [field]="['chipLoad']" [(object)]="material"
                                         [disabled]="!editable(bit)"></app-parameter-field>
                </td>
                <td>
                    <app-parameter-field id="inputCuttingSpeed{{i}}" type="bitmaterial"
                                         [field]="['minCuttingSpeed', 'maxCuttingSpeed']" [(object)]="material"
                                         [disabled]="!editable(bit)"></app-parameter-field>
                </td>
                <td>
                    <app-number-field id="inputPassDepthMultiplier{{i}}" type="bitmaterial"
                                      [field]="['passDepthMultiplier']" [(object)]="material"
                                      [disabled]="!editable(bit)"></app-number-field>
                </td>
                <td>
                    <app-parameter-field id="inputSpindleSpeed{{i}}" type="bitmaterial"
                                         [field]="['spindleSpeed']" [(object)]="material"
                                         [disabled]="!editable(bit)"></app-parameter-field>
                </td>
                <td *ngIf="editable(bit)">
                    <button type="button" class="btn btn-danger btn-sm float-right"
                            (click)="deleteMaterial(bit, material)">
                        <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                    </button>
                </td>
            </tr>
            <tr *ngIf="editable(bit)">
                <td>
                    <app-text-field id="inputMaterialNew" type="bitmaterial"
                                    [field]="['material']" [(object)]="newMaterial"
                                    [options]="allMaterials"
                                    [disabled]="!editable(bit)"></app-text-field>
                </td>
                <td>
                    <app-parameter-field id="inputChiploadNew" type="bitmaterial"
                                         [field]="['chipLoad']" [(object)]="newMaterial"
                                         [disabled]="!editable(bit)"></app-parameter-field>
                </td>
                <td>
                    <app-parameter-field id="inputCuttingSpeedNew" type="bitmaterial"
                                         [field]="['minCuttingSpeed', 'maxCuttingSpeed']" [(object)]="newMaterial"
                                         [disabled]="!editable(bit)"></app-parameter-field>
                </td>
                <td>
                    <app-number-field id="inputPassDepthMultiplierNew" type="bitmaterial"
                                      [field]="['passDepthMultiplier']" [(object)]="newMaterial"
                                      [disabled]="!editable(bit)"></app-number-field>
                </td>
                <td>
                    <app-parameter-field id="inputSpindleSpeedNew" type="bitmaterial"
                                         [field]="['spindleSpeed']" [(object)]="newMaterial"
                                         [disabled]="!editable(bit)"></app-parameter-field>
                </td>
                <td>
                    <button *ngIf="editable(bit)" class="mb-1 btn btn-primary btn-sm float-right"
                            (click)="addMaterial(bit)">
                        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <div class="col-md-9">
        </div>
        <div class="col-md-3">
            <button *ngIf="editable(bit)" class="btn btn-primary btn-lg float-right" (click)="save(bit)">
                <fa-icon [icon]="['fas', 'save']"></fa-icon>
                Save
            </button>
            <button *ngIf="editable(bit)" class="btn btn-primary-outline btn-lg float-right" (click)="cancel()">
                <fa-icon [icon]="['fas', 'ban']"></fa-icon>
                Cancel
            </button>
            <button *ngIf="!editable(bit)" class="btn btn-primary btn-lg float-right" (click)="cancel()">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                Back
            </button>
        </div>
    </div>
</div>
