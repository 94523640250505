import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { User, Invitation } from '../../../../target/fabber-model';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-invitations',
  templateUrl: './admin-invitations.component.html',
  styleUrls: ['./admin-invitations.component.scss']
})
export class AdminInvitationsComponent implements OnInit {
  public invitations: Array<Invitation>;
  public emails: string;
  constructor(private restangular: Restangular) {
      this.invitations = new Array<Invitation>();
      this.emails = "";
  }

  ngOnInit() {
      this.restangular.all('users').all('invitations').getList().subscribe((invitations) => {
         this.invitations = invitations; 
      });
  }

  invite() {
      this.restangular.one('users').post('invitations', this.emails).subscribe(
         (resp) => {
             this.restangular.all('users').all('invitations').getList().subscribe((invitations) => {
                 this.invitations = invitations;
                 this.emails = '';
             });             
         }
      );
  }
  
  revoke(id: string) {
      this.restangular.one('users').one('invitations', id).remove().subscribe(
         (resp) => {
             this.restangular.all('users').all('invitations').getList().subscribe((invitations) => {
                 this.invitations = invitations;
                 this.emails = '';
             });
         }
      );
  }
  
  resend(id: string, email: string) {
      this.restangular.one('users').one('invitations', id).remove().subscribe(
         (revokeresp) => {
             this.restangular.one('users').post('invitations', email).subscribe(
                 (resp) => {
                 this.restangular.all('users').all('invitations').getList().subscribe((invitations) => {
                    this.invitations = invitations;
                 });             
             });
      });
  }
}
