/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sheets.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "../post-process-button/post-process-button.component.ngfactory";
import * as i9 from "../post-process-button/post-process-button.component";
import * as i10 from "@angular/common/http";
import * as i11 from "../services/project.service";
import * as i12 from "ng-intercom";
import * as i13 from "@angular/router";
import * as i14 from "./sheets.component";
import * as i15 from "ng2-ui-auth";
import * as i16 from "../services/units/units.service";
import * as i17 from "@ngx-loading-bar/core";
import * as i18 from "../alert/alert.service";
var styles_SheetsComponent = [i0.styles];
var RenderType_SheetsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SheetsComponent, data: {} });
export { RenderType_SheetsComponent as RenderType_SheetsComponent };
function View_SheetsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "fabber-sidebar-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current Sheet"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select sheet to view and cut"])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "ngb-pagination", [["class", "d-flex justify-content-center"], ["role", "navigation"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.currentSheet = $event) !== false);
        ad = (pd_0 && ad);
    } if (("pageChange" === en)) {
        var pd_1 = (_co.selectSheet($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NgbPagination_0, i2.RenderType_NgbPagination)), i1.ɵdid(6, 573440, null, 6, i3.NgbPagination, [i3.NgbPaginationConfig], { disabled: [0, "disabled"], boundaryLinks: [1, "boundaryLinks"], rotate: [2, "rotate"], collectionSize: [3, "collectionSize"], maxSize: [4, "maxSize"], page: [5, "page"], pageSize: [6, "pageSize"] }, { pageChange: "pageChange" }), i1.ɵqud(603979776, 2, { tplEllipsis: 0 }), i1.ɵqud(603979776, 3, { tplFirst: 0 }), i1.ɵqud(603979776, 4, { tplLast: 0 }), i1.ɵqud(603979776, 5, { tplNext: 0 }), i1.ɵqud(603979776, 6, { tplNumber: 0 }), i1.ɵqud(603979776, 7, { tplPrevious: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading || _co.drawingParts); var currVal_1 = true; var currVal_2 = true; var currVal_3 = _co.nSheets; var currVal_4 = 3; var currVal_5 = _co.currentSheet; var currVal_6 = 1; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_SheetsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(2, 2)], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "fas", "lock-alt"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SheetsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(2, 2)], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "fas", "lock-open-alt"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SheetsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "fabber-sidebar-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit Sheet"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enable editing of sheet nesting"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "btn btn-outline-primary"], ["id", "enableEditing"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleEditing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SheetsComponent_3)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SheetsComponent_4)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editing; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.editing; _ck(_v, 10, 0, currVal_1); }, null); }
function View_SheetsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "fabber-sidebar-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download Code"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download the code here to cut the current sheet"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-post-process-button", [], null, null, null, i8.View_PostProcessButtonComponent_0, i8.RenderType_PostProcessButtonComponent)), i1.ɵdid(6, 114688, null, 0, i9.PostProcessButtonComponent, [i10.HttpClient, i11.ProjectService, i12.Intercom], { projectId: [0, "projectId"], sheetId: [1, "sheetId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.projectId; var currVal_1 = _co.sheetId; _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
function View_SheetsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "fabber-sidebar-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Selected Part"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Instance ", " of ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.currentPartInstance.replace((_v.context.ngIf.name + ":"), ""); var currVal_2 = _v.context.ngIf.instances.length; _ck(_v, 6, 0, currVal_1, currVal_2); }); }
export function View_SheetsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { viewerDiv: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "fabber-sidebar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "ml-1 mr-1 mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SheetsComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SheetsComponent_2)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SheetsComponent_5)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SheetsComponent_6)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 11, "div", [["class", "fabber-top-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 10, "div", [["class", "container-flex mt-1 ml-3 mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 8, "nav", [["aria-label", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 7, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 4, "li", [["class", "breadcrumb-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i13.RouterLinkWithHref, [i13.Router, i13.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(20, 2), (_l()(), i1.ɵted(-1, null, ["Select Parts"])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "li", [["aria-current", "page"], ["class", "breadcrumb-item active"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manufacture"])), (_l()(), i1.ɵeld(24, 0, [[1, 0], ["viewerDiv", 1]], null, 0, "div", [["class", "svgViewer"], ["id", "viewerDiv"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sheets != null); _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.loading && !_co.drawingParts); _ck(_v, 6, 0, currVal_1); var currVal_2 = (!_co.loading && !_co.drawingToolpath); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.currentPart)); _ck(_v, 10, 0, currVal_3); var currVal_6 = _ck(_v, 20, 0, "/project", _co.projectId); _ck(_v, 19, 0, currVal_6); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 19).target; var currVal_5 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_4, currVal_5); }); }
export function View_SheetsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sheets", [], null, null, null, View_SheetsComponent_0, RenderType_SheetsComponent)), i1.ɵdid(1, 4440064, null, 0, i14.SheetsComponent, [i11.ProjectService, i13.ActivatedRoute, i15.AuthService, i16.UnitsService, i3.NgbModal, i17.LoadingBarService, i18.AlertService, i6.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SheetsComponentNgFactory = i1.ɵccf("app-sheets", i14.SheetsComponent, View_SheetsComponent_Host_0, {}, {}, []);
export { SheetsComponentNgFactory as SheetsComponentNgFactory };
