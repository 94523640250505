import { Component, OnInit } from '@angular/core';
import {AuthService} from 'ng2-ui-auth';
import {ActivatedRoute, Router} from '@angular/router';
import {Restangular} from 'ngx-restangular';
import {UserService} from '../services/user.service';
import {AlertService} from '../alert/alert.service';

@Component({
    selector: 'app-sketchup',
    templateUrl: './sketchup.component.html',
    styleUrls: ['./sketchup.component.scss']
})
export class SketchupComponent implements OnInit {
    skp: string;
    fromSketchup: boolean;
    inProgress: boolean;
    loggedIn: boolean;
    constructor(private auth: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private restangular: Restangular,
                private userService: UserService,
                private alertService: AlertService) {

    }

    ngOnInit() {
        this.skp = this.route.snapshot.queryParams.skp;
        this.loggedIn = this.auth.isAuthenticated();
        if (this.skp == null) {
            this.fromSketchup = false;
            return;
        }
        this.fromSketchup = true;
        if (this.loggedIn) {
            this.connect();
        }
    }

    connect() {
        this.inProgress = true;
        this.restangular.one('auth', 'link').post('skp', {name: 'SketchUp', uuid: this.route.snapshot.queryParams.skp}).subscribe({
            error: (err) => {},
            complete: () => { this.inProgress = false; }
        });
    }

    signup() {
        this.inProgress = true;
        this.userService.signup().subscribe({
            error: (err) => { this.inProgress = false; this.alertService.error('Signup failed'); },
            complete: () => this.connect()
        });
    }

    login() {
        this.inProgress = true;
        this.auth.link('memberful').subscribe({
            error: (err) => { this.inProgress = false; this.alertService.error('Login failed'); },
            complete: () => { this.loggedIn = true; this.connect(); }
        });
    }

}
