<div #owner class="canvas-owner"  (resized)="onResize($event)">
    <canvas #canvas class="webgl-canvas">
    </canvas>
</div>
<div id="overlay" *ngIf="progressMap.size>0">
    <div id="spinner">
        <fa-icon [icon]="['fas', 'spinner']" spin="true" size="5x"></fa-icon>
      <p *ngFor="let id of progressMap.keys()">{{id}}: {{progressMap.get(id)*100}}%</p>
    </div>
</div>
