import { InjectionToken } from '@angular/core';

export const BASE_UNITSET = new InjectionToken<UnitSet>('base.units');

export class UnitSet {

  constructor() {
    this.units = new Array<Unit>();
  }

  units: Unit[];
}

export class Unit {
  unit: string;
  precision: number;
}
