
<as-split class="toolframe-outer" unit="percent" direction="horizontal" [gutterSize]="10" [useTransition]="true">
    <as-split-area [size]="0" [maxSize]="30" [minSize]="0">
        <ng-content select="three-tool-panel[placement=Left]"></ng-content>
    </as-split-area>
    <as-split-area [size]="70" [maxSize]="100" [minSize]="50">
        <!--            <as-split unit="percent" direction="vertical">-->
        <!--                <ng-content select="three-tool-panel[placement=Top]"></ng-content>-->
        <!--                <as-split-area size="*">-->
        <ng-content select="three-webgl-renderer"></ng-content>
            <ng-content select="three-toolbar[placement=Left]"></ng-content>
            <ng-content select="three-toolbar[placement=Top]"></ng-content>
            <ng-content select="three-toolbar[placement=Bottom]"></ng-content>
            <ng-content select="three-toolbar[placement=Right]"></ng-content>
        <!--                </as-split-area>-->
        <!--                <ng-content select="three-tool-panel[placement=Bottom]"></ng-content>-->
        <!--            </as-split>-->
    </as-split-area>
    <as-split-area [size]="30" [maxSize]="50" [minSize]="0">
        <ng-content select="three-tool-panel[placement=Right]"></ng-content>
    </as-split-area>

</as-split>
