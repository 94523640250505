import {Directive, Input} from '@angular/core';
import {AbstractObject3D} from '../three/objects/abstract-object-3d';
import * as THREE from 'three';
import {Slice} from '../../../target/fabber-model';
import {Paths} from './paths';

@Directive({
    selector: 'app-slice-geometry'
})
export class SliceGeometryDirective extends AbstractObject3D<THREE.Object3D> {
    @Input() slice: Slice;

    constructor() {
        super();
    }

    protected afterInit(): void {
        console.log('Slice afterInit');
        this.slice.features.forEach(feature => {
            console.log('Slice afterInit: feature');
            const paths = Paths.toPaths(feature.path);
            paths.forEach(path => {
                const points = path.getPoints();
                const geometry = new THREE.BufferGeometry().setFromPoints(points);
                const material = new THREE.LineBasicMaterial({color: 0xffffff});
                const line = new THREE.Line(geometry, material);
                this.addChild(line);
            });
        });
    }

    protected newObject3DInstance(): THREE.Object3D {
        console.log('SliceGeometry.newObject3D');
        return new THREE.Object3D();
    }

}
