<form>
  <div class="form-row mb-1" *ngFor='let q of quantities(), index as i'>
    <div class="col">
      <label for="unitDropdown{{i}}">{{q.replace('_', ' ') | titlecase}} units</label>
      <div ngbDropdown class="input-group input-group-sm">
        <button class="btn btn-sm btn-outline-primary w-100" id="unitDropdown{{i}}" ngbDropdownToggle>{{selectedUnit(q)}}</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem *ngFor="let u of availableUnits(q)" (click)="selectUnit(q, u)">{{u}}</button>
        </div>
      </div>
    </div>
    <div class="col">
      <label for="decimalDropdown{{i}}">{{q.replace('_', ' ') | titlecase}} decimal places</label>
      <div ngbDropdown class="input-group input-group-sm">
        <button class="btn btn-sm btn-outline-primary w-100" id="decimalDropdown{{i}}" ngbDropdownToggle>{{decimalString(selectedDecimal(q))}}</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem *ngFor="let d of availableDecimals(q)" (click)="selectDecimal(q, d)">{{decimalString(d)}}</button>
        </div>
      </div>
    </div>
  </div>
</form>
