import {Inject, Injectable} from '@angular/core';
import * as Qty from 'js-quantities';
import {BASE_UNITSET, UnitSet} from './unit-set';

@Injectable({
    providedIn: 'root'
})
export class UnitsService {

    private workingUnits: UnitSet;

    constructor(@Inject(BASE_UNITSET) private baseUnits: UnitSet) {

    }

    setWorkingUnits(u: UnitSet) {
        this.workingUnits = u;
    }

    quantities(): string[] {
        if (this.baseUnits) {
            const qs = new Array<string>();
            this.baseUnits.units.forEach((u) => {
                const q = Qty(u.unit).kind();
                if (qs.indexOf(q) === -1) {
                    qs.push(q);
                }
            });
            return qs;
        }
        return Qty.getKinds();
    }

    selectedForQuantity(quantity: string, set: UnitSet): string {
        if (set) {
            const us = new Array<string>();
            set.units.forEach((u) => {
                const q = Qty(u.unit).kind();
                if (quantity === q) {
                    us.push(u.unit);
                }
            });
            return us[0];
        }
        const units = this.unitsForQuantity(quantity);
        if (units.length === 0) {
            return '';
        }
        return units[0];
    }

    selectedForUnit(unit: string, set = this.workingUnits): string {
        return this.selectedForQuantity(Qty(unit).kind(), set);
    }

    unitsForQuantity(quantity: string): string[] {
        if (this.baseUnits) {
            const us = new Array<string>();
            this.baseUnits.units.forEach((u) => {
                const q = Qty(u.unit).kind();
                if (quantity === q) {
                    us.push(u.unit);
                }
            });
            return us;
        }
        return Qty.getUnits(quantity);
    }

    unitsForUnit(unit: string): string[] {
        return this.unitsForQuantity(Qty(unit).kind());
    }

    decimalForUnit(unit: string, set: UnitSet): number {
        let precision = set.units.filter((u) => u.unit === unit).map((u) => u.precision);
        if (precision.length === 0) {
            precision = this.baseUnits.units.filter((u) => u.unit === unit).map((u) => u.precision);
            if (precision.length === 0) {
                return 3;
            }
        }
        return precision[0];
    }

    baseValue(value: number, unit: string): number {
        return Qty(value, unit).toBase().scalar;
    }

    unitValue(baseValue: number, unit: string): number {
        return Qty(baseValue, Qty(unit).toBase().units()).to(unit).scalar;
    }

    convertQuantity(unitValue: string, toUnit: string): number {
        if (unitValue && toUnit) {
            const result = Qty(unitValue).to(toUnit).scalar;
            // Temp fix: js-quantities should be fixed so this doesn't happen, should use mulSafe and divSafe for all maths
            if (Number.isNaN(result)) {
                return 0;
            }
            return result;
        }
        return null;
    }

    convert(value: number, fromUnit: string, toUnit: string): number {
        if (value && fromUnit && toUnit && fromUnit.length > 0 && toUnit.length > 0 && fromUnit !== toUnit) {
            const result = Qty(value, fromUnit).to(toUnit).scalar;
            // Temp fix: js-quantities should be fixed so this doesn't happen, should use mulSafe and divSafe for all maths
            if (Number.isNaN(result)) {
                return 0;
            }
            return result;
        }
        return value;
    }

    format(unitValue: string): string {
        if (unitValue) {
            const qty = Qty(unitValue);
            if (this.workingUnits) {
                for (let i = 0; i < this.workingUnits.units.length; i++) {
                    const value = this.workingUnits.units[i];
                    const q = Qty(value.unit);
                    if (qty.kind() === q.kind()) {
                        return qty.to(q).scalar.toFixed(value.precision) + ' ' + value.unit;
                    }
                }
            }
            if (this.baseUnits) {
                for (let i = 0; i < this.baseUnits.units.length; i++) {
                    const value = this.baseUnits.units[i];
                    const q = Qty(value.unit);
                    if (qty.kind() === q.kind()) {
                        return qty.to(q).scalar.toFixed(value.precision) + ' ' + value.unit;
                    }
                }
            }
        }
        return unitValue;
    }

}
