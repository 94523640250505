import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from 'ng2-ui-auth';
import {FormHelperService} from '../services/form-helper.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProjectService} from '../services/project.service';
import {Restangular} from 'ngx-restangular';
import {environment} from '../../environments/environment';
import {AlertService} from '../alert/alert.service';


@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    form: FormGroup;
    invitation_code: string;
    skp: string;
    inProgress: boolean;
    termsAccepted: boolean;
    beta: boolean;

    constructor(private auth: AuthService,
                private projectService: ProjectService,
                private router: Router,
                private fb: FormBuilder,
                public fh: FormHelperService,
                private route: ActivatedRoute,
                private modalService: NgbModal,
                private restangular: Restangular,
                private alertService: AlertService) {
        this.termsAccepted = false;
        this.beta = environment.beta;
    }

    ngOnInit() {
        // Are we in beta?
        // Are we signed in?
        // //auth.isAuthenticated()
        // Do we have an invitation_code?
        // Do we have an skp code?

        // 1. We're not signed in
        // 2. We are signed in, and we have an authentication code
        // 3. We are signed in and have no authentication code
        this.route.queryParams.subscribe(params => {
            this.invitation_code = params.invitation_code;
            this.skp = params.skp;
            if (this.auth.isAuthenticated()) {
                if (this.skp != null) {
                    // I've come here from Sketchup
                    this.alertService.info('Thank you for launching Fabber in Sketchup, please wait while we connect you...');
                    this.restangular.one('auth', 'link').post('skp', {
                        name: 'SketchUp',
                        uuid: this.route.snapshot.queryParams.skp
                    }).subscribe(
                        (token) => {
                            this.router.navigate(['/sketchup']);
                        }
                    );
                } else {
                    // I don't know why I'm here, I'm logged in but signing up?
                    this.alertService.info('You are already logged in, please click Fabber above to view your projects or Logout to leave.');
                }
            } else {
                if (this.invitation_code != null) {
                    this.alertService.info('Congratulations! You have been invited to Fabber. Please signup below and discover design to fabrication in seconds!');
                }
            }
        });

        this.form = this.fb.group({
            name: new FormControl('', [Validators.required, Validators.minLength(3)]),
            email: new FormControl('', [Validators.required, Validators.minLength(3)]),
            'password-group': new FormGroup(
                {
                    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
                    'confirm-password': new FormControl('')
                },
                (c: FormGroup) => (c.value['password'] === c.value['confirm-password'] ? null : {'pass-confirm': true})
            )
        });
    }

    signup(signupData: any) {
        // Behaviour here depends on invite/activation code
        // If invited or activating, we already have a user
        // so we call a different method, again we get a token and redirect though
        this.inProgress = true;
        this.auth
            .signup({
                name: signupData['name'],
                email: signupData['email'],
                password: signupData['password-group']['password'],
                invitation_code: this.invitation_code
            })
            .subscribe({
                next: response => this.auth.setToken(response.access_token),
                error: (err: any) => {
                    this.alertService.error(err);
                    this.inProgress = false;
                },
                complete: () => this.navigateAfterSignup()
            });
    }

    providerSignup(provider: string) {
        // Behaviour here depends on invite/activation code
        // If invited or activating, we already have a user
        // so we call a different method, again we get a token and redirect though
        this.inProgress = true;
        this.auth.authenticate(provider, {invitation_code: this.invitation_code}).subscribe({
            error: (err: any) => {
                this.alertService.error(err);
                this.inProgress = false;
            },
            complete: () => this.navigateAfterSignup()
        });
    }

    navigateAfterSignup() {
        // TODO Should we go to a welcome page first?
        // TODO Insert Sketchup logic here
        // If skp queryParam, then attempt to link the SketchUp instance with this account
        // by POSTing to /api/auth/link/skp
        if (this.route.snapshot.queryParams.skp) {
            this.restangular.one('auth', 'link').post('skp', {name: 'SketchUp', uuid: this.route.snapshot.queryParams.skp}).subscribe(
                (token) => {
                    this.router.navigate(['/sketchup']);
                }
            );
        } else {
            // TODO: Only do this if we have no projects, else we end up creating loads of empty ones
            this.projectService.createAndUpload('My First Project');
        }
    }

    acceptTerms() {
        this.termsAccepted = true;
    }
}
