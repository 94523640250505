<div class="container-flex mt-1 ml-3 mr-3">
    <div class="row">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">Select Parts</li>
                <li class="breadcrumb-item"><a [routerLink]="['/project', projectId, 'sheets']"
                                               *ngIf="!(loading || saving)">Manufacture</a><span
                        *ngIf="loading || saving">Manufacture</span></li>
            </ol>
        </nav>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <p class="lead">Select parts for manufacturing.</p>
            <p>Each column represents a combination of material, thickness, and machine. Drag parts between columns to
                choose configurations for each, and change number of each part to be manufactured.</p>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-4">
            <label>View complete design</label>
            <a class="btn btn-light ml-1" routerLink='/project/{{projectId}}/view' placement="top"
               ngbTooltip="Open project 3D view">
                <fa-icon [icon]="['fas', 'cube']" size="2x"></fa-icon>
            </a>
        </div>
        <div class="col-md-4">
            <label>Select number off for complete design</label>
            <input type="number" class="n-off ml-1" id="project-off" name="project-off" min="0" value="1"
                   placement="right" ngbTooltip="Select number off" [(ngModel)]="projectOff" (change)="updateOff()"
                   [disabled]="loading || saving">
        </div>
        <div class="col-md-4">
            <label>Add material or machine configuration</label>
            <button class="btn btn-primary ml-1" (click)="addConfiguration()" [disabled]="loading || saving">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </button>
        </div>
    </div>
    <div class="row mt-2 mb-2">
        <div class="card-group col-md-12" *ngIf="cuttingList">
            <div class="card"
                 [ngClass]="{'machine-column-closed': i==0 && collapseLeft, 'machine-column': i>0 || !collapseLeft}"
                 *ngFor="let column of cuttingList.machines; index as i">
                <!--<img *ngIf="group.image.length!=''" class="card-img-top" src="{{group.image}}" alt="Card image cap">-->
                <div class="card-body">
                    <div *ngIf="i==0">
                        <p class="card-text">
                            <button class="btn btn-light" (click)="collapseLeft = !collapseLeft">
                                <fa-icon [icon]="['fas', 'arrows-alt-h']" size="2x"></fa-icon>
                            </button>
                            <span *ngIf="!collapseLeft"> The following parts will not be manufactured.</span>
                        </p>
                    </div>
                    <div *ngIf="i>0">
                        <h6 class="card-title">{{column.thickness | unit}}</h6>
                        <small *ngIf="i > 0" class="text-muted">Minimum sheet size {{minimumSheetSize[i].length | unit}}
                            x {{minimumSheetSize[i].width | unit}}<br>Maximum bit radius {{maximumBitRadii[i] | unit}}
                        </small>
                        <p class="card-text">The following parts will be cut using:</p>
                        <app-machine-picker [machineId]="column.machineId"
                                            [bitId]="column.bitId"
                                            [material]="column.material"
                                            [sheetSize]="column.sheetSize"
                                            [minimumSheetSize]="minimumSheetSize[i]"
                                            (machineIdChange)="updateMachineId(i, $event)"
                                            (materialChange)="updateMaterial(i, $event)"
                                            (bitIdChange)="updateBitId(i, $event)"
                                            (sheetSizeChange)="updateSheetSize(i, $event)"
                                            [disabled]="loading || saving"></app-machine-picker>

                    </div>
                    <ul *ngIf="i>0 || !collapseLeft" class="list-group list-group-flush part-list" dragula="PARTS"
                        [(dragulaModel)]="column.instances">
                        <li *ngFor="let instance of column.instances"
                            class="list-group-item d-flex justify-content-between align-items-center part-item"
                            [style.background]="'url(/api/projects/' + projectId + '/parts/' + instance.partId + '.png?token=' + auth.getToken() + ')'"
                            [style.background-repeat]="'no-repeat'" [style.background-position]="'center'"
                            [style.background-size]="'auto 148px'">
                            <span>{{instance.name}}<br>
                                <small *ngIf="instance.slice && i > 0"
                                       class="text-muted">{{instance.slice.minimumSheetSize.length | unit}}
                                    x {{instance.slice.minimumSheetSize.width | unit}}
                                    x {{instance.slice.thickness | unit}}</small>
                                <br>
                                <small *ngIf="instance.slice && i > 0"
                                       class="text-muted">Internal radius {{instance.slice.minimumInternalRadius | unit}}</small>
                            </span>
                            <div *ngIf="instance.slice && i > 0" class="btn-group-vertical">
                                <!--<a class="btn btn-light" routerLink='/parts-list/{{projectId}}/part/{{instance.partId}}/toolpath' placement="top" ngbTooltip="Open part toolpath"><fa-icon [icon]="['fas', 'square']" size="2x"></fa-icon></a>-->
                                <a class="btn btn-light" routerLink='/project/{{projectId}}/part/{{instance.partId}}'
                                   placement="top" ngbTooltip="Open part 3D view">
                                    <fa-icon [icon]="['fas', 'cube']" size="2x"></fa-icon>
                                </a>
                                <input type="number" class="n-off" id="{{instance.partId}}-off"
                                       name="{{instance.partId}}-off" min="0" [(ngModel)]="instance.off" placement="top"
                                       ngbTooltip="Select number off" (change)="change()">
                            </div>
                            <div *ngIf="!instance.slice || i == 0" class="btn-group-vertical">
                                <a class="btn btn-light" routerLink='/project/{{projectId}}/part/{{instance.partId}}'
                                   placement="top" ngbTooltip="Open part 3D view">
                                    <fa-icon [icon]="['fas', 'cube']" size="2x"></fa-icon>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="card-footer">
                    <small class="text-muted">{{column.instances.length}} parts, {{countInstances(i)}} instances</small>
                    <button *ngIf="cuttingList.machines.length>2 && i>0"
                            [swal]="{title:'Delete column?', text: 'This cannot be undone', type: 'question', showCancelButton: true, focusCancel: true}"
                            (confirm)="deleteColumn(i)" class="btn btn-danger float-right">
                        <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<swal
    #manufacturePrompt
    title="Ready to Manufacture"
    text="All information is complete, do you want to move to the manufacture page?"
    icon="question"
    cancelButtonText="Not Yet"
    confirmButtonText="Let's go!"
    [showCancelButton]="true"
    [focusCancel]="true"
    (cancel)="manufacture(false)"
    (confirm)="manufacture(true)">
</swal>
