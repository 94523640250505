import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable({
    providedIn: 'root'
})
export class UpdateService {

    constructor(private appRef: ApplicationRef, private updates: SwUpdate) {
        this.updates.activated.subscribe(update => {
            console.log('Activated');
            console.log(update);
        });
        this.updates.available.subscribe(update => {
            console.log('Available');
            console.log(update);
        });
    }

    check(): Observable<void> {
        return fromPromise(this.updates.checkForUpdate());
    }
}
