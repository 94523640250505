import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { timer } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class OnshapeService {
    // https://dev-portal.onshape.com/doc/clientmessaging.html
    constructor(router, route) {
        this.router = router;
        this.route = route;
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event) => {
            let r = this.route;
            while (r.firstChild) {
                r = r.firstChild;
            }
            r.params.subscribe(params => {
                this.init(params);
            });
        });
    }
    init(params) {
        if (params['documentId']) {
            window.addEventListener('keydown', (event) => this.handleKeydown(event), false);
            window.addEventListener('message', (event) => this.handleMessage(event), false);
            timer(10000, 10000).subscribe(() => this.postMessage('keepAlive'));
        }
    }
    handleKeydown(event) {
        // Shift-S
        if (event.shiftKey && event.key === 's') {
            this.postMessage('saveAVersion');
        }
        // Shift-?
        if (event.shiftKey && event.key === '?') {
            this.postMessage('showKeyboardShortcutsHelp');
        }
    }
    handleMessage(event) {
        if (event.data && event.data.messageName) {
            switch (event.data.messageName) {
                case 'show':
                case 'hide':
            }
        }
    }
    postMessage(type, data) {
        const message = { messageName: type };
        if (data) {
            Object.keys(data).forEach(key => message[key] = data[key]);
        }
        window.parent.postMessage(message, '*');
    }
    showMessageBubble(message = '') {
        this.postMessage('showMessageBubble', { message: message });
    }
    showLoadingSpinner(message = '') {
        this.postMessage('startLoadingSpinner', { message: message });
        return () => this.postMessage('stopLoadingSpinner');
    }
    showWorkingSpinner() {
        this.postMessage('startWorkingSpinner');
        return () => this.postMessage('stopWorkingSpinner');
    }
}
OnshapeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnshapeService_Factory() { return new OnshapeService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute)); }, token: OnshapeService, providedIn: "root" });
