<div class="container">
    <div class="row">
        <div class="col-md-12"><img class="img-fluid d-block mx-auto" src="assets/fabber-1line-2color-bold-lowres.png"
                                    alt="Get Fabber!"></div>
    </div>
    <div class="row mt-2">
        <div class="px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center" style="">
            <h1>Design to fabrication in seconds</h1>
            <p class="mb-3 lead" style="">Upload your model into Fabber. We'll figure out how to fabricate and send you
                back ready to run G Code for your machine</p>
            <a href="https://www.getfabber.com" target="getfabber" class="btn btn-primary w-100 btn-lg">Let's go!</a>
            <br>
            <p class="mb-3 lead">Already a Fabber user? <a [routerLink]="['/login']">Click here to login</a>.</p>
        </div>
        <div class="col-md-6" style=""><img class="img-fluid d-block"
                                            src="https://static.pingendo.com/img-placeholder-tablet.svg"></div>
    </div>
</div>
