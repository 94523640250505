import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {Location} from '@angular/common';
import {MachineService} from '../services/machine.service';
import {Machine, PostProcessor, HoldingStrategy} from '../../../target/fabber-model';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {LabelType, Options} from 'ng5-slider';
import {AlertService} from '../alert/alert.service';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-machine-editor',
    templateUrl: './machine-editor.component.html',
    styleUrls: ['./machine-editor.component.scss']
})
export class MachineEditorComponent implements OnInit {

    machine$: Observable<Machine>;
    postProcessors$: Observable<PostProcessor[]>;
    loading: boolean;
    speedSliderOptions: Options = {
        floor: 0,
        step: 0.01,
        ceil: 1,
        showTicks: false,
        getLegend: (value: number): string => {
            return '<b>T</b>' + value;
        },
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Floor:
                    return '<fa-icon [icon]="[\'fas\', \'fa-adjust\']"></fa-icon>';
                case LabelType.Ceil:
                    return '<fa-icon [icon]="[\'fas\', \'fa-adjust\']"></fa-icon>';
                default:
                    return Number(value * 100).toFixed(0) + '&#37;';
            }
        }
    };
    brands: string[];

    constructor(private machineService: MachineService, private route: ActivatedRoute,
                private location: Location, private alertService: AlertService, private title: Title) {
        this.loading = false;
        this.brands = new Array<string>();
    }

    ngOnInit() {
        this.machine$ = this.machineService.get(this.route.snapshot.params.id);
        this.postProcessors$ = this.machineService.getPostProcessors();
        this.machineService.brands().subscribe(brands => brands.forEach(brand => this.brands.push(brand)));
        this.title.setTitle('Machine');
    }

    editable(machine: Machine): boolean {
        return machine.owner != null;
    }

    save(machine: any) {
        machine.save().subscribe({
            next: () => this.location.back(),
            error: (err) => this.alertService.error(err)
        });
    }

    cancel() {
        this.location.back();
    }
}
