import {Injectable} from '@angular/core';
import {User, Application} from '../../../target/fabber-model';
import {Restangular} from 'ngx-restangular';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from 'ng2-ui-auth';
import {UnitSet} from './units/unit-set';
import {UnitsService} from './units/units.service';
import {Intercom} from 'ng-intercom';

interface IGTagWindow extends Window {
    gtag: any;
}

function getWindow(): any {
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private meValue: User;

    constructor(private restangular: Restangular, private auth: AuthService,
                private unitsService: UnitsService, private intercom: Intercom) {
    }

    get nativeWindow(): IGTagWindow {
        return getWindow();
    }

    me(force: boolean): Observable<User> {
        if (!force && this.meValue) {
            return of(this.meValue);
        }
        return this.restangular.one('users', 'me').get().pipe(map((user) => {
            this.meValue = user as User;
            this.setAnalyticsUserId(this.meValue.id);
            this.intercom.update({
                app_id: 't7tq0zk6',
                email: this.meValue.email,
                name: this.meValue.name,
                user_id: this.meValue.id,
                created_at: this.meValue.signup_date,
                fabberVersion: (!window['fabberVersion'] || window['fabberVersion'] === '${VERSION}') ? 'dev' : window['fabberVersion']
            });
            this.unitsService.setWorkingUnits((user as User).units);
            return user;
        }));
    }

    setAnalyticsUserId(id: string) {
        this.nativeWindow.gtag('config', 'UA-131430495-1', {'send_page_view': false, 'user_id': id});
    }

    signup(): Observable<any> {
        return this.auth.authenticate('memberfulsignup');
    }

    saveUnits(units: UnitSet): Observable<User> {
        this.meValue.units = units;
        this.unitsService.setWorkingUnits(units);
        return this.restangular.one('users', 'me').customPUT(this.meValue);
    }

    applications(): Observable<Application[]> {
        return this.restangular.one('users', 'me').all('applications').getList();
    }

    removeApplication(id: string): Observable<any> {
        return this.restangular.one('users', 'me').one('applications', id).remove();
    }

}
