import {Component, Input, OnInit} from '@angular/core';
import {AuthService, PopupService} from 'ng2-ui-auth';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Intercom} from 'ng-intercom';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public isCollapsed = true;
    public version: string;
    @Input() inApp = false;
    name$: Observable<string>;

    constructor(public auth: AuthService, private router: Router, private userService: UserService, private intercom: Intercom, private route: ActivatedRoute) {
        if (!window['fabberVersion'] || window['fabberVersion'] === '${VERSION}') {
            this.version = 'dev';
        } else {
            this.version = window['fabberVersion'];
        }
    }

    ngOnInit() {
        console.log('resolved user');
        this.route.data.subscribe(data => console.log(data));
        if (this.auth.isAuthenticated()) {
            this.name$ = this.userService.me(false).pipe(map((u) => u.name));
        } else {
            this.name$ = of('');
        }
    }

    logout() {
        this.router.navigateByUrl('/logout').then(() => {
            this.auth.logout().subscribe({
                error: (err: any) => console.log(err),
                complete: () => {
                    this.intercom.shutdown();
                    window.open('https://getfabber.memberful.com/auth/sign_out', '_self');
                }
            });
        });
    }

    popout() {
        window.open(window.location.href, 'fabber');
    }

    startTour() {
        this.intercom.boot({
            app_id: 't7tq0zk6'
        });
        this.intercom.startTour(96325);
    }
}
