/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.15.527 on 2021-06-22 16:40:19.

export class User {
    id: string;
    name: string;
    email: string;
    password: string;
    providers: { [index: string]: Provider };
    units: UnitSet;
    validation_code: string;
    email_validated: boolean;
    signup_method: string;
    signup_date: Date;
    last_login: Date;
    preferred_units: string[];

    static fromData(data: User, target?: User): User {
        if (!data) {
            return data;
        }
        const instance = target || new User();
        instance.id = data.id;
        instance.name = data.name;
        instance.email = data.email;
        instance.password = data.password;
        instance.providers = __getCopyObjectFn(Provider.fromData)(data.providers);
        instance.units = UnitSet.fromData(data.units);
        instance.validation_code = data.validation_code;
        instance.email_validated = data.email_validated;
        instance.signup_method = data.signup_method;
        instance.signup_date = data.signup_date;
        instance.last_login = data.last_login;
        instance.preferred_units = __getCopyArrayFn(__identity<string>())(data.preferred_units);
        return instance;
    }
}

export class Application {
    id: string;
    uuid: string;
    name: string;
    owner: string;
    properties: { [index: string]: string };

    static fromData(data: Application, target?: Application): Application {
        if (!data) {
            return data;
        }
        const instance = target || new Application();
        instance.id = data.id;
        instance.uuid = data.uuid;
        instance.name = data.name;
        instance.owner = data.owner;
        instance.properties = __getCopyObjectFn(__identity<string>())(data.properties);
        return instance;
    }
}

export class Invitation {
    id: string;
    code: string;
    inviter: string;
    invitee: string;
    email: string;

    static fromData(data: Invitation, target?: Invitation): Invitation {
        if (!data) {
            return data;
        }
        const instance = target || new Invitation();
        instance.id = data.id;
        instance.code = data.code;
        instance.inviter = data.inviter;
        instance.invitee = data.invitee;
        instance.email = data.email;
        return instance;
    }
}

export class ProjectSummary {
    id: string;
    owner: string;
    name: string;
    created: Date;
    edited: Date;
    cad: CADSource;
    thumbnailId: string;
    status: ProjectStatus;

    static fromData(data: ProjectSummary, target?: ProjectSummary): ProjectSummary {
        if (!data) {
            return data;
        }
        const instance = target || new ProjectSummary();
        instance.id = data.id;
        instance.owner = data.owner;
        instance.name = data.name;
        instance.created = data.created;
        instance.edited = data.edited;
        instance.cad = CADSource.fromData(data.cad);
        instance.thumbnailId = data.thumbnailId;
        instance.status = data.status;
        return instance;
    }
}

export class Part {
    id: string;
    project: string;
    name: string;
    isSolid: boolean;
    hash: string;
    thumbnailId: string;
    stlId: string;
    instances: PartInstance[];

    static fromData(data: Part, target?: Part): Part {
        if (!data) {
            return data;
        }
        const instance = target || new Part();
        instance.id = data.id;
        instance.project = data.project;
        instance.name = data.name;
        instance.isSolid = data.isSolid;
        instance.hash = data.hash;
        instance.thumbnailId = data.thumbnailId;
        instance.stlId = data.stlId;
        instance.instances = __getCopyArrayFn(PartInstance.fromData)(data.instances);
        return instance;
    }
}

export class CuttingList {
    id: string;
    project: string;
    projectOff: number;
    machines: CuttingMachineMaterial[];

    static fromData(data: CuttingList, target?: CuttingList): CuttingList {
        if (!data) {
            return data;
        }
        const instance = target || new CuttingList();
        instance.id = data.id;
        instance.project = data.project;
        instance.projectOff = data.projectOff;
        instance.machines = __getCopyArrayFn(CuttingMachineMaterial.fromData)(data.machines);
        return instance;
    }
}

export class CADProvider {

    static fromData(data: CADProvider, target?: CADProvider): CADProvider {
        if (!data) {
            return data;
        }
        const instance = target || new CADProvider();
        return instance;
    }
}

export class CADSource {
    provider: string;
    type: string;
    id: string;
    name: string;
    hash: string;
    updated: Date;

    static fromData(data: CADSource, target?: CADSource): CADSource {
        if (!data) {
            return data;
        }
        const instance = target || new CADSource();
        instance.provider = data.provider;
        instance.type = data.type;
        instance.id = data.id;
        instance.name = data.name;
        instance.hash = data.hash;
        instance.updated = data.updated;
        return instance;
    }
}

export class LayerSet {
    down: Layer[];
    up: Layer[];

    static fromData(data: LayerSet, target?: LayerSet): LayerSet {
        if (!data) {
            return data;
        }
        const instance = target || new LayerSet();
        instance.down = __getCopyArrayFn(Layer.fromData)(data.down);
        instance.up = __getCopyArrayFn(Layer.fromData)(data.up);
        return instance;
    }
}

export class Layer {
    features: LayerFeature[];
    normal: Normal;
    height: Quantity<any>;
    face: string;

    static fromData(data: Layer, target?: Layer): Layer {
        if (!data) {
            return data;
        }
        const instance = target || new Layer();
        instance.features = __getCopyArrayFn(LayerFeature.fromData)(data.features);
        instance.normal = Normal.fromData(data.normal);
        instance.height = data.height;
        instance.face = data.face;
        return instance;
    }
}

export class Path {
    type: "circle" | "ellipse" | "linearc";
    units: string;

    static fromData(data: Path, target?: Path): Path {
        if (!data) {
            return data;
        }
        const instance = target || new Path();
        instance.type = data.type;
        instance.units = data.units;
        return instance;
    }

    static fromDataUnion(data: PathUnion): PathUnion {
        if (!data) {
            return data;
        }
        switch (data.type) {
            case "circle":
                return CirclePath.fromData(data);
            case "ellipse":
                return EllipsePath.fromData(data);
            case "linearc":
                return LineArcPath.fromData(data);
        }
    }
}

export class CirclePath extends Path {
    type: "circle";
    r: number;
    x: number;
    y: number;

    static fromData(data: CirclePath, target?: CirclePath): CirclePath {
        if (!data) {
            return data;
        }
        const instance = target || new CirclePath();
        super.fromData(data, instance);
        instance.r = data.r;
        instance.x = data.x;
        instance.y = data.y;
        return instance;
    }
}

export class EllipsePath extends Path {
    type: "ellipse";
    rx: number;
    ry: number;
    x: number;
    y: number;
    rotation: number;

    static fromData(data: EllipsePath, target?: EllipsePath): EllipsePath {
        if (!data) {
            return data;
        }
        const instance = target || new EllipsePath();
        super.fromData(data, instance);
        instance.rx = data.rx;
        instance.ry = data.ry;
        instance.x = data.x;
        instance.y = data.y;
        instance.rotation = data.rotation;
        return instance;
    }
}

export class LineArcPath extends Path {
    type: "linearc";
    path: string;

    static fromData(data: LineArcPath, target?: LineArcPath): LineArcPath {
        if (!data) {
            return data;
        }
        const instance = target || new LineArcPath();
        super.fromData(data, instance);
        instance.path = data.path;
        return instance;
    }
}

export class Normal {
    x: number;
    y: number;
    z: number;

    static fromData(data: Normal, target?: Normal): Normal {
        if (!data) {
            return data;
        }
        const instance = target || new Normal();
        instance.x = data.x;
        instance.y = data.y;
        instance.z = data.z;
        return instance;
    }
}

export class Sheet {
    id: string;
    sheetSize: SheetSize;
    parts: Placement[];

    static fromData(data: Sheet, target?: Sheet): Sheet {
        if (!data) {
            return data;
        }
        const instance = target || new Sheet();
        instance.id = data.id;
        instance.sheetSize = SheetSize.fromData(data.sheetSize);
        instance.parts = __getCopyArrayFn(Placement.fromData)(data.parts);
        return instance;
    }
}

export class Document {
    id: string;
    created: Date;
    edited: Date;

    static fromData(data: Document, target?: Document): Document {
        if (!data) {
            return data;
        }
        const instance = target || new Document();
        instance.id = data.id;
        instance.created = data.created;
        instance.edited = data.edited;
        return instance;
    }
}

export class Sheets extends Document {
    projectId: string;
    sheetSets: SheetSet[];

    static fromData(data: Sheets, target?: Sheets): Sheets {
        if (!data) {
            return data;
        }
        const instance = target || new Sheets();
        super.fromData(data, instance);
        instance.projectId = data.projectId;
        instance.sheetSets = __getCopyArrayFn(SheetSet.fromData)(data.sheetSets);
        return instance;
    }
}

export class Slice {
    id: string;
    part: string;
    thickness: Quantity<any>;
    up: Normal;
    outer: PathUnion;
    features: SliceFeature[];
    bottomFeatures: SliceFeature[];
    minimumSheetSize: SheetSize;
    minimumInternalRadius: Quantity<any>;

    static fromData(data: Slice, target?: Slice): Slice {
        if (!data) {
            return data;
        }
        const instance = target || new Slice();
        instance.id = data.id;
        instance.part = data.part;
        instance.thickness = data.thickness;
        instance.up = Normal.fromData(data.up);
        instance.outer = Path.fromDataUnion(data.outer);
        instance.features = __getCopyArrayFn(SliceFeature.fromData)(data.features);
        instance.bottomFeatures = __getCopyArrayFn(SliceFeature.fromData)(data.bottomFeatures);
        instance.minimumSheetSize = SheetSize.fromData(data.minimumSheetSize);
        instance.minimumInternalRadius = data.minimumInternalRadius;
        return instance;
    }
}

export class Toolpath {
    sheetSize: SheetSize;
    thickness: Quantity<any>;
    groups: ToolpathGroup[];

    static fromData(data: Toolpath, target?: Toolpath): Toolpath {
        if (!data) {
            return data;
        }
        const instance = target || new Toolpath();
        instance.sheetSize = SheetSize.fromData(data.sheetSize);
        instance.thickness = data.thickness;
        instance.groups = __getCopyArrayFn(ToolpathGroup.fromData)(data.groups);
        return instance;
    }
}

export class Machine {
    id: string;
    owner: string;
    name: string;
    brand: string;
    model: string;
    xLength: Quantity<any>;
    yLength: Quantity<any>;
    zHeight: Quantity<any>;
    minSpindleSpeed: Quantity<any>;
    maxSpindleSpeed: Quantity<any>;
    minColletSize: Quantity<any>;
    maxColletSize: Quantity<any>;
    minCuttingSpeed: Quantity<any>;
    maxCuttingSpeed: Quantity<any>;
    thumbnailId: string;
    toolpathStrategy: ToolpathStrategy;
    postProcessStrategy: PostProcessStrategy;

    static fromData(data: Machine, target?: Machine): Machine {
        if (!data) {
            return data;
        }
        const instance = target || new Machine();
        instance.id = data.id;
        instance.owner = data.owner;
        instance.name = data.name;
        instance.brand = data.brand;
        instance.model = data.model;
        instance.xLength = data.xLength;
        instance.yLength = data.yLength;
        instance.zHeight = data.zHeight;
        instance.minSpindleSpeed = data.minSpindleSpeed;
        instance.maxSpindleSpeed = data.maxSpindleSpeed;
        instance.minColletSize = data.minColletSize;
        instance.maxColletSize = data.maxColletSize;
        instance.minCuttingSpeed = data.minCuttingSpeed;
        instance.maxCuttingSpeed = data.maxCuttingSpeed;
        instance.thumbnailId = data.thumbnailId;
        instance.toolpathStrategy = ToolpathStrategy.fromData(data.toolpathStrategy);
        instance.postProcessStrategy = PostProcessStrategy.fromData(data.postProcessStrategy);
        return instance;
    }
}

export class Bit extends Document {
    owner: string;
    name: string;
    diameter: Quantity<any>;
    shaftDiameter: Quantity<any>;
    cuttingEdgeLength: Quantity<any>;
    overallLength: Quantity<any>;
    materialSettings: BitMaterial[];

    static fromData(data: Bit, target?: Bit): Bit {
        if (!data) {
            return data;
        }
        const instance = target || new Bit();
        super.fromData(data, instance);
        instance.owner = data.owner;
        instance.name = data.name;
        instance.diameter = data.diameter;
        instance.shaftDiameter = data.shaftDiameter;
        instance.cuttingEdgeLength = data.cuttingEdgeLength;
        instance.overallLength = data.overallLength;
        instance.materialSettings = __getCopyArrayFn(BitMaterial.fromData)(data.materialSettings);
        return instance;
    }
}

export class BitMaterial {
    material: string;
    passDepthMultiplier: number;
    chipLoad: Quantity<any>;
    spindleSpeed: Quantity<any>;
    minCuttingSpeed: Quantity<any>;
    maxCuttingSpeed: Quantity<any>;

    static fromData(data: BitMaterial, target?: BitMaterial): BitMaterial {
        if (!data) {
            return data;
        }
        const instance = target || new BitMaterial();
        instance.material = data.material;
        instance.passDepthMultiplier = data.passDepthMultiplier;
        instance.chipLoad = data.chipLoad;
        instance.spindleSpeed = data.spindleSpeed;
        instance.minCuttingSpeed = data.minCuttingSpeed;
        instance.maxCuttingSpeed = data.maxCuttingSpeed;
        return instance;
    }
}

export class MaterialChoice {
    material: string;
    bits: Bit[];

    static fromData(data: MaterialChoice, target?: MaterialChoice): MaterialChoice {
        if (!data) {
            return data;
        }
        const instance = target || new MaterialChoice();
        instance.material = data.material;
        instance.bits = __getCopyArrayFn(Bit.fromData)(data.bits);
        return instance;
    }
}

export class MaterialSheet extends Document {
    material: string;
    thickness: Quantity<any>;
    sheetSize: SheetSize;

    static fromData(data: MaterialSheet, target?: MaterialSheet): MaterialSheet {
        if (!data) {
            return data;
        }
        const instance = target || new MaterialSheet();
        super.fromData(data, instance);
        instance.material = data.material;
        instance.thickness = data.thickness;
        instance.sheetSize = SheetSize.fromData(data.sheetSize);
        return instance;
    }
}

export class ParameterSpec {
    name: string;
    defaultValue: number;
    unit: string;
    displayUnits: string[];
    description: string;
    minValue: number;
    maxValue: number;

    static fromData(data: ParameterSpec, target?: ParameterSpec): ParameterSpec {
        if (!data) {
            return data;
        }
        const instance = target || new ParameterSpec();
        instance.name = data.name;
        instance.defaultValue = data.defaultValue;
        instance.unit = data.unit;
        instance.displayUnits = __getCopyArrayFn(__identity<string>())(data.displayUnits);
        instance.description = data.description;
        instance.minValue = data.minValue;
        instance.maxValue = data.maxValue;
        return instance;
    }
}

export class PostProcessor extends Document {
    name: string;
    owner: string;
    contentType: string;
    fileExtension: string;
    units: UnitSet;
    template: string;

    static fromData(data: PostProcessor, target?: PostProcessor): PostProcessor {
        if (!data) {
            return data;
        }
        const instance = target || new PostProcessor();
        super.fromData(data, instance);
        instance.name = data.name;
        instance.owner = data.owner;
        instance.contentType = data.contentType;
        instance.fileExtension = data.fileExtension;
        instance.units = UnitSet.fromData(data.units);
        instance.template = data.template;
        return instance;
    }
}

export class PostProcessStrategy {
    units: UnitSet;
    postProcessor: string;
    cutRate: number;
    jogRate: number;
    zDirectionStrategy: ZDirectionStrategy;

    static fromData(data: PostProcessStrategy, target?: PostProcessStrategy): PostProcessStrategy {
        if (!data) {
            return data;
        }
        const instance = target || new PostProcessStrategy();
        instance.units = UnitSet.fromData(data.units);
        instance.postProcessor = data.postProcessor;
        instance.cutRate = data.cutRate;
        instance.jogRate = data.jogRate;
        instance.zDirectionStrategy = data.zDirectionStrategy;
        return instance;
    }
}

export class Feedrates {
    cutFeedrateXY: Quantity<any>;
    cutFeedrateZ: Quantity<any>;
    jogFeedrateXY: Quantity<any>;
    jogFeedrateZ: Quantity<any>;
    spindleSpeed: Quantity<any>;

    static fromData(data: Feedrates, target?: Feedrates): Feedrates {
        if (!data) {
            return data;
        }
        const instance = target || new Feedrates();
        instance.cutFeedrateXY = data.cutFeedrateXY;
        instance.cutFeedrateZ = data.cutFeedrateZ;
        instance.jogFeedrateXY = data.jogFeedrateXY;
        instance.jogFeedrateZ = data.jogFeedrateZ;
        instance.spindleSpeed = data.spindleSpeed;
        return instance;
    }
}

export class Provider {
    id: string;
    data: { [index: string]: string };
    token: Token;

    static fromData(data: Provider, target?: Provider): Provider {
        if (!data) {
            return data;
        }
        const instance = target || new Provider();
        instance.id = data.id;
        instance.data = __getCopyObjectFn(__identity<string>())(data.data);
        instance.token = Token.fromData(data.token);
        return instance;
    }
}

export class UnitSet {
    units: Unit[];

    static fromData(data: UnitSet, target?: UnitSet): UnitSet {
        if (!data) {
            return data;
        }
        const instance = target || new UnitSet();
        instance.units = __getCopyArrayFn(Unit.fromData)(data.units);
        return instance;
    }
}

export class PartInstance {
    name: string;
    hash: string;
    T: number[];
    t: number[];

    static fromData(data: PartInstance, target?: PartInstance): PartInstance {
        if (!data) {
            return data;
        }
        const instance = target || new PartInstance();
        instance.name = data.name;
        instance.hash = data.hash;
        instance.T = __getCopyArrayFn(__identity<number>())(data.T);
        instance.t = __getCopyArrayFn(__identity<number>())(data.t);
        return instance;
    }
}

export class CuttingMachineMaterial {
    machineId: string;
    material: string;
    bitId: string;
    thickness: Quantity<any>;
    sheetSize: SheetSize;
    instances: CuttingPartInstance[];

    static fromData(data: CuttingMachineMaterial, target?: CuttingMachineMaterial): CuttingMachineMaterial {
        if (!data) {
            return data;
        }
        const instance = target || new CuttingMachineMaterial();
        instance.machineId = data.machineId;
        instance.material = data.material;
        instance.bitId = data.bitId;
        instance.thickness = data.thickness;
        instance.sheetSize = SheetSize.fromData(data.sheetSize);
        instance.instances = __getCopyArrayFn(CuttingPartInstance.fromData)(data.instances);
        return instance;
    }
}

export class LayerFeature {
    path: PathUnion;
    outer: boolean;
    links: LayerLink[];

    static fromData(data: LayerFeature, target?: LayerFeature): LayerFeature {
        if (!data) {
            return data;
        }
        const instance = target || new LayerFeature();
        instance.path = Path.fromDataUnion(data.path);
        instance.outer = data.outer;
        instance.links = __getCopyArrayFn(LayerLink.fromData)(data.links);
        return instance;
    }
}

export class SheetSize {
    length: Quantity<any>;
    width: Quantity<any>;

    static fromData(data: SheetSize, target?: SheetSize): SheetSize {
        if (!data) {
            return data;
        }
        const instance = target || new SheetSize();
        instance.length = data.length;
        instance.width = data.width;
        return instance;
    }
}

export class Placement {
    part: string;
    instance: string;
    translation: number[];
    rotation: number[];
    startX: number;
    startY: number;

    static fromData(data: Placement, target?: Placement): Placement {
        if (!data) {
            return data;
        }
        const instance = target || new Placement();
        instance.part = data.part;
        instance.instance = data.instance;
        instance.translation = __getCopyArrayFn(__identity<number>())(data.translation);
        instance.rotation = __getCopyArrayFn(__identity<number>())(data.rotation);
        instance.startX = data.startX;
        instance.startY = data.startY;
        return instance;
    }
}

export class SheetSet {
    machineId: string;
    material: string;
    bitId: string;
    thickness: Quantity<any>;
    sheetSize: SheetSize;
    sheets: Sheet[];

    static fromData(data: SheetSet, target?: SheetSet): SheetSet {
        if (!data) {
            return data;
        }
        const instance = target || new SheetSet();
        instance.machineId = data.machineId;
        instance.material = data.material;
        instance.bitId = data.bitId;
        instance.thickness = data.thickness;
        instance.sheetSize = SheetSize.fromData(data.sheetSize);
        instance.sheets = __getCopyArrayFn(Sheet.fromData)(data.sheets);
        return instance;
    }
}

export class SliceFeature {
    up: Normal;
    hole: boolean;
    depth: Quantity<any>;
    startDepth: Quantity<any>;
    path: PathUnion;
    innerPaths: PathUnion[];
    edgePaths: PathUnion[];

    static fromData(data: SliceFeature, target?: SliceFeature): SliceFeature {
        if (!data) {
            return data;
        }
        const instance = target || new SliceFeature();
        instance.up = Normal.fromData(data.up);
        instance.hole = data.hole;
        instance.depth = data.depth;
        instance.startDepth = data.startDepth;
        instance.path = Path.fromDataUnion(data.path);
        instance.innerPaths = __getCopyArrayFn(Path.fromDataUnion)(data.innerPaths);
        instance.edgePaths = __getCopyArrayFn(Path.fromDataUnion)(data.edgePaths);
        return instance;
    }
}

export class ToolpathGroup {
    description: string;
    toolSelection: ToolSelection;
    features: ToolpathFeature[];

    static fromData(data: ToolpathGroup, target?: ToolpathGroup): ToolpathGroup {
        if (!data) {
            return data;
        }
        const instance = target || new ToolpathGroup();
        instance.description = data.description;
        instance.toolSelection = ToolSelection.fromData(data.toolSelection);
        instance.features = __getCopyArrayFn(ToolpathFeature.fromData)(data.features);
        return instance;
    }
}

export class ToolpathStrategy {
    orderStrategy: OrderStrategy;
    infillStrategy: InfillStrategy;
    rasterAngle: Quantity<any>;
    infillStepover: number;
    internalCornerStrategy: InternalCornerStrategy;
    externalCornerStrategy: ExternalCornerStrategy;
    vCarveInternalCornerStrategy: VCarveInternalCornerStrategy;
    vCarveExternalCornerStrategy: ExternalCornerStrategy;
    jogHeight: Quantity<any>;
    xHome: Quantity<any>;
    yHome: Quantity<any>;
    nestingGap: Quantity<any>;
    nestingRotations: number;
    punchThroughDepth: Quantity<any>;
    superSkinThickness: Quantity<any>;
    zeroStrategy: ZeroStrategy;
    edgeStrategy: EdgeStrategy;
    jogHeightStrategy: JogHeightStrategy;
    holdingStrategy: HoldingStrategy;
    drillStrategy: DrillStrategy;

    static fromData(data: ToolpathStrategy, target?: ToolpathStrategy): ToolpathStrategy {
        if (!data) {
            return data;
        }
        const instance = target || new ToolpathStrategy();
        instance.orderStrategy = data.orderStrategy;
        instance.infillStrategy = data.infillStrategy;
        instance.rasterAngle = data.rasterAngle;
        instance.infillStepover = data.infillStepover;
        instance.internalCornerStrategy = data.internalCornerStrategy;
        instance.externalCornerStrategy = data.externalCornerStrategy;
        instance.vCarveInternalCornerStrategy = data.vCarveInternalCornerStrategy;
        instance.vCarveExternalCornerStrategy = data.vCarveExternalCornerStrategy;
        instance.jogHeight = data.jogHeight;
        instance.xHome = data.xHome;
        instance.yHome = data.yHome;
        instance.nestingGap = data.nestingGap;
        instance.nestingRotations = data.nestingRotations;
        instance.punchThroughDepth = data.punchThroughDepth;
        instance.superSkinThickness = data.superSkinThickness;
        instance.zeroStrategy = data.zeroStrategy;
        instance.edgeStrategy = data.edgeStrategy;
        instance.jogHeightStrategy = data.jogHeightStrategy;
        instance.holdingStrategy = data.holdingStrategy;
        instance.drillStrategy = data.drillStrategy;
        return instance;
    }
}

export class Token {
    accessToken: string;
    tokenType: string;
    expiresIn: number;
    refreshToken: string;
    tokenReceived: Date;

    static fromData(data: Token, target?: Token): Token {
        if (!data) {
            return data;
        }
        const instance = target || new Token();
        instance.accessToken = data.accessToken;
        instance.tokenType = data.tokenType;
        instance.expiresIn = data.expiresIn;
        instance.refreshToken = data.refreshToken;
        instance.tokenReceived = data.tokenReceived;
        return instance;
    }
}

export class Unit {
    unit: string;
    precision: number;

    static fromData(data: Unit, target?: Unit): Unit {
        if (!data) {
            return data;
        }
        const instance = target || new Unit();
        instance.unit = data.unit;
        instance.precision = data.precision;
        return instance;
    }
}

export class CuttingPartInstance {
    partId: string;
    name: string;
    off: number;
    instances: number;
    slice: Slice;

    static fromData(data: CuttingPartInstance, target?: CuttingPartInstance): CuttingPartInstance {
        if (!data) {
            return data;
        }
        const instance = target || new CuttingPartInstance();
        instance.partId = data.partId;
        instance.name = data.name;
        instance.off = data.off;
        instance.instances = data.instances;
        instance.slice = Slice.fromData(data.slice);
        return instance;
    }
}

export class LayerLink {
    face: string;
    hole: boolean;
    depth: Quantity<any>;

    static fromData(data: LayerLink, target?: LayerLink): LayerLink {
        if (!data) {
            return data;
        }
        const instance = target || new LayerLink();
        instance.face = data.face;
        instance.hole = data.hole;
        instance.depth = data.depth;
        return instance;
    }
}

export class ToolSelection {
    number: number;

    static fromData(data: ToolSelection, target?: ToolSelection): ToolSelection {
        if (!data) {
            return data;
        }
        const instance = target || new ToolSelection();
        instance.number = data.number;
        return instance;
    }
}

export class ToolpathFeature {
    featureType: string;
    part: string;
    segments: ToolpathPathSegmentUnion[];
    start: PointXY;
    end: PointXY;

    static fromData(data: ToolpathFeature, target?: ToolpathFeature): ToolpathFeature {
        if (!data) {
            return data;
        }
        const instance = target || new ToolpathFeature();
        instance.featureType = data.featureType;
        instance.part = data.part;
        instance.segments = __getCopyArrayFn(ToolpathPathSegment.fromDataUnion)(data.segments);
        instance.start = PointXY.fromData(data.start);
        instance.end = PointXY.fromData(data.end);
        return instance;
    }
}

export class ToolpathPathSegment {
    type: "xyArcCut" | "xyCut" | "xyJog" | "zCut" | "zJog" | "JogStart" | "JogHome";
    x: Quantity<any>;
    y: Quantity<any>;
    z: Quantity<any>;
    safeZ: boolean;

    static fromData(data: ToolpathPathSegment, target?: ToolpathPathSegment): ToolpathPathSegment {
        if (!data) {
            return data;
        }
        const instance = target || new ToolpathPathSegment();
        instance.type = data.type;
        instance.x = data.x;
        instance.y = data.y;
        instance.z = data.z;
        instance.safeZ = data.safeZ;
        return instance;
    }

    static fromDataUnion(data: ToolpathPathSegmentUnion): ToolpathPathSegmentUnion {
        if (!data) {
            return data;
        }
        switch (data.type) {
            case "xyArcCut":
                return XYArcCut.fromData(data);
            case "xyCut":
                return XYCut.fromData(data);
            case "xyJog":
                return XYJog.fromData(data);
            case "zCut":
                return ZCut.fromData(data);
            case "zJog":
                return ZJog.fromData(data);
            case "JogStart":
                return JogStart.fromData(data);
            case "JogHome":
                return JogHome.fromData(data);
        }
    }
}

export class PointXY {
    x: Quantity<any>;
    y: Quantity<any>;

    static fromData(data: PointXY, target?: PointXY): PointXY {
        if (!data) {
            return data;
        }
        const instance = target || new PointXY();
        instance.x = data.x;
        instance.y = data.y;
        return instance;
    }
}

export class XYArcCut extends ToolpathPathSegment {
    type: "xyArcCut";
    dx: Quantity<any>;
    dy: Quantity<any>;
    xc: Quantity<any>;
    yc: Quantity<any>;
    i: Quantity<any>;
    j: Quantity<any>;
    clockwise: boolean;

    static fromData(data: XYArcCut, target?: XYArcCut): XYArcCut {
        if (!data) {
            return data;
        }
        const instance = target || new XYArcCut();
        super.fromData(data, instance);
        instance.dx = data.dx;
        instance.dy = data.dy;
        instance.xc = data.xc;
        instance.yc = data.yc;
        instance.i = data.i;
        instance.j = data.j;
        instance.clockwise = data.clockwise;
        return instance;
    }
}

export class XYCut extends ToolpathPathSegment {
    type: "xyCut";
    dx: Quantity<any>;
    dy: Quantity<any>;

    static fromData(data: XYCut, target?: XYCut): XYCut {
        if (!data) {
            return data;
        }
        const instance = target || new XYCut();
        super.fromData(data, instance);
        instance.dx = data.dx;
        instance.dy = data.dy;
        return instance;
    }
}

export class XYJog extends ToolpathPathSegment {
    type: "xyJog";
    dx: Quantity<any>;
    dy: Quantity<any>;

    static fromData(data: XYJog, target?: XYJog): XYJog {
        if (!data) {
            return data;
        }
        const instance = target || new XYJog();
        super.fromData(data, instance);
        instance.dx = data.dx;
        instance.dy = data.dy;
        return instance;
    }
}

export class ZCut extends ToolpathPathSegment {
    type: "zCut";
    dz: Quantity<any>;

    static fromData(data: ZCut, target?: ZCut): ZCut {
        if (!data) {
            return data;
        }
        const instance = target || new ZCut();
        super.fromData(data, instance);
        instance.dz = data.dz;
        return instance;
    }
}

export class ZJog extends ToolpathPathSegment {
    type: "zJog";
    dz: Quantity<any>;

    static fromData(data: ZJog, target?: ZJog): ZJog {
        if (!data) {
            return data;
        }
        const instance = target || new ZJog();
        super.fromData(data, instance);
        instance.dz = data.dz;
        return instance;
    }
}

export class JogStart extends ToolpathPathSegment {
    type: "JogStart";
    dx: Quantity<any>;
    dy: Quantity<any>;
    dz: Quantity<any>;

    static fromData(data: JogStart, target?: JogStart): JogStart {
        if (!data) {
            return data;
        }
        const instance = target || new JogStart();
        super.fromData(data, instance);
        instance.dx = data.dx;
        instance.dy = data.dy;
        instance.dz = data.dz;
        return instance;
    }
}

export class JogHome extends ToolpathPathSegment {
    type: "JogHome";
    dx: Quantity<any>;
    dy: Quantity<any>;
    dz: Quantity<any>;

    static fromData(data: JogHome, target?: JogHome): JogHome {
        if (!data) {
            return data;
        }
        const instance = target || new JogHome();
        super.fromData(data, instance);
        instance.dx = data.dx;
        instance.dy = data.dy;
        instance.dz = data.dz;
        return instance;
    }
}

export type Quantity<T> = string;

export type ProjectStatus = "Empty" | "Slicing" | "Sliced" | "Nesting" | "Nested" | "Generating" | "Generated" | "Complete";

export type ZDirectionStrategy = "PositiveUp" | "PositiveDown";

export type OrderStrategy = "ByPart" | "ByFeatureType";

export type InfillStrategy = "None" | "SpiralIn" | "SpiralOut" | "Raster" | "Grid" | "Hex";

export type InternalCornerStrategy = "None" | "TBone" | "DogBone";

export type ExternalCornerStrategy = "Round" | "FlyPast";

export type VCarveInternalCornerStrategy = "Sharp" | "Round";

export type ZeroStrategy = "Table" | "TopOfMaterial";

export type EdgeStrategy = "None" | "SuperEdge";

export type JogHeightStrategy = "SetHeight" | "SafeZ";

export type HoldingStrategy = "Vacuum" | "SuperSkin" | "Tabs";

export type DrillStrategy = "None" | "Peck";

export type PathUnion = CirclePath | EllipsePath | LineArcPath;

export type ToolpathPathSegmentUnion = XYArcCut | XYCut | XYJog | ZCut | ZJog | JogStart | JogHome;

function __getCopyArrayFn<T>(itemCopyFn: (item: T) => T): (array: T[]) => T[] {
    return (array: T[]) => __copyArray(array, itemCopyFn);
}

function __copyArray<T>(array: T[], itemCopyFn: (item: T) => T): T[] {
    return array && array.map(item => item && itemCopyFn(item));
}

function __getCopyObjectFn<T>(itemCopyFn: (item: T) => T): (object: { [index: string]: T }) => { [index: string]: T } {
    return (object: { [index: string]: T }) => __copyObject(object, itemCopyFn);
}

function __copyObject<T>(object: { [index: string]: T }, itemCopyFn: (item: T) => T): { [index: string]: T } {
    if (!object) {
        return object;
    }
    const result: any = {};
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const value = object[key];
            result[key] = value && itemCopyFn(value);
        }
    }
    return result;
}

function __identity<T>(): (value: T) => T {
    return value => value;
}
