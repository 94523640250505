import { ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { fromPromise } from 'rxjs/internal-compatibility';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
export class UpdateService {
    constructor(appRef, updates) {
        this.appRef = appRef;
        this.updates = updates;
        this.updates.activated.subscribe(update => {
            console.log('Activated');
            console.log(update);
        });
        this.updates.available.subscribe(update => {
            console.log('Available');
            console.log(update);
        });
    }
    check() {
        return fromPromise(this.updates.checkForUpdate());
    }
}
UpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i1.SwUpdate)); }, token: UpdateService, providedIn: "root" });
