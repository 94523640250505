import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {distinctUntilChanged} from 'rxjs/internal/operators/distinctUntilChanged';
import {debounceTime, map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-text-field',
    templateUrl: './text-field.component.html',
    styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent implements OnInit {

    @Input() type: string;
    @Input() field: string;
    @Input() disabled = false;
    @Input() options = new Array<string>();

    @Input()
    get object() {
        return this.objectValue;
    }

    set object(o: any) {
        this.objectValue = o;
        this.objectChange.emit(this.objectValue);
        this.getValue();
    }

    @Output() objectChange = new EventEmitter();

    private objectValue: any;
    public displayValue: string;
    search = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(t => t.length < 1 ? [] : this.options.filter(o => o.toLowerCase().startsWith(t.toLowerCase())))
        );
    }

    constructor() {
    }

    ngOnInit() {
        this.displayValue = null;
        this.getValue();
    }

    getValue() {
        if (this.objectValue) {
            this.displayValue = this.objectValue[this.field];
        } else {
            this.displayValue = null;
        }
    }

    setValue(value: string) {
        if (this.objectValue) {
            this.objectValue[this.field] = value;
            console.log(this.objectValue);
            this.objectChange.emit(this.objectValue);
        }
    }

}
