/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./webgl-renderer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "angular-resize-event";
import * as i7 from "./webgl-renderer.component";
var styles_WebGLRendererComponent = [i0.styles];
var RenderType_WebGLRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WebGLRendererComponent, data: {} });
export { RenderType_WebGLRendererComponent as RenderType_WebGLRendererComponent };
function View_WebGLRendererComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ": ", "%"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = (_co.progressMap.get(_v.context.$implicit) * 100); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_WebGLRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["id", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["id", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"], ["size", "5x"], ["spin", "true"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(3, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"], spin: [1, "spin"], size: [2, "size"] }, null), i1.ɵpad(4, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebGLRendererComponent_2)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, "fas", "spinner"); var currVal_3 = "true"; var currVal_4 = "5x"; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.progressMap.keys(); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).title; var currVal_1 = i1.ɵnov(_v, 3).renderedIconHTML; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_WebGLRendererComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { canvasRef: 0 }), i1.ɵqud(402653184, 2, { ownerRef: 0 }), (_l()(), i1.ɵeld(2, 0, [[2, 0], ["owner", 1]], null, 2, "div", [["class", "canvas-owner"]], null, [[null, "resized"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resized" === en)) {
        var pd_0 = (_co.onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 212992, null, 0, i6.ResizedDirective, [i1.ElementRef], null, { resized: "resized" }), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["canvas", 1]], null, 0, "canvas", [["class", "webgl-canvas"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WebGLRendererComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = (_co.progressMap.size > 0); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_WebGLRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "three-webgl-renderer", [], null, null, null, View_WebGLRendererComponent_0, RenderType_WebGLRendererComponent)), i1.ɵdid(1, 5292032, null, 3, i7.WebGLRendererComponent, [i1.NgZone, i1.ChangeDetectorRef], null, null), i1.ɵqud(335544320, 1, { sceneComponent: 0 }), i1.ɵqud(335544320, 2, { cameraComponent: 0 }), i1.ɵqud(603979776, 3, { passes: 1 })], null, null); }
var WebGLRendererComponentNgFactory = i1.ɵccf("three-webgl-renderer", i7.WebGLRendererComponent, View_WebGLRendererComponent_Host_0, { clearColor: "clearColor", clearOpacity: "clearOpacity" }, {}, []);
export { WebGLRendererComponentNgFactory as WebGLRendererComponentNgFactory };
