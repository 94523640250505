import { Pass } from 'three/examples/jsm/postprocessing/Pass';
import {WebGLRendererComponent} from '../webgl-renderer.component';
import {AbstractObject3D} from '../../objects/abstract-object-3d';
import * as THREE from 'three';

export abstract class AbstractPass<T extends Pass> {

    public abstract setup(renderer: WebGLRendererComponent): T;

    /**
     * Utility to fetch array of mesh objects and ignore empty objects
     *
     * @param objects
     */
    leaves(objects: AbstractObject3D<THREE.Object3D>[]): THREE.Object3D[] {
        return objects.map(obj => obj.getObject())
            .map(obj => obj.children.length === 0 ? [] : obj.children)
            .reduce((out, currentValue) => out.concat(currentValue), [])
            .filter(((value, index, array) => array.indexOf(value) === index))
            .filter(obj => obj['isMesh'] != null);
    }
}
