import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from 'ng2-ui-auth';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Restangular} from 'ngx-restangular';
import {SketchupService} from '../services/sketchup.service';
import {Fusion360Service} from '../services/fusion360.service';
import {UserService} from '../services/user.service';

// TODO: Move auth code into UserService. UserService tracks current user and role etc.
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService,
                private userService: UserService,
                private router: Router,
                private restangular: Restangular,
                private sketchupService: SketchupService,
                private fusion360Service: Fusion360Service) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log('Checking route ' + getResolvedUrl(next));
        const to = next.data['noAuthURL'] as string;
        if (this.authService.isAuthenticated()) {
            if (next.data['admin'] === true) {
                // It's an admin page, hit an API method to check we're an admin
                const obs = Observable.create((observer) => {
                    this.restangular.one('users', 'adminToken').get().subscribe({
                        next: (token) => {
                            observer.next(true);
                            observer.complete();
                        },
                        error: (err) => {
                            // We failed auth, so we say no
                            console.log(err);
                            if (!to) {
                                observer.next(this.router.createUrlTree(['/login'], {
                                    queryParams: Object.assign({}, {to: getResolvedUrl(next)}, next.queryParams)
                                }));
                            } else {
                                observer.next(this.router.createUrlTree([to]));
                            }
                            observer.complete();
                        }
                    });
                });
                return obs;
            }
            // Ensure user is loaded into UserService before going to page
            return this.userService.me(false).pipe(map((u) => true));
        }
        if (this.sketchupService.inSketchup()) {
            // Post info on Sketchup to server
            return this.restangular.one('auth').post('skp', {name: 'SketchUp', uuid: next.queryParams.skp})
                .pipe(map(token => {
                    this.authService.setToken(token['token']);
                    return true;
                }), catchError(err => {
                    // If unknown then launch signup window and return false
                    this.sketchupService.launchSignup();
                    return of(false);
                }));
        }
        if (!to) {
            return this.router.createUrlTree(['/login'], {queryParams: Object.assign({}, {to: getResolvedUrl(next)}, next.queryParams)});
        }
        return this.router.createUrlTree([to]);
    }
}

function getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/');
}
