import {Component, Input, OnInit} from '@angular/core';
import { saveAs } from 'file-saver/dist/FileSaver';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ProjectService} from '../services/project.service';
import {Intercom} from 'ng-intercom';

@Component({
  selector: 'app-post-process-button',
  templateUrl: './post-process-button.component.html',
  styleUrls: ['./post-process-button.component.scss']
})
export class PostProcessButtonComponent implements OnInit {
  inProgress = false;
  @Input() projectId: string;
  @Input() sheetId: string;
  @Input() disabled: boolean;

  constructor(private http: HttpClient, private projectService: ProjectService, private intercom: Intercom) { }

  ngOnInit() {
  }

  download() {
    this.inProgress = true;
    const url = '/api/projects/' + this.projectId + '/sheets/' + this.sheetId + '/code';
    const headers = new HttpHeaders();
    headers.set('Accept', '*/*');
    this.http.get(url, {headers: headers, observe: 'response', responseType: 'blob'}).subscribe({
      next: (response) => {
        const contentDispositionHeader: string = response.headers.get('Content-Disposition');
        const parts: string[] = contentDispositionHeader.split(';');
        const filename = parts[1].split('=')[1];
        const blob = new Blob([response.body as any], {type: response.headers.get('Content-Type')});
        saveAs(blob, filename);
        this.inProgress = false;
        this.intercom.trackEvent('export', {project: this.projectId, sheet: this.sheetId});
      },
      error: err => { this.inProgress = false; }
    });
  }
}
