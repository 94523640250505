export const RGB_COLOR_REGEX = /\((\d+),\s*(\d+),\s*(\d+)(,\s*(\d*.\d*))?\)/;

export class Color {
    public r: number;
    public g: number;
    public b: number;
    public a: number;

    constructor(r?: string|number, g?: number, b?: number, a?: number) {
        if (typeof r === 'string') {
            r = r.trim();
            if (r.indexOf('#') === 0) {
                r = r.substr(r.indexOf('#') + 1);
                this.r = parseInt(r.substr(0, 2), 16);
                this.g = parseInt(r.substr(2, 2), 16);
                this.b = parseInt(r.substr(4, 2), 16);
            } else if (r.indexOf('rgb') === 0) {
                const res = RGB_COLOR_REGEX.exec(r);
                this.r = parseInt(res[1], 10);
                this.g = parseInt(res[2], 10);
                this.b = parseInt(res[3], 10);
                this.a = res[5] ? parseFloat(res[5]) : 1;
            }
        } else {
            this.r = r;
            this.g = g;
            this.b = b;
            this.a = a || 1;
        }
    }

    toHex() {
        return '#' + Math.round(this.r).toString(16) + Math.round(this.g).toString(16) + Math.round(this.b).toString(16);
    }

    toRgb() {
        return `rgb(${this.r}, ${this.g}, ${this.b})`;
    }

    toRgba() {
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
    }

    interpolate(other: Color, x: number): Color {
        x = Math.min(Math.max(0, x), 1);
        return new Color(this.r + x * (other.r - this.r),
            this.g + x * (other.g - this.g),
            this.b + x * (other.b - this.b),
            this.a + x * (other.a - this.a));
    }
}
