import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss']
})
export class NumberFieldComponent implements OnInit {

  @Input() type: string;
  @Input() field: string;
  @Input() disabled = false;

  @Input()
  get object() {
    return this.objectValue;
  }

  set object(o: any) {
    this.objectValue = o;
    this.objectChange.emit(this.objectValue);
    this.getValue();
  }

  @Output() objectChange = new EventEmitter();

  private objectValue: any;
  public displayValue: number;

  constructor() {
  }

  ngOnInit() {
    this.displayValue = null;
    this.getValue();
  }

  getValue() {
    if (this.objectValue) {
      this.displayValue = this.objectValue[this.field];
    } else {
      this.displayValue = null;
    }
  }

  setValue(value: string) {
    if (this.objectValue) {
      this.objectValue[this.field] = value;
      console.log(this.objectValue);
      this.objectChange.emit(this.objectValue);
    }
  }

}
