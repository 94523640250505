import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export enum AlertType {
    SUCCESS, INFO, WARNING, DANGER
}

export enum AlertTime {
    SHORT = 4000, // ms
    LONG = 10000 // ms
}

export interface Alert {
    type: AlertType;
    time: AlertTime;
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    private readonly status: BehaviorSubject<Alert>;
    private parser: DOMParser;

    constructor() {
        this.parser = new DOMParser();
        this.status = new BehaviorSubject(null);
    }

    stripHTML(message: string): string {
      const doc = this.parser.parseFromString(message, 'text/html');
      return doc.body.textContent || message;
    }

    getStatus(): BehaviorSubject<Alert> {
        return this.status;
    }

    show(alert: Alert) {
        this.status.next(alert);
    }

    alert(type: AlertType, time: AlertTime, message: string | object) {
        console.log(message);
        if (typeof message === 'string') {
            this.show({type: type, time: time, message: this.stripHTML(message as string)});
        } else {
            const messageObject = message as object;
            if (messageObject['error']) {
                this.alert(type, time, messageObject['error']);
            } else if (messageObject['statusText']) {
                this.alert(type, time, messageObject['statusText']);
            } else if (messageObject['message']) {
                this.alert(type, time, messageObject['message']);
            } else {
                this.alert(type, time, messageObject.toString());
            }
        }
    }

    warning(message: string | object) {
        this.alert(AlertType.WARNING, AlertTime.LONG, message);
    }

    error(message: string | object) {
        this.alert(AlertType.DANGER, AlertTime.LONG, message);
    }

    info(message: string | object) {
        this.alert(AlertType.INFO, AlertTime.LONG, message);
    }

    success(message: string | object) {
        this.alert(AlertType.SUCCESS, AlertTime.LONG, message);
    }

}
