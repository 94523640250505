import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LandingComponent } from './landing/landing.component';
import { View2dComponent } from './view2d/view2d.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { SignupComponent } from './signup/signup.component';
import { ProjectsComponent } from './projects/projects.component';
import { PartsListComponent } from './parts-list/parts-list.component';
import { ProfileComponent } from './profile/profile.component';
import { SketchupComponent } from './sketchup/sketchup.component';
import { MachinesComponent } from './machines/machines.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminProjectsComponent } from './admin/admin-projects/admin-projects.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminTasksComponent } from './admin/admin-tasks/admin-tasks.component';
import { AdminFilesComponent } from './admin/admin-files/admin-files.component';
import { AdminInvitationsComponent } from './admin/admin-invitations/admin-invitations.component';
import { AdminMachinesComponent } from './admin/admin-machines/admin-machines.component';
import { AdminPostProcessorComponent } from './admin/admin-post-processor/admin-post-processor.component';
import { SheetsComponent } from './sheets/sheets.component';
import { PartComponent } from './part/part.component';
import { MachineEditorComponent } from './machine-editor/machine-editor.component';
import { TOSComponent } from './tos/tos.component';
import { AssemblyComponent } from './assembly/assembly.component';
import { BitEditorComponent } from './bit-editor/bit-editor.component';
import { WaitingComponent } from './waiting/waiting.component';
import { AppGuard } from './guards/app.guard';
import { UserResolver } from './guards/user-resolver';
import { PostProcessorEditorComponent } from './post-processor-editor/post-processor-editor.component';
import { WorkshopComponent } from './workshop/workshop.component';
const ɵ0 = { noAuthURL: 'landing' }, ɵ1 = { noAuthURL: 'landing' }, ɵ2 = { noScroll: true }, ɵ3 = { noScroll: true }, ɵ4 = { noHeader: true }, ɵ5 = { admin: true };
// Can use this on routes: canActivate: [AuthGuard]
const routes = [
    { path: '', component: ProjectsComponent, canActivate: [AuthGuard], data: ɵ0, resolve: { user: UserResolver } },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard], data: ɵ1 },
    { path: 'signup', component: SignupComponent },
    { path: 'landing', component: LandingComponent },
    // {
    //     path: 'project/:id', component: ProjectComponent, canActivate: [AuthGuard], resolve: {user: UserResolver}, data: {noScroll: true}, children: [
    //         {path: '', component: PartsListComponent, canActivate: [AuthGuard], resolve: {user: UserResolver}},
    //         {path: 'sheets', component: SheetsComponent, canActivate: [AuthGuard], resolve: {user: UserResolver}},
    //         {path: 'view', component: AssemblyComponent, canActivate: [AuthGuard], resolve: {user: UserResolver}},
    //         {
    //             path: 'part/:partid',
    //             component: PartComponent,
    //             canActivate: [AuthGuard],
    //             data: {noScroll: true},
    //             resolve: {user: UserResolver}
    //         },
    //         {
    //             path: 'part/:partid/toolpath',
    //             component: View2dComponent,
    //             canActivate: [AuthGuard],
    //             data: {noScroll: true},
    //             resolve: {user: UserResolver}
    //         }
    //     ]
    // },
    { path: 'project/:id', component: PartsListComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
    { path: 'project/:id/sheets', component: SheetsComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
    { path: 'project/:id/view', component: AssemblyComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
    {
        path: 'project/:id/part/:partid',
        component: PartComponent,
        canActivate: [AuthGuard],
        data: ɵ2,
        resolve: { user: UserResolver }
    },
    {
        path: 'project/:id/part/:partid/toolpath',
        component: View2dComponent,
        canActivate: [AuthGuard],
        data: ɵ3,
        resolve: { user: UserResolver }
    },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
    { path: 'sketchup', component: SketchupComponent, data: ɵ4 },
    { path: 'workshop', component: WorkshopComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
    { path: 'machines', component: MachinesComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
    { path: 'machines/:id', component: MachineEditorComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
    { path: 'post/:id', component: PostProcessorEditorComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
    { path: 'bits/:id', component: BitEditorComponent, canActivate: [AuthGuard], resolve: { user: UserResolver } },
    { path: 'tos', component: TOSComponent },
    { path: 'wait', component: WaitingComponent, canActivate: [AppGuard], resolve: { user: UserResolver } },
    {
        path: 'admin',
        // canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: AdminDashboardComponent,
                canActivate: [AuthGuard],
                data: ɵ5,
                children: [
                    { path: 'users', component: AdminUsersComponent },
                    { path: 'projects', component: AdminProjectsComponent },
                    { path: 'tasks', component: AdminTasksComponent },
                    { path: 'files', component: AdminFilesComponent },
                    { path: 'invitations', component: AdminInvitationsComponent },
                    { path: 'machines', component: AdminMachinesComponent },
                    { path: 'pp', component: AdminPostProcessorComponent }
                ]
            }
        ]
    },
    { path: '**', component: PageNotFoundComponent }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
