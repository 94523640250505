<div class="container-fluid mt-2">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col" colspan="2">Machine</th>
          <th scope="col" colspan="3">Cutting Area</th>
          <th scope="col" colspan="2">Spindle Speed</th>
          <th scope="col" colspan="2">Collet Size</th>
          <th scope="col" colspan="2">Cutting Speed</th>
          <th scope="col">Materials</th>
          <th scope="col">Actions</th>
        </tr>
        <tr>
          <th scope="col"></th>
          <th scope="col">Brand</th>
          <th scope="col">Model</th>
          <th scope="col">x</th>
          <th scope="col">y</th>
          <th scope="col">z</th>
          <th scope="col">min</th>
          <th scope="col">max</th>
          <th scope="col">min</th>
          <th scope="col">max</th>
          <th scope="col">min</th>
          <th scope="col">max</th>
          <th scope="col"></th>
          <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let machine of (machines$ | async); index as i">
          <th scope="row">{{machine.name}}</th>
          <td>{{machine.brand}}</td>
          <td>{{machine.model}}</td>
          <td colspan="3"><app-parameter-field type="machine" [field]="['xLength','yLength', 'zHeight']"></app-parameter-field></td>
          <td colspan="2"><app-parameter-field type="machine" [field]="['minSpindleSpeed', 'maxSpindleSpeed']"></app-parameter-field></td>
          <td colspan="2"><app-parameter-field type="machine" [field]="['minColletSize', 'maxColletSize']"></app-parameter-field></td>
          <td colspan="2"><app-parameter-field type="machine" [field]="['minCuttingSpeed', 'maxCuttingSpeed']"></app-parameter-field></td>
          <td><app-material-picker></app-material-picker></td>
          <td>
            <button class="btn btn-primary btn-sm">Edit</button>
            <button class="btn btn-primary btn-sm ml-1">Delete</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
