import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {SketchupService} from '../services/sketchup.service';
import {Fusion360Service} from '../services/fusion360.service';
import {AuthService} from 'ng2-ui-auth';
import {catchError, map} from 'rxjs/operators';
import {Restangular} from 'ngx-restangular';

@Injectable({
    providedIn: 'root'
})
export class AppGuard implements CanActivate {

    constructor(private router: Router,
                private auth: AuthService,
                private restangular: Restangular,
                private sketchupService: SketchupService,
                private fusion360Service: Fusion360Service) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.sketchupService.inSketchup() || this.fusion360Service.inFusion360()) {
            if (!this.auth.isAuthenticated()) {
                // Post info on Sketchup to server
                return this.restangular.one('auth').post('skp', {name: 'SketchUp', uuid: next.queryParams.skp})
                    .pipe(map(token => {
                        this.auth.setToken(token['token']);
                        return true;
                    }), catchError(err => {
                        // If unknown then launch signup window and return false
                        this.sketchupService.launchSignup();
                        return of(false);
                    }));
            }
            return true;
        }
        return this.router.createUrlTree(['/']);
    }

}
