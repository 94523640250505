/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./number-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "./number-field.component";
var styles_NumberFieldComponent = [i0.styles];
var RenderType_NumberFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NumberFieldComponent, data: {} });
export { RenderType_NumberFieldComponent as RenderType_NumberFieldComponent };
export function View_NumberFieldComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "input-group input-group-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 7, "input", [["class", "form-control"], ["placement", "top"], ["type", "number"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 3).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i1.ɵnov(_v, 3).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.displayValue = $event) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = (_co.setValue($event) !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(3, 16384, null, 0, i2.NumberValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i2.NumberValueAccessor]), i1.ɵdid(5, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(8, 737280, null, 0, i3.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i3.NgbTooltipConfig, i1.NgZone, i4.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { placement: [0, "placement"], ngbTooltip: [1, "ngbTooltip"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.disabled; var currVal_8 = _co.displayValue; _ck(_v, 5, 0, currVal_7, currVal_8); var currVal_9 = "top"; var currVal_10 = i1.ɵinlineInterpolate(1, "Edit ", _co.field, ""); _ck(_v, 8, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_NumberFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-number-field", [], null, null, null, View_NumberFieldComponent_0, RenderType_NumberFieldComponent)), i1.ɵdid(1, 114688, null, 0, i5.NumberFieldComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NumberFieldComponentNgFactory = i1.ɵccf("app-number-field", i5.NumberFieldComponent, View_NumberFieldComponent_Host_0, { type: "type", field: "field", disabled: "disabled", object: "object" }, { objectChange: "objectChange" }, []);
export { NumberFieldComponentNgFactory as NumberFieldComponentNgFactory };
