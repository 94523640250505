// From https://stackoverflow.com/questions/34177221/angular2-how-to-inject-window-into-an-angular2-service
import {Injectable} from '@angular/core';


interface ADSK {
    fusionSendData(action: string, data: string);
}

interface IFabberWindow extends Window {
    adsk: ADSK;
}

function getWindow(): any {
    return window;
}

@Injectable()
export class Fusion360Service {

    constructor() {
        window['fusionJavaScriptHandler'] = {
            handle: function (action, data) {
                alert(action + ' ' + JSON.stringify(data));
                return 'OK';
            }
        };
    }

    get nativeWindow(): IFabberWindow {
        return getWindow();
    }

    inFusion360(): boolean {
        if (this.nativeWindow.adsk) {
            return true;
        }
        return false;
    }
}
