<div class="container mt-1">
    <div class="row">
        <div class="col-md-12">
            <p class="lead">Please wait while Fabber processes your geometry...</p>
        </div>
    </div>
</div>
<div class="d-flex justify-content-center">
    <object id="svg1" data="/assets/waiting.svg" type="image/svg+xml"></object>
</div>
