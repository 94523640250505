import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {PostProcessor} from '../../../target/fabber-model';
import {MachineService} from '../services/machine.service';
import {ActivatedRoute} from '@angular/router';
import {StringTemplateService} from '../services/st/string-template.service';

@Component({
    selector: 'app-post-processor-editor',
    templateUrl: './post-processor-editor.component.html',
    styleUrls: ['./post-processor-editor.component.scss']
})
export class PostProcessorEditorComponent implements OnInit {
    post$: Observable<PostProcessor>;
    output: string;

    constructor(private machineService: MachineService, private route: ActivatedRoute, private st: StringTemplateService) {
    }

    ngOnInit() {
        this.post$ = this.machineService.getPostProcessor(this.route.snapshot.params['id']);
    }

    editable(post: PostProcessor): boolean {
        return post.owner ? true : false;
    }
}
