import { Component, OnInit } from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private loadingBar: LoadingBarService) {
  }

  ngOnInit() {
      this.loadingBar.start();
  }

}
