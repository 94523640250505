<div class="container mt-1 top-buttons">
    <div class="row">
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg" [routerLink]="['/project', project?.id]"><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> Project</button>
        </div>
        <div class="col-md-9"></div>
    </div>
</div>
<div #view2dcontainer class="view2dcontainer">
    <div #view2d id="view2d"></div>
</div>
