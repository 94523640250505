/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view2d.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./view2d.component";
import * as i7 from "../services/project.service";
import * as i8 from "ng2-ui-auth";
var styles_View2dComponent = [i0.styles];
var RenderType_View2dComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_View2dComponent, data: {} });
export { RenderType_View2dComponent as RenderType_View2dComponent };
export function View_View2dComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { view2d: 0 }), i1.ɵqud(402653184, 2, { view2dcontainer: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "container mt-1 top-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "button", [["class", "btn btn-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 2), (_l()(), i1.ɵeld(8, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i3.View_FaIconComponent_0, i3.RenderType_FaIconComponent)), i1.ɵdid(9, 573440, null, 0, i4.FaIconComponent, [i5.DomSanitizer, i4.FaConfig, i4.FaIconLibrary, [2, i4.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(10, 2), (_l()(), i1.ɵted(-1, null, [" Project"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "col-md-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, [[2, 0], ["view2dcontainer", 1]], null, 1, "div", [["class", "view2dcontainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, [[1, 0], ["view2d", 1]], null, 0, "div", [["id", "view2d"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 7, 0, "/project", ((_co.project == null) ? null : _co.project.id)); _ck(_v, 6, 0, currVal_0); var currVal_3 = _ck(_v, 10, 0, "fas", "arrow-left"); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 9).title; var currVal_2 = i1.ɵnov(_v, 9).renderedIconHTML; _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_View2dComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view2d", [], null, null, null, View_View2dComponent_0, RenderType_View2dComponent)), i1.ɵdid(1, 4308992, null, 0, i6.View2dComponent, [i7.ProjectService, i2.ActivatedRoute, i8.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var View2dComponentNgFactory = i1.ɵccf("app-view2d", i6.View2dComponent, View_View2dComponent_Host_0, {}, {}, []);
export { View2dComponentNgFactory as View2dComponentNgFactory };
