import {Component, Input, OnInit} from '@angular/core';
import {UpdateService} from '../../services/update.service';

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
    @Input() version: string;

    constructor(private updateService: UpdateService) {
    }

    ngOnInit() {
    }

  checkForUpdates() {
    this.updateService.check();
  }
}
