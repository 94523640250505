<div class="container mt-1">
    <div class="row">
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg" [routerLink]="" (click)="reload()"><fa-icon [spin]="loading" [icon]="['fas', 'sync-alt']"></fa-icon></button>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-3">
            <button id="machines-button" class="btn btn-primary btn-lg float-right" [routerLink]="['/machines']">Go to my Machines</button>
        </div>
    </div>
</div>
<div class="container card-columns mt-2">
    <div class="card" style="width: 18rem;" *ngFor="let project of projects">
         <img class="card-img-top" src="/api/projects/{{project.id}}.png?token={{auth.getToken()}}" alt="{{project.name}}">
        <div class="card-body">
            <h5 class="card-title">{{project.name}}</h5>
            <a [routerLink]="['/project', project.id]" class="btn btn-primary">Open project</a>
            <a [routerLink]="" [swal]="deleteSwal" class="btn btn-danger float-right"><fa-icon [icon]="['fas', 'trash-alt']"></fa-icon></a>
            <swal
                #deleteSwal
                title="Delete {{ project.name }}?"
                text="This cannot be undone"
                type="question"
                [showCancelButton]="true"
                [focusCancel]="true"
                (confirm)="deleteProject(project)">
            </swal>
        </div>
        <div class="card-footer">
            <small class="text-muted">Last edited: {{ project.edited | moment}}</small>
        </div>
    </div>
</div>
