import {Component, OnInit} from '@angular/core';
import {Machine, Bit} from '../../../target/fabber-model';
import {MachineService} from '../services/machine.service';
import {Router} from '@angular/router';
import {ParameterSchemaService} from '../services/parameter-schema.service';
import {AlertService} from '../alert/alert.service';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-machines',
    templateUrl: './machines.component.html',
    styleUrls: ['./machines.component.scss']
})
export class MachinesComponent implements OnInit {
    public loading: boolean;
    public machines: Machine[];
    public bits: Bit[];
    newBitName: string;
    newMachineName: string;

    constructor(private machineService: MachineService,
                private router: Router,
                private parameterSchemaService: ParameterSchemaService,
                private alertService: AlertService,
                private title: Title) {
        this.machines = new Array<Machine>();
        this.bits = new Array<Bit>();
        this.newBitName = 'My new bit';
        this.newMachineName = 'My new machine';
    }

    ngOnInit() {
        this.reload();
        this.title.setTitle('Workshop');
    }

    reload() {
        this.loading = true;
        this.machineService.all().subscribe(machines => {
            this.machines = machines;
            this.machineService.allBits().subscribe(bits => {
                this.bits = bits;
                this.loading = false;
            });
        });
    }

    myMachines(): Machine[] {
        return this.machines.filter((machine) => machine.owner != null);
    }

    publicMachines(): Machine[] {
        return this.machines.filter((machine) => machine.owner == null);
    }

    myBits(): Bit[] {
        return this.bits.filter((bit) => bit.owner != null);
    }

    publicBits(): Bit[] {
        return this.bits.filter((bit) => bit.owner == null);
    }


    copy(machine: Machine) {
        this.machineService.copy(machine).subscribe({
            next: (newMachine) => {
                this.router.navigate(['/machines', newMachine.id]);
            },
            error: (err) => this.alertService.error(err)
        });
    }

    remove(machine: Machine) {
        this.machineService.remove(machine.id).subscribe({
            error: (err) => {
                this.alertService.error(err);
            },
            complete: () => {
                this.reload();
            }
        });
    }

    add(name: string) {
        const myMachine = new Machine();
        myMachine.name = name;
        this.machineService.add(myMachine).subscribe({
            next: (newMachine) => {
                this.router.navigate(['/machines', newMachine.id]);
            },
            error: (err) => this.alertService.error(err)
        });
    }

    copyBit(bit: Bit) {
        this.machineService.copyBit(bit).subscribe({
            next: (newBit) => {
                this.router.navigate(['/bits', newBit.id]);
            },
            error: (err) => this.alertService.error(err)
        });
    }

    removeBit(bit: Bit) {
        this.machineService.removeBit(bit.id).subscribe({
            error: (err) => {
                this.alertService.error(err);
            },
            complete: () => {
                this.reload();
            }
        });
    }

    addBit(name: string) {
        const myBit = new Bit();
        myBit.name = name;
        this.machineService.addBit(myBit).subscribe({
            next: (newBit) => {
                this.router.navigate(['/bits', newBit.id]);
            },
            error: (err) => this.alertService.error(err)
        });
    }

    materialNames(bit: Bit): string {
        return bit.materialSettings.map((ms) => ms.material).join(', ');
    }
}
