<div class="container mt-2">
    <div class="row">
        <div class="col-md-12">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Code</th>
                        <th scope="col">Email</th>
                        <th scope="col">Accepted?</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let invitation of invitations">
                        <th scope="row">{{invitation.code}}</th>
                        <td>{{invitation.email}}</td>
                        <td>{{invitation.invitee==null?"No":"Yes"}}</td>
                        <td>
                            <button class="btn btn-primary btn-sm" (click)="revoke(invitation.id)" [disabled]="invitation.invitee!=null">Revoke</button>
                            <button class="btn btn-primary btn-sm ml-1" (click)="resend(invitation.id, invitation.email)" [disabled]="invitation.invitee!=null">Resend</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <p class="lead">Paste email addresses below to add invitations:</p>
        </div>
        <div class="col-md-9 input-group">
            <div class="input-group-prepend">
                <span class="input-group-text">Email addresses:</span>
            </div>
            <textarea class="form-control" [(ngModel)]="emails"></textarea>
        </div>
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg float-right" (click)="invite()" [disabled]="emails==null || emails.trim().length==0">Invite new users</button>
        </div>
    </div>
</div>
