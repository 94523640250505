import { Injectable } from '@angular/core';
import { ParameterSpec } from '../../../target/fabber-model';
import { map } from 'rxjs/internal/operators/map';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParameterSchemaService {
  private schema: Map<string, Map<string, ParameterSpec>>;

  constructor(private http: HttpClient) {
  }

  loadSchema(): Promise<boolean> {
    return this.http.get('api/schema').pipe(
            map(schema => {
              this.schema = schema as Map<string, Map<string, ParameterSpec>>;
              console.log('Loaded schema');
              return true;
            })
        )
        .toPromise();
  }

  getSchema(type: string): Map<string, ParameterSpec> {
    return this.schema[type];
  }

  getParameterSpec(type: string, field: string): ParameterSpec {
    const typeSchema = this.getSchema(type);
    if (typeSchema) {
      const spec = typeSchema[field];
      if (spec) {
        return spec;
      }
    }
    const spec = new ParameterSpec();
    spec.name = field;
    return spec;
  }

}
