import {Injectable} from '@angular/core';
import {User} from '../../../target/fabber-model';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';


@Injectable()
export class UserResolver implements Resolve<User> {

    constructor(private userService: UserService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> | Promise<User> | User {
        console.log('calling user resolver');
        return this.userService.me(false);
    }
}
