<div class="container-fluid mt-2" *ngIf="(post$ | async) as post">
    <div class="row" style="height: 500px;">
        <as-split direction="horizontal">
            <as-split-area [size]="50">
                <div style="height: 500px;">
                    <ngx-codemirror [(ngModel)]="post.template"
                                    [options]="{lineNumbers: true, readOnly: !editable(post), mode: 'StringTemplate'}"></ngx-codemirror>
                </div>
            </as-split-area>
            <as-split-area [size]="50">
                <div style="height: 500px;">
                    <ngx-codemirror [(ngModel)]="output"
                                    [options]="{lineNumbers: false, readOnly: true}"></ngx-codemirror>
                </div>
            </as-split-area>
        </as-split>
    </div>
</div>
