import {Injectable} from '@angular/core';
import {ProjectSummary, Part, Slice, CuttingList, Sheets, Toolpath, PostProcessor, Sheet} from '../../../target/fabber-model';
import {Restangular} from 'ngx-restangular';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    constructor(private router: Router, private restangular: Restangular) {
    }

    all(): Observable<ProjectSummary[]> {
        return this.restangular.all('projects').getList();
    }

    get(id: string): Observable<ProjectSummary> {
        return this.restangular.one('projects', id).get();
    }

    getParts(id: string): Observable<Part[]> {
        return this.restangular.one('projects', id).all('parts').getList();
    }

    getPart(id: string, partid: string): Observable<Part> {
        return this.restangular.one('projects', id).one('parts', partid).get();
    }

    getSlices(id: string, partid: string): Observable<Slice[]> {
        return this.restangular.one('projects', id).one('parts', partid).all('slices').getList();
    }

    getCuttingList(projectId: string): Observable<CuttingList> {
        return this.restangular.one('projects', projectId).customGET('cuttinglist');
    }

    saveCuttingList(projectId: string, cl: CuttingList) {
        return this.restangular.one('projects', projectId).customPUT(cl, 'cuttinglist');
    }

    remove(id: string): any {
        return this.restangular.one('projects', id).remove();
    }

    create(name: string): Observable<ProjectSummary> {
        const newProject = new ProjectSummary();
        newProject.name = name;
        return this.restangular.all('projects').post(newProject);
    }

    createAndUpload(name: string): void {
        this.create(name).subscribe(project => this.navigateToUpload(project));
    }

    navigateToProject(project: ProjectSummary): void {
        this.router.navigate(['/project', project.id]);
    }

    navigateToUpload(project: ProjectSummary): void {
        this.router.navigate(['/project', project.id]);
    }

    getSheets(id: string): Observable<Sheets> {
        return this.restangular.one('projects', id).customGET('sheets');
    }

    updateSheets(id: string, sheets: Sheets): Observable<Sheets> {
        return this.restangular.one('projects', id).customPUT('sheets', sheets);
    }

    updateSheet(id: string, sheetId: string, sheet: Sheet): Observable<Sheets> {
        return this.restangular.one('projects', id).one('sheets', sheetId).customPUT(sheet);
    }

    getToolpath(projectId: string, sheetId: string): Observable<Toolpath> {
        return this.restangular.one('projects', projectId).one('sheets', sheetId).customGET('toolpath');
    }

    getSheetSlices(projectId: string, sheetId: string): Observable<Map<string, Slice>> {
        return this.restangular.one('projects', projectId).one('sheets', sheetId).customGET('slices');
    }


}
