import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ParameterSchemaService} from '../services/parameter-schema.service';
import {Machine, MaterialSheet, SheetSize} from '../../../target/fabber-model';
import {MachineService} from '../services/machine.service';
import {UnitsService} from '../services/units/units.service';

@Component({
    selector: 'app-material-size-picker',
    templateUrl: './material-size-picker.component.html',
    styleUrls: ['./material-size-picker.component.scss']
})
export class MaterialSizePickerComponent implements OnInit, OnChanges {
    @Input() minimumSheetSize: SheetSize;
    @Input() machine: Machine;
    @Input() material: string;
    @Input() size: MaterialSheet;
    @Input() disabled: boolean;
    @Output() sizeChange = new EventEmitter<MaterialSheet>();

    unit: string;
    units: string[];
    availableSizes: MaterialSheet[];

    lengthValue: number;
    widthValue: number;
    minLengthValue: number;
    minWidthValue: number;
    maxLengthValue: number;
    maxWidthValue: number;
    private sizeValue: MaterialSheet;

    constructor(private unitsService: UnitsService, private machineService: MachineService) {
        this.availableSizes = new Array();
    }

    ngOnInit() {
        this.units = new Array<string>();
        this.unitsService.unitsForUnit('m').forEach((u) => this.units.push(u));
        this.unit = this.unitsService.selectedForUnit('m');
    }

    setUnit(u: any) {
        const previousUnit = this.unit;
        if (u !== previousUnit) {
            this.unit = u;
            if (this.lengthValue) {
                this.lengthValue = this.unitsService.convert(this.lengthValue, previousUnit, u);
            }
            if (this.widthValue) {
                this.widthValue = this.unitsService.convert(this.widthValue, previousUnit, u);
            }
            if (this.maxLengthValue) {
                this.maxLengthValue = this.unitsService.convert(this.maxLengthValue, previousUnit, u);
            }
            if (this.maxWidthValue) {
                this.maxWidthValue = this.unitsService.convert(this.maxWidthValue, previousUnit, u);
            }
            if (this.minLengthValue) {
                this.minLengthValue = this.unitsService.convert(this.minLengthValue, previousUnit, u);
            }
            if (this.minWidthValue) {
                this.minWidthValue = this.unitsService.convert(this.minWidthValue, previousUnit, u);
            }
        }
    }

    setSize(materialSheet: MaterialSheet) {
        console.log('setting sheet size and material');
        this.sizeValue = materialSheet;
        if (this.sizeValue && this.sizeValue.sheetSize) {
            this.lengthValue = this.unitsService.convertQuantity(this.sizeValue.sheetSize.length, this.unit);
            this.widthValue = this.unitsService.convertQuantity(this.sizeValue.sheetSize.width, this.unit);
        } else {
            this.lengthValue = null;
            this.widthValue = null;
        }
        this.sizeChange.emit(this.sizeValue);
    }

    setLength(length: number) {
        if (!this.sizeValue) {
            this.sizeValue = new MaterialSheet();
            this.sizeValue.sheetSize = new SheetSize();
        }
        if (length) {
            this.sizeValue.sheetSize.length = '' + length + ' ' + this.unit;
        } else {
            this.sizeValue.sheetSize.length = null;
        }
        this.sizeChange.emit(this.sizeValue);
    }

    setWidth(width: number) {
        if (!this.sizeValue) {
            this.sizeValue = new MaterialSheet();
            this.sizeValue.sheetSize = new SheetSize();
        }
        if (width) {
            this.sizeValue.sheetSize.width = '' + width + ' ' + this.unit;
        } else {
            this.sizeValue.sheetSize.width = null;
        }
        this.sizeChange.emit(this.sizeValue);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
        if (changes['size']) {
            if (this.size && this.size.sheetSize && this.size.sheetSize.length) {
                this.lengthValue = this.unitsService.convertQuantity(this.size.sheetSize.length, this.unit);
            }
            if (this.size && this.size.sheetSize && this.size.sheetSize.width) {
                this.widthValue = this.unitsService.convertQuantity(this.size.sheetSize.width, this.unit);
            }
        }
        if (changes['material']) {
            if (this.material) {
                this.machineService.materialSizes(this.material).subscribe((mats) => this.availableSizes = mats);
            } else {
                this.machineService.allMaterialSizes().subscribe((mats) => this.availableSizes = mats);
            }
        }
        if (changes['machine']) {
            if (this.machine) {
                const dims = [this.unitsService.convertQuantity(this.machine.xLength, this.unit),
                    this.unitsService.convertQuantity(this.machine.yLength, this.unit)];
                this.maxLengthValue = Math.max(dims[0], dims[1]);
                this.maxWidthValue = Math.min(dims[0], dims[1]);
                if (this.lengthValue) {
                    this.lengthValue = Math.min(this.lengthValue, this.maxLengthValue);
                }
                if (this.widthValue) {
                    this.widthValue = Math.min(this.widthValue, this.maxWidthValue);
                }
            } else {
                this.maxLengthValue = null;
                this.maxWidthValue = null;
            }
        }
        if (changes['minimumSheetSize']) {
            if (this.minimumSheetSize) {
                const dims = [this.unitsService.convertQuantity(this.minimumSheetSize.length, this.unit),
                    this.unitsService.convertQuantity(this.minimumSheetSize.width, this.unit)];
                this.minLengthValue = Math.max(dims[0], dims[1]);
                this.minWidthValue = Math.min(dims[0], dims[1]);
                if (this.lengthValue) {
                    this.lengthValue = Math.max(this.lengthValue, this.minLengthValue);
                }
                if (this.widthValue) {
                    this.widthValue = Math.max(this.widthValue, this.minWidthValue);
                }
            } else {
                this.minLengthValue = null;
                this.minWidthValue = null;
            }
        }
    }
}
