/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "./alert.component";
import * as i6 from "./alert.service";
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ngb-alert", [["class", "alert"], ["role", "alert"]], [[8, "title", 0], [2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.close(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(2, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { type: [0, "type"] }, { close: "close" }), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getAlertType(_v.context.$implicit); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getAlertTitle(_v.context.$implicit); var currVal_1 = i1.ɵnov(_v, 2).dismissible; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.message; _ck(_v, 3, 0, currVal_3); }); }
export function View_AlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "alert-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i5.AlertComponent, [i6.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i1.ɵccf("app-alert", i5.AlertComponent, View_AlertComponent_Host_0, {}, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
