import { ParameterSpec } from '../../../target/fabber-model';
import { map } from 'rxjs/internal/operators/map';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ParameterSchemaService {
    constructor(http) {
        this.http = http;
    }
    loadSchema() {
        return this.http.get('api/schema').pipe(map(schema => {
            this.schema = schema;
            console.log('Loaded schema');
            return true;
        }))
            .toPromise();
    }
    getSchema(type) {
        return this.schema[type];
    }
    getParameterSpec(type, field) {
        const typeSchema = this.getSchema(type);
        if (typeSchema) {
            const spec = typeSchema[field];
            if (spec) {
                return spec;
            }
        }
        const spec = new ParameterSpec();
        spec.name = field;
        return spec;
    }
}
ParameterSchemaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParameterSchemaService_Factory() { return new ParameterSchemaService(i0.ɵɵinject(i1.HttpClient)); }, token: ParameterSchemaService, providedIn: "root" });
