<div class="container mt-2" *ngIf="(user$ | async) as user">
    <div class="row">
      <div class="col-md-12" style="">
        <p>Name: {{user.name}}</p>
        <p>Email: {{user.email}}</p>
      </div>
<!--      <div class="col-md-6" style="">-->
<!--        <div style="margin-top: 20px;">-->
<!--            <button class="btn btn-primary w-100 btn-lg" *ngIf="!user.providers['onshape']" (click)="providerLink('onshape')" [disabled]="inProgress">Link with my Onshape account</button>-->
<!--            <button class="btn btn-primary w-100 btn-lg" *ngIf="user.providers['onshape']" (click)="providerUnlink('onshape')" [disabled]="inProgress || user.signup_method=='onshape'">Unlink my Onshape account</button>-->
<!--        </div>-->
<!--        <div style="margin-top: 20px;">-->
<!--            <button class="btn btn-primary w-100 btn-lg" *ngIf="!user.providers['autodesk']" (click)="providerLink('autodesk')" [disabled]="inProgress">Link with my Autodesk account</button>-->
<!--            <button class="btn btn-primary w-100 btn-lg" *ngIf="user.providers['autodesk']" (click)="providerUnlink('autodesk')" [disabled]="inProgress || user.signup_method=='autodesk'">Unlink my Autodesk account</button>-->
<!--        </div>-->
<!--        <div style="margin-top: 20px;">-->
<!--            <button class="btn btn-primary w-100 btn-lg" *ngIf="!user.providers['google']" (click)="providerLink('google')" [disabled]="inProgress">Link with my Google account</button>-->
<!--            <button class="btn btn-primary w-100 btn-lg" *ngIf="user.providers['google']" (click)="providerUnlink('google')" [disabled]="inProgress || user.signup_method=='google'">Unlink my Google account</button>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="row">
        <div class="col-md-6">
            <h4>Preferred Units</h4>
            <cae-unit-set-editor [unitSet]="user.units" (unitSetChange)="saveUnits($event)"></cae-unit-set-editor>
        </div>
        <div class="col-md-6">
            <h4>Linked Applications</h4>
            <ul class="list-group"*ngIf="(applications$ | async) as applications">
                <li class="list-group-item list-group-item-action flex-column align-items-start" *ngFor="let app of applications">
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ app.name | titlecase }}</h5>
                    </div>
                    <p><span *ngFor="let prop of app.properties | keyvalue">{{ prop.key }} = {{ prop.value }}<br></span></p>
                    <div class="btn-group float-right" role="group">
                        <button type="button" class="btn btn-danger btn-sm" [swal]="deleteSwal"><fa-icon [icon]="['fas', 'trash-alt']"></fa-icon></button>
                        <swal
                            #deleteSwal
                            title="Unlink {{ app.name | titlecase }}?"
                            text="This cannot be undone"
                            type="question"
                            [showCancelButton]="true"
                            [focusCancel]="true"
                            (confirm)="removeApp(app)">
                        </swal>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
