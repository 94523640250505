import { Component, OnInit } from '@angular/core';
import { ProjectSummary } from '../../../target/fabber-model';
import { ProjectService } from '../services/project.service';
import { AuthService } from 'ng2-ui-auth';
import {AlertService} from '../alert/alert.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  public numbers: Array<number>;
  public projects: Array<ProjectSummary>;
  public newProjectName: string;
  public loading: boolean;
  constructor(private projectService: ProjectService, public auth: AuthService, private alertService: AlertService, private title: Title) {
      this.projects = new Array<ProjectSummary>();
      this.newProjectName = 'New Project';
      this.loading = true;
  }

  ngOnInit() {
      this.projectService.all().subscribe(projects => {
          this.projects = projects;
          this.loading = false;
          this.title.setTitle('Fabber');
      });
  }

  reload() {
      this.loading = true;
      this.projectService.all().subscribe(projects => {
          this.projects = projects;
          this.loading = false;
      });
  }

  create(name: string) {
      this.projectService.create(name).subscribe(project => {
         this.projects.push(project);
         this.projectService.navigateToUpload(project);
      });
  }

  deleteProject(project: ProjectSummary) {
      this.projectService.remove(project.id).subscribe({
          complete: () => this.projects.splice(this.projects.indexOf(project), 1)
      });
  }
}
