/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@sweetalert2/ngx-sweetalert2";
import * as i4 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../node_modules/@sweetalert2/ngx-sweetalert2/sweetalert2-ngx-sweetalert2.ngfactory";
import * as i8 from "../services/units/unit-set-editor/unit-set-editor.component.ngfactory";
import * as i9 from "../services/units/unit-set-editor/unit-set-editor.component";
import * as i10 from "../services/units/units.service";
import * as i11 from "./profile.component";
import * as i12 from "../services/user.service";
import * as i13 from "ng2-ui-auth";
import * as i14 from "../alert/alert.service";
var styles_ProfileComponent = [i0.styles];
var RenderType_ProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileComponent, data: {} });
export { RenderType_ProfileComponent as RenderType_ProfileComponent };
function View_ProfileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " = ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.key; var currVal_1 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ProfileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "li", [["class", "list-group-item list-group-item-action flex-column align-items-start"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "d-flex w-100 justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h5", [["class", "mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProfileComponent_4)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.KeyValuePipe, [i1.KeyValueDiffers]), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "btn-group float-right"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 16777216, null, null, 4, "button", [["class", "btn btn-danger btn-sm"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 212992, null, 0, i3.SwalDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver], { swal: [0, "swal"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(13, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(14, 2), (_l()(), i1.ɵeld(15, 0, null, null, 2, "swal", [["text", "This cannot be undone"], ["type", "question"]], null, [[null, "confirm"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("confirm" === en)) {
        var pd_0 = (_co.removeApp(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_SwalComponent_0, i7.RenderType_SwalComponent)), i1.ɵdid(16, 770048, [["deleteSwal", 4]], 0, i3.SwalComponent, [i3.SweetAlert2LoaderService, i3.ɵc], { title: [0, "title"], text: [1, "text"], showCancelButton: [2, "showCancelButton"], focusCancel: [3, "focusCancel"] }, { confirm: "confirm" }), i1.ɵppd(17, 1)], function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_v.context.$implicit.properties)); _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 16); _ck(_v, 11, 0, currVal_2); var currVal_5 = _ck(_v, 14, 0, "fas", "trash-alt"); _ck(_v, 13, 0, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "Unlink ", i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.name)), "?"); var currVal_7 = "This cannot be undone"; var currVal_8 = true; var currVal_9 = true; _ck(_v, 16, 0, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.name)); _ck(_v, 3, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 13).title; var currVal_4 = i1.ɵnov(_v, 13).renderedIconHTML; _ck(_v, 12, 0, currVal_3, currVal_4); }); }
function View_ProfileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "container mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-md-12"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Name: ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Email: ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Preferred Units"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "cae-unit-set-editor", [], null, [[null, "unitSetChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("unitSetChange" === en)) {
        var pd_0 = (_co.saveUnits($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_UnitSetEditorComponent_0, i8.RenderType_UnitSetEditorComponent)), i1.ɵdid(12, 114688, null, 0, i9.UnitSetEditorComponent, [i10.UnitsService], { unitSet: [0, "unitSet"] }, { unitSetChange: "unitSetChange" }), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Linked Applications"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProfileComponent_2)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.ngIf.units; _ck(_v, 12, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.applications$)); _ck(_v, 17, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.ngIf.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.ngIf.email; _ck(_v, 6, 0, currVal_1); }); }
export function View_ProfileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProfileComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.user$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile", [], null, null, null, View_ProfileComponent_0, RenderType_ProfileComponent)), i1.ɵdid(1, 114688, null, 0, i11.ProfileComponent, [i12.UserService, i13.AuthService, i14.AlertService, i6.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileComponentNgFactory = i1.ɵccf("app-profile", i11.ProfileComponent, View_ProfileComponent_Host_0, {}, {}, []);
export { ProfileComponentNgFactory as ProfileComponentNgFactory };
