/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./admin-dashboard.component";
var styles_AdminDashboardComponent = [i0.styles];
var RenderType_AdminDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminDashboardComponent, data: {} });
export { RenderType_AdminDashboardComponent as RenderType_AdminDashboardComponent };
export function View_AdminDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, "div", [["class", "container mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "btn btn-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵted(-1, null, ["Manage Users"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "btn btn-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵted(-1, null, ["Manage Invitations"])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["class", "btn btn-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(15, 1), (_l()(), i1.ɵted(-1, null, ["Manage Files"])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "button", [["class", "btn btn-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(20, 1), (_l()(), i1.ɵted(-1, null, ["Manage Tasks"])), (_l()(), i1.ɵeld(22, 0, null, null, 12, "div", [["class", "row mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 4, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 3, "button", [["class", "btn btn-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(26, 1), (_l()(), i1.ɵted(-1, null, ["Manage Machines"])), (_l()(), i1.ɵeld(28, 0, null, null, 4, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 3, "button", [["class", "btn btn-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 30).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(30, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(31, 1), (_l()(), i1.ɵted(-1, null, ["Manage Projects"])), (_l()(), i1.ɵeld(33, 0, null, null, 0, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 0, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(36, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 5, 0, "users"); _ck(_v, 4, 0, currVal_0); var currVal_1 = _ck(_v, 10, 0, "invitations"); _ck(_v, 9, 0, currVal_1); var currVal_2 = _ck(_v, 15, 0, "files"); _ck(_v, 14, 0, currVal_2); var currVal_3 = _ck(_v, 20, 0, "tasks"); _ck(_v, 19, 0, currVal_3); var currVal_4 = _ck(_v, 26, 0, "machines"); _ck(_v, 25, 0, currVal_4); var currVal_5 = _ck(_v, 31, 0, "projects"); _ck(_v, 30, 0, currVal_5); _ck(_v, 36, 0); }, null); }
export function View_AdminDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-dashboard", [], null, null, null, View_AdminDashboardComponent_0, RenderType_AdminDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i3.AdminDashboardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminDashboardComponentNgFactory = i1.ɵccf("app-admin-dashboard", i3.AdminDashboardComponent, View_AdminDashboardComponent_Host_0, {}, {}, []);
export { AdminDashboardComponentNgFactory as AdminDashboardComponentNgFactory };
