/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./version.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/router";
import * as i8 from "./version.component";
import * as i9 from "../../services/update.service";
var styles_VersionComponent = [i0.styles];
var RenderType_VersionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VersionComponent, data: {} });
export { RenderType_VersionComponent as RenderType_VersionComponent };
export function View_VersionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "d-inline-block"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 1720320, null, 3, i2.NgbDropdown, [i1.ChangeDetectorRef, i2.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i2.NgbNavbar]], null, null), i1.ɵqud(603979776, 1, { _menu: 0 }), i1.ɵqud(603979776, 2, { _menuElement: 0 }), i1.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 5, "button", [["aria-haspopup", "true"], ["class", "btn btn-outline-primary nav-link dropdown-toggle"], ["id", "versionDropdown"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(9, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(10, 2), (_l()(), i1.ɵeld(11, 0, [[2, 0]], null, 11, "div", [["aria-labelledby", "versionDropdown"], ["class", "dropdown-menu-right"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, [[1, 4]], 1, i2.NgbDropdownMenu, [i2.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "dropdown-item"], ["ngbDropdownItem", ""]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkForUpdates() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, [[4, 4]], 0, i2.NgbDropdownItem, [i1.ElementRef], null, null), (_l()(), i1.ɵted(16, null, ["Version: ", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 0, "div", [["class", "dropdown-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "button", [["class", "dropdown-item"], ["ngbDropdownItem", ""]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(20, 1), i1.ɵdid(21, 16384, [[4, 4]], 0, i2.NgbDropdownItem, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["Terms of Service"]))], function (_ck, _v) { var currVal_4 = _ck(_v, 10, 0, "fas", "info"); _ck(_v, 9, 0, currVal_4); var currVal_11 = _ck(_v, 20, 0, "tos"); _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 9).title; var currVal_3 = i1.ɵnov(_v, 9).renderedIconHTML; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 12).dropdown.isOpen(); var currVal_7 = i1.ɵnov(_v, 12).placement; _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = i1.ɵnov(_v, 15).disabled; _ck(_v, 14, 0, currVal_8); var currVal_9 = _co.version; _ck(_v, 16, 0, currVal_9); var currVal_10 = i1.ɵnov(_v, 21).disabled; _ck(_v, 18, 0, currVal_10); }); }
export function View_VersionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-version", [], null, null, null, View_VersionComponent_0, RenderType_VersionComponent)), i1.ɵdid(1, 114688, null, 0, i8.VersionComponent, [i9.UpdateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VersionComponentNgFactory = i1.ɵccf("app-version", i8.VersionComponent, View_VersionComponent_Host_0, { version: "version" }, {}, []);
export { VersionComponentNgFactory as VersionComponentNgFactory };
