<nav class="navbar navbar-expand-md navbar-light bg-white navbar-ontop">
    <div class="container justify-content-center">
        <button class="navbar-toggler navbar-toggler-right border-0" type="button" (click)="isCollapsed = !isCollapsed"
                [attr.aria-expanded]="!isCollapsed" aria-controls="navbars" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse text-center justify-content-center" id="navbar"
             [ngbCollapse]="isCollapsed">
            <ul class="navbar-nav">
                <li class="nav-item mx-2">
                    <a class="nav-link" href="https://forum.getfabber.com/" target="fabberforum">
                        <fa-icon [icon]="['fas', 'comments']"></fa-icon>
                        Forum</a>
                </li>
            </ul>
        </div>
        <div class="float-right">

            <!--<img [src]="'/api/users/me.png?token=' + auth.getToken()" alt="User name" height="50" width="50">-->
            <!--<span>User name</span>-->
        </div>
    </div>

    <!--    <small class="nav-link text-muted">{{version}}</small>-->
</nav>
<as-split [direction]="'horizontal'">
    <as-split-area>

    </as-split-area>
    <as-split-area>
        <router-outlet></router-outlet>
    </as-split-area>
    <as-split-area>

    </as-split-area>
</as-split>
