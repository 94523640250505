<div class="container">
    <div class="row">
        <div class="col-md-12"><img class="img-fluid d-block mx-auto" src="assets/fabber-1line-2color-bold-lowres.png" alt="Get Fabber!"></div>
    </div>
    <div class="row mt-2">
        <div class="px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center" style="">
            <h1>Login</h1>
            <p class="mb-3 lead" style="">Login here to access your Fabber projects</p>
            <button class="btn btn-primary w-100 btn-lg" (click)="providerLogin('memberful')" [disabled]="inProgress">Login</button>
            <br><p class="mb-3 lead">Don't have an account? <a href="https://getfabber.com/" target="getfabber">Sign up</a>.</p>
    </div>
        <div class="col-md-6" style=""></div>
    </div>
</div>
