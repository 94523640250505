import * as Qty from 'js-quantities';
import { UnitSet } from './unit-set';
import * as i0 from "@angular/core";
import * as i1 from "./unit-set";
export class UnitsService {
    constructor(baseUnits) {
        this.baseUnits = baseUnits;
    }
    setWorkingUnits(u) {
        this.workingUnits = u;
    }
    quantities() {
        if (this.baseUnits) {
            const qs = new Array();
            this.baseUnits.units.forEach((u) => {
                const q = Qty(u.unit).kind();
                if (qs.indexOf(q) === -1) {
                    qs.push(q);
                }
            });
            return qs;
        }
        return Qty.getKinds();
    }
    selectedForQuantity(quantity, set) {
        if (set) {
            const us = new Array();
            set.units.forEach((u) => {
                const q = Qty(u.unit).kind();
                if (quantity === q) {
                    us.push(u.unit);
                }
            });
            return us[0];
        }
        const units = this.unitsForQuantity(quantity);
        if (units.length === 0) {
            return '';
        }
        return units[0];
    }
    selectedForUnit(unit, set = this.workingUnits) {
        return this.selectedForQuantity(Qty(unit).kind(), set);
    }
    unitsForQuantity(quantity) {
        if (this.baseUnits) {
            const us = new Array();
            this.baseUnits.units.forEach((u) => {
                const q = Qty(u.unit).kind();
                if (quantity === q) {
                    us.push(u.unit);
                }
            });
            return us;
        }
        return Qty.getUnits(quantity);
    }
    unitsForUnit(unit) {
        return this.unitsForQuantity(Qty(unit).kind());
    }
    decimalForUnit(unit, set) {
        let precision = set.units.filter((u) => u.unit === unit).map((u) => u.precision);
        if (precision.length === 0) {
            precision = this.baseUnits.units.filter((u) => u.unit === unit).map((u) => u.precision);
            if (precision.length === 0) {
                return 3;
            }
        }
        return precision[0];
    }
    baseValue(value, unit) {
        return Qty(value, unit).toBase().scalar;
    }
    unitValue(baseValue, unit) {
        return Qty(baseValue, Qty(unit).toBase().units()).to(unit).scalar;
    }
    convertQuantity(unitValue, toUnit) {
        if (unitValue && toUnit) {
            const result = Qty(unitValue).to(toUnit).scalar;
            // Temp fix: js-quantities should be fixed so this doesn't happen, should use mulSafe and divSafe for all maths
            if (Number.isNaN(result)) {
                return 0;
            }
            return result;
        }
        return null;
    }
    convert(value, fromUnit, toUnit) {
        if (value && fromUnit && toUnit && fromUnit.length > 0 && toUnit.length > 0 && fromUnit !== toUnit) {
            const result = Qty(value, fromUnit).to(toUnit).scalar;
            // Temp fix: js-quantities should be fixed so this doesn't happen, should use mulSafe and divSafe for all maths
            if (Number.isNaN(result)) {
                return 0;
            }
            return result;
        }
        return value;
    }
    format(unitValue) {
        if (unitValue) {
            const qty = Qty(unitValue);
            if (this.workingUnits) {
                for (let i = 0; i < this.workingUnits.units.length; i++) {
                    const value = this.workingUnits.units[i];
                    const q = Qty(value.unit);
                    if (qty.kind() === q.kind()) {
                        return qty.to(q).scalar.toFixed(value.precision) + ' ' + value.unit;
                    }
                }
            }
            if (this.baseUnits) {
                for (let i = 0; i < this.baseUnits.units.length; i++) {
                    const value = this.baseUnits.units[i];
                    const q = Qty(value.unit);
                    if (qty.kind() === q.kind()) {
                        return qty.to(q).scalar.toFixed(value.precision) + ' ' + value.unit;
                    }
                }
            }
        }
        return unitValue;
    }
}
UnitsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UnitsService_Factory() { return new UnitsService(i0.ɵɵinject(i1.BASE_UNITSET)); }, token: UnitsService, providedIn: "root" });
