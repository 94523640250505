import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { User } from '../../../../target/fabber-model';
import * as moment from 'moment';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {
  public users$: Observable<Array<User>>;
  constructor(private restangular: Restangular) {
  }

  ngOnInit() {
      this.users$ = this.restangular.all('users').getList();
  }
  
  formatDate(date: any) {
      return moment(date).fromNow();
  }
}
