import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isArray} from 'util';

@Component({
    selector: 'app-strategy-picker',
    templateUrl: './strategy-picker.component.html',
    styleUrls: ['./strategy-picker.component.scss']
})
export class StrategyPickerComponent implements OnInit {
    @Input() options: { name: string, value: string, beta: boolean }[];
    @Input() field: string;

    @Input()
    get object() {
        return this.objectValue;
    }

    set object(o: any) {
        this.objectValue = o;
        this.getValue();
        this.objectChange.emit(this.objectValue);
    }

    @Output() objectChange = new EventEmitter();

    private objectValue: any;
    displayValue: string;

    constructor() {
    }

    ngOnInit() {
        this.getValue();
    }

    getValue() {
        if (this.objectValue) {
            this.displayValue = this.objectValue[this.field] as string;
        }
    }

    setValue(value: string) {
        if (this.objectValue) {
            this.objectValue[this.field] = value;
            this.objectChange.emit(this.objectValue);
        }
    }
}
