import { Router } from '@angular/router';
import { of } from 'rxjs';
import { SketchupService } from '../services/sketchup.service';
import { Fusion360Service } from '../services/fusion360.service';
import { AuthService } from 'ng2-ui-auth';
import { catchError, map } from 'rxjs/operators';
import { Restangular } from 'ngx-restangular';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "ng2-ui-auth";
import * as i3 from "ngx-restangular";
import * as i4 from "../services/sketchup.service";
import * as i5 from "../services/fusion360.service";
export class AppGuard {
    constructor(router, auth, restangular, sketchupService, fusion360Service) {
        this.router = router;
        this.auth = auth;
        this.restangular = restangular;
        this.sketchupService = sketchupService;
        this.fusion360Service = fusion360Service;
    }
    canActivate(next, state) {
        if (this.sketchupService.inSketchup() || this.fusion360Service.inFusion360()) {
            if (!this.auth.isAuthenticated()) {
                // Post info on Sketchup to server
                return this.restangular.one('auth').post('skp', { name: 'SketchUp', uuid: next.queryParams.skp })
                    .pipe(map(token => {
                    this.auth.setToken(token['token']);
                    return true;
                }), catchError(err => {
                    // If unknown then launch signup window and return false
                    this.sketchupService.launchSignup();
                    return of(false);
                }));
            }
            return true;
        }
        return this.router.createUrlTree(['/']);
    }
}
AppGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppGuard_Factory() { return new AppGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.Restangular), i0.ɵɵinject(i4.SketchupService), i0.ɵɵinject(i5.Fusion360Service)); }, token: AppGuard, providedIn: "root" });
