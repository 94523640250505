import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {MachineService} from '../../services/machine.service';
import { Machine, Bit } from '../../../../target/fabber-model';

@Component({
  selector: 'app-admin-machines',
  templateUrl: './admin-machines.component.html',
  styleUrls: ['./admin-machines.component.scss']
})
export class AdminMachinesComponent implements OnInit {
  public machines$: Observable<Array<Machine>>;
  constructor(private machineService: MachineService) { }

  ngOnInit() {
    this.machines$ = this.machineService.all();
  }

}
