import * as CodeMirror from 'codemirror';
import { ANTLRInputStream, CommonTokenStream, Token } from 'antlr4ts';
import { STGLexer } from './parser/STGLexer';
import * as i0 from "@angular/core";
export class StringTemplateService {
    constructor() {
        CodeMirror.defineMode('StringTemplate', (config, modeOptions) => {
            return {
                startState: () => new TokenState(),
                token: (ss, state) => state.token(ss),
                copyState: (state) => new TokenState()
            };
        });
    }
}
StringTemplateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StringTemplateService_Factory() { return new StringTemplateService(); }, token: StringTemplateService, providedIn: "root" });
class TokenState {
    constructor() {
        this.styleMap = {
            'DOC_COMMENT': 'comment',
            'BLOCK_COMMENT': 'comment',
            'LINE_COMMENT': 'comment',
            'TMPL_COMMENT': 'comment',
            'HORZ_WS': null,
            'VERT_WS': null,
            'ID': 'variable',
            'STRING': 'string',
            'BIGSTRING': 'string',
            'BIGSTRING_NO_NL': 'string',
            'ANON_TEMPLATE': '',
            'TMPL_ASSIGN': 'operator',
            'ASSIGN': 'operator',
            'DOT': 'punctuation',
            'COMMA': 'punctuation',
            'COLON': 'punctuation',
            'LPAREN': 'bracket',
            'RPAREN': 'bracket',
            'LBRACK': 'bracket',
            'RBRACK': 'bracket',
            'AT': '',
            'TRUE': 'atom',
            'FALSE': 'atom',
            'ELLIPSIS': 'punctuation',
            'DELIMITERS': 'punctuation',
            'IMPORT': '',
            'DEFAULT': '',
            'KEY': '',
            'VALUE': '',
            'FIRST': '',
            'LAST': '',
            'REST': '',
            'TRUNC': '',
            'STRIP': 'keyword',
            'TRIM': 'keyword',
            'LENGTH': 'keyword',
            'STRLEN': 'keyword',
            'REVERSE': 'keyword',
            'GROUP': 'keyword',
            'WRAP': 'keyword',
            'ANCHOR': 'operator',
            'SEPARATOR': 'operator'
        };
    }
    token(ss) {
        if (this.lineTokens == null || this.lineIndex >= this.lineTokens.length) {
            this.lineTokens = this.lexLine(ss);
            this.lineIndex = 0;
        }
        const token = this.lineTokens[this.lineIndex];
        while (ss.pos < token.stopIndex + 1) {
            ss.next();
        }
        this.lineIndex++;
        if (token.channel !== 0 || token.type <= 0) {
            return null;
        }
        const name = STGLexer.VOCABULARY.getSymbolicName(token.type);
        if (name && this.styleMap[name]) {
            return this.styleMap[name];
        }
        return null;
    }
    lexLine(ss) {
        let line = '';
        while (!ss.eol() && ss.peek() != null) {
            line += ss.next();
        }
        ss.backUp(line.length);
        const inputStream = new ANTLRInputStream(line);
        const lexer = new STGLexer(inputStream);
        const tokenStream = new CommonTokenStream(lexer);
        tokenStream.fill();
        return tokenStream.getTokens();
    }
}
