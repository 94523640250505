<nav class="navbar navbar-expand-md navbar-dark bg-primary navbar-ontop">
    <div class="container justify-content-center">
        <button class="navbar-toggler navbar-toggler-right border-0" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbars" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse text-center justify-content-center" id="navbar" [ngbCollapse]="isCollapsed">
            <ul class="navbar-nav">
                <li class="nav-item mx-2">
                    <a id="fabber-home-logo" class="nav-link navbar-brand mr-0" routerLink="/" *ngIf="!inApp"><img class="fabber-logo" src="assets/fabber-1line-1color-white-lowres.png" alt="FABBER"/></a>
                </li>
                <li class="nav-item mx-2">
                    <span class="navbar-brand mr-0e" *ngIf="inApp"><img class="fabber-logo" src="assets/fabber-1line-1color-white-lowres.png" alt="FABBER"/></span>
                </li>
                <li class="nav-item mx-2">
                    <a class="nav-link" href="https://forum.getfabber.com/" target="fabberforum"><fa-icon [icon]="['fas', 'comments']"></fa-icon> Forum</a>
                </li>
                <li class="nav-item mx-2" *ngIf="auth.isAuthenticated()">
                    <a class="nav-link" (click)="startTour()" target="fabberforum"><fa-icon [icon]="['fas', 'play-circle']"></fa-icon> Tour</a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item mx-2" *ngIf="auth.isAuthenticated()">
                    <a class="nav-link" routerLink="/machines"><fa-icon [icon]="['fas', 'industry-alt']"></fa-icon> Workshop</a>
                </li>
                <li class="nav-item mx-2" *ngIf="auth.isAuthenticated()">
                    <a class="nav-link" routerLink="/profile"><fa-icon [icon]="['fas', 'user']"></fa-icon> {{name$ | async}}<!-- <img [src]="'/api/users/me.png?token=' + auth.getToken()" [alt]="name() | async" class="rounded-circle z-depth-0" alt="avatar image" height="35">--></a>
                </li>
                <li class="nav-item mx-2" *ngIf="!auth.isAuthenticated() && !inApp">
                    <a class="nav-link" routerLink="/login"><fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon> Login</a>
                </li>
                <li class="nav-item mx-2" *ngIf="auth.isAuthenticated() && !inApp">
                    <a class="nav-link" [routerLink]="" (click)="logout()"><fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon> Logout</a>
                </li>
                <li class="nav-item mx-2" *ngIf="inApp">
                    <a class="nav-link" [routerLink]="" (click)="popout()"><fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon> Open in Browser</a>
                </li>
                <li class="nav-item mx-2">
                    <app-version [version]="version"></app-version>
                </li>
            </ul>
        </div>
        <div class="float-right">

            <!--<img [src]="'/api/users/me.png?token=' + auth.getToken()" alt="User name" height="50" width="50">-->
            <!--<span>User name</span>-->
        </div>
    </div>

<!--    <small class="nav-link text-muted">{{version}}</small>-->
</nav>
