import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {MachineService} from '../services/machine.service';
import {Observable} from 'rxjs';
import {Machine, Bit, BitMaterial, MaterialChoice, MaterialSheet, SheetSize} from '../../../target/fabber-model';
import {filter} from 'rxjs/operators';
import {UnitsService} from '../services/units/units.service';

@Component({
    selector: 'app-machine-picker',
    templateUrl: './machine-picker.component.html',
    styleUrls: ['./machine-picker.component.scss']
})
export class MachinePickerComponent implements OnInit, OnChanges {
    candidateMachines: Machine[];
    candidateBits: Bit[];
    candidateMaterials: MaterialChoice[];

    machine: Machine;
    private materialChoice: MaterialChoice;
    bit: Bit;
    materialSheet: MaterialSheet;

    @Input() minimumSheetSize: SheetSize;

    @Input() disabled = false;

    @Input()
    get machineId() {
        return this.machine ? this.machine.id : null;
    }

    set machineId(id: string) {
        if (id) {
            this.machineService.get(id).subscribe((m) => {
                this.machine = m;
                this.machineIdChange.emit(id);
                if (m.xLength && m.yLength) {
                    const ss = new SheetSize();
                    ss.length = m.xLength;
                    ss.width = m.yLength;
                    this.sheetSize = ss;
                }
            });
        } else {
            this.machine = null;
            this.machineIdChange.emit(id);
        }
    }

    @Input()
    get material() {
        return this.materialChoice ? this.materialChoice.material : null;
    }

    set material(id: string) {
        if (id) {
            this.machineService.allMaterials().subscribe((mats) => {
                mats.forEach((mat) => {
                    if (id === mat.material) {
                        this.materialChoice = mat;
                        this.materialChange.emit(id);
                    }
                });
            });
        } else {
            this.materialChoice = null;
            this.materialChange.emit(id);
        }
    }

    @Input()
    get bitId() {
        return this.bit ? this.bit.id : null;
    }

    set bitId(id: string) {
        if (id) {
            this.machineService.getBit(id).subscribe((b) => {
                this.bit = b;
                this.bitIdChange.emit(id);
            });
        } else {
            this.bit = null;
            this.bitIdChange.emit(id);
        }
    }

    @Input()
    get sheetSize() {
        return this.materialSheet ? this.materialSheet.sheetSize : null;
    }

    set sheetSize(ss: SheetSize) {
        if (this.materialSheet) {
            this.materialSheet.sheetSize = ss;
            this.sheetSizeChange.emit(ss);
        } else {
            if (ss) {
                this.materialSheet = new MaterialSheet();
                this.materialSheet.sheetSize = ss;
            }
        }
    }

    @Output() machineIdChange = new EventEmitter<string>();
    @Output() materialChange = new EventEmitter<string>();
    @Output() bitIdChange = new EventEmitter<string>();
    @Output() sheetSizeChange = new EventEmitter<SheetSize>();

    constructor(private machineService: MachineService, private unitsService: UnitsService) {
        this.candidateMachines = new Array<Machine>();
        this.candidateBits = new Array<Bit>();
        this.candidateMaterials = new Array<MaterialChoice>();
    }

    updateMachine(machine: Machine) {
        if (machine) {
            this.machineId = machine.id;
        } else {
            this.machineId = null;
        }
        this.updateMaterialCandidates();
        this.updateBitCandidates();
    }

    updateBit(bit: Bit) {
        if (bit) {
            this.bitId = bit.id;
        } else {
            this.bitId = null;
        }
        this.updateMachineCandidates();
        this.updateMaterialCandidates();
    }

    updateBitMaterial(material: MaterialChoice) {
        if (material) {
            this.material = material.material;
        } else {
            this.material = null;
        }
        this.updateBitCandidates();
        this.updateMachineCandidates();
    }

    updateMachineCandidates() {
        this.machineService.all()
            .subscribe((machines) => this.candidateMachines = machines.filter(machine => this.sheetFitsMachine(machine)));
    }

    sheetFitsMachine(machine: Machine): boolean {
        if (this.minimumSheetSize && this.minimumSheetSize.length && this.minimumSheetSize.width
            && machine.xLength && machine.yLength) {
            const machineDims = [this.unitsService.convertQuantity(machine.xLength, 'm'),
                this.unitsService.convertQuantity(machine.yLength, 'm')];
            const sheetDims = [this.unitsService.convertQuantity(this.minimumSheetSize.width, 'm'),
                this.unitsService.convertQuantity(this.minimumSheetSize.length, 'm')];
            return Math.max(machineDims[0], machineDims[1]) >= Math.max(sheetDims[0], sheetDims[1])
                && Math.min(machineDims[0], machineDims[1]) >= Math.min(sheetDims[0], sheetDims[1]);
        }
        return true;
    }

    updateBitCandidates() {
        if (this.machine) {
            this.machineService.bits(this.machine.id).subscribe((bits) => {
                if (this.materialChoice) {
                    this.candidateBits = bits.filter((bit) => this.materialChoice.bits.map((b) => b.id).indexOf(bit.id) > -1);
                } else {
                    this.candidateBits = bits;
                }
            });
        } else {
            this.machineService.allBits().subscribe((bits) => {
                if (this.material) {
                    this.candidateBits = bits.filter((bit) => this.materialChoice.bits.map((b) => b.id).indexOf(bit.id) > -1);
                } else {
                    console.log(bits);
                    this.candidateBits = bits;
                }
            });
        }
    }

    updateMaterialCandidates() {
        if (this.bit) {
            console.log(this.bit);
            const newCandidateMaterials = new Array<MaterialChoice>();
            this.bit.materialSettings.forEach((bm) => {
                const mc = new MaterialChoice();
                mc.material = bm.material;
                mc.bits = Array.of(this.bit);
                newCandidateMaterials.push(mc);
            });
            this.candidateMaterials = newCandidateMaterials;
            console.log(newCandidateMaterials);
        } else {
            if (this.machine) {
                this.machineService.materials(this.machine.id)
                    .subscribe((materials) => {
                        this.candidateMaterials = materials;
                        console.log(materials);
                    });
            } else {
                this.machineService.allMaterials().subscribe((materials) => {
                    this.candidateMaterials = materials;
                    console.log(materials);
                });
            }
        }
    }

    ngOnInit() {
        if (this.machineId) {

        }
        this.updateMachineCandidates();
        this.updateMaterialCandidates();
        this.updateBitCandidates();
    }

    groupMachineByFn = (item) => item.owner ? item.owner : 'Public';

    updateMaterialSheet($event: MaterialSheet) {
        this.materialSheet = $event;
        this.sheetSizeChange.emit(this.materialSheet ? this.materialSheet.sheetSize : null);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['minimumSheetSize']) {
            this.updateMachineCandidates();
        }
    }
}

