<!--<div class="btn-group machine-picker">-->
<label for="machinePicker">Machine</label>
<ng-select id="machinePicker"
           [items]="candidateMachines"
           bindLabel="name"
           [(ngModel)]="machine"
           [groupBy]="groupMachineByFn"
           (ngModelChange)="updateMachine($event)"
           [disabled]="disabled" placement="top" ngbTooltip="Select machine">
    <ng-template ng-optgroup-tmp let-item="item">
        {{item.owner || 'Public'}}
    </ng-template>
</ng-select>
<!--    <button type="button" class="btn btn-primary btn-sm machine-edit-button" placement="top" ngbTooltip="View {{machine.name}}" [routerLink]="['/machines', machine.id]"><fa-icon [icon]="['fas', 'eye']"></fa-icon></button>
</div>-->
<label for="materialPicker">Material</label>
<ng-select id="materialPicker"
           [items]="candidateMaterials"
           bindLabel="material"
           [(ngModel)]="material"
           (ngModelChange)="updateBitMaterial($event)"
           [disabled]="disabled" placement="top" ngbTooltip="Select material">
</ng-select>
<label for="bitPicker">Bit</label>
<ng-select id="bitPicker"
           [items]="candidateBits"
           bindLabel="name"
           [(ngModel)]="bit"
           groupBy="owner"
           (ngModelChange)="updateBit($event)"
           [disabled]="disabled" placement="top" ngbTooltip="Select bit">
    <ng-template ng-optgroup-tmp let-item="item">
        {{item.owner || 'Public'}}
    </ng-template>
</ng-select>
<label for="sizePicker">Sheet size</label>
<app-material-size-picker id="sizePicker" [machine]="machine" [minimumSheetSize]="minimumSheetSize"
                          [material]="material" [size]="materialSheet"
                          [disabled]="disabled"
                          (sizeChange)="updateMaterialSheet($event)"></app-material-size-picker>
