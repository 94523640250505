/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "ng2-ui-auth";
import * as i5 from "@angular/router";
import * as i6 from "./services/user.service";
import * as i7 from "ng-intercom";
import * as i8 from "../../node_modules/@ngx-loading-bar/core/ngx-loading-bar-core.ngfactory";
import * as i9 from "@ngx-loading-bar/core";
import * as i10 from "@angular/common";
import * as i11 from "./alert/alert.component.ngfactory";
import * as i12 from "./alert/alert.component";
import * as i13 from "./alert/alert.service";
import * as i14 from "./app.component";
import * as i15 from "angular-gtag";
import * as i16 from "./services/sketchup.service";
import * as i17 from "./services/fusion360.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.AuthService, i5.Router, i6.UserService, i7.Intercom, i5.ActivatedRoute], { inApp: [0, "inApp"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inSketchup || _co.inFusion360); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-loading-bar", [["color", "#fff"]], [[2, "loading-bar-fixed", null]], null, null, i8.View_LoadingBarComponent_0, i8.RenderType_LoadingBarComponent)), i1.ɵdid(1, 49152, null, 0, i9.LoadingBarComponent, [i9.LoadingBarService], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "fabber-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-alert", [], null, null, null, i11.View_AlertComponent_0, i11.RenderType_AlertComponent)), i1.ɵdid(6, 114688, null, 0, i12.AlertComponent, [i13.AlertService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(10, { "fabber-noscroll": 0, "fabber-scroll": 1 }), (_l()(), i1.ɵeld(11, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(12, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "#fff"; _ck(_v, 1, 0, currVal_1); var currVal_2 = !_co.noHeader; _ck(_v, 4, 0, currVal_2); _ck(_v, 6, 0); var currVal_3 = _ck(_v, 10, 0, _co.noScroll, !_co.noScroll); _ck(_v, 9, 0, currVal_3); _ck(_v, 12, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fixed; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i14.AppComponent, [i5.ActivatedRoute, i15.Gtag, i16.SketchupService, i17.Fusion360Service, i7.Intercom, i4.AuthService, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
