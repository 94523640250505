import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from 'ng2-ui-auth';
import {Part, Slice} from '../../../target/fabber-model';
import {Observable} from 'rxjs';
import {ProjectService} from '../services/project.service';

@Component({
    selector: 'app-part',
    templateUrl: './part.component.html',
    styleUrls: ['./part.component.scss']
})
export class PartComponent implements OnInit {
    projectId: string;
    partId: string;
    geometryUrl: string;
    pi = Math.PI;
    halfpi = -1 * this.pi / 2;
    part: Observable<Part>;
    slices: Slice[];

    constructor(private route: ActivatedRoute,
                public auth: AuthService,
                private projectService: ProjectService) {
        this.slices = new Array<Slice>();
    }

    ngOnInit() {
        this.projectId = this.route.snapshot.params.id;
        this.partId = this.route.snapshot.params.partid;
        this.part = this.projectService.getPart(this.projectId, this.partId);
        this.geometryUrl = '/api/projects/' + this.route.snapshot.params.id
            + '/parts/' + this.route.snapshot.params.partid + '.stl?token=' + this.auth.getToken();
        this.projectService.getSlices(this.projectId, this.partId).subscribe(slices => {
            this.slices = slices;
            console.log(this.slices.length + ' slices loaded');
        });
    }

}
