import {AbstractObject3D} from '../objects/abstract-object-3d';
import * as THREE from 'three';
import {Subject} from 'rxjs';


export class SelectionManager {

    private hovered: Map<THREE.Object3D, AbstractObject3D<THREE.Object3D>>;
    private selected: Map<THREE.Object3D, AbstractObject3D<THREE.Object3D>>;

    constructor(private scene: AbstractObject3D<THREE.Object3D>,
                private selectionChange: Subject<AbstractObject3D<THREE.Object3D> []>,
                private hoverChange: Subject<AbstractObject3D<THREE.Object3D>[]>,
                private doubleClick: Subject<AbstractObject3D<THREE.Object3D>>,
                private closestOnly = true,
                private excludeHelpers = true) {
        this.hovered = new Map<THREE.Object3D, AbstractObject3D<THREE.Object3D>>();
        this.selected = new Map<THREE.Object3D, AbstractObject3D<THREE.Object3D>>();
    }

    public setHovered(objects: THREE.Object3D[]): void {
        // Added
        const added = objects.filter((obj) => !this.hovered.has(obj));
        // Removed
        const removed = Array.from(this.hovered.keys()).filter((obj) => objects.indexOf(obj) === -1);
        // Apply removed
        removed.forEach((obj) => {
            const component = this.hovered.get(obj);
            if (component) {
                component.onMouseOut();
            }
            this.hovered.delete(obj);
        });
        // Apply added
        const comps = added.map(obj => this.scene.find(obj))
            .filter(comp => comp != null)
            .filter(comp => !comp.isHelper());
        if (comps.length > 0) {
            this.hovered.set(comps[0].getObject(), comps[0]);
        }
        // added.forEach((obj) => {
        //     const component = this.scene.find(obj);
        //     if (component) {
        //         component.onMouseOver();
        //     }
        //     this.hovered.set(obj, component);
        // });
        // Fire event
        if (added.length > 0 || removed.length > 0) {
            this.hoverChange.next(this.getHovered());
        }
    }

    public setHoveredByPath(objectPaths: string[][]): void {
        this.setHovered(objectPaths.map(path => this.scene.findByPath(path))
            .filter(object => object != null && !object.isHelper())
            .map(object => object.getObject()));
    }

    public setSelected(objects: THREE.Object3D[]): void {
        // Added
        const added = objects.filter((obj) => !this.selected.has(obj));
        // Remained
        const remained = objects.filter((obj) => this.selected.has(obj));
        // Removed
        const removed = Array.from(this.selected.keys()).filter((obj) => objects.indexOf(obj) === -1);
        // Apply removed
        removed.forEach((obj) => {
            const component = this.selected.get(obj);
            if (component) {
                component.onDeselect();
            }
            this.selected.delete(obj);
        });
        // Apply remained
        remained.forEach((obj) => {
            const component = this.selected.get(obj);
            if (component) {
                component.onClick();
            }
        });
        // Apply added
        added.forEach((obj) => {
            const component = this.scene.find(obj);
            if (component) {
                component.onClick();
                component.onSelect();
            }
            this.selected.set(obj, component);
        });
        // Fire event
        if (added.length > 0 || removed.length > 0) {
            this.selectionChange.next(this.getSelected());
        }
    }

    public setSelectedByPath(objectPaths: string[][]): void {
        this.setSelected(objectPaths.map(path => this.scene.findByPath(path))
            .filter(object => object != null && !object.isHelper())
            .map(object => object.getObject()));
    }

    public toggleSelected(objects: THREE.Object3D[]): void {
        // Added
        const added = objects.filter((obj) => !this.selected.has(obj));
        // Removed
        const removed = objects.filter((obj) => this.selected.has(obj));
        // Apply removed
        removed.forEach((obj) => {
                const component = this.selected.get(obj);
                if (component) {
                    component.onClick();
                    component.onDeselect();
                }
                this.selected.delete(obj);
            }
        );
        // Apply added
        added.forEach((obj) => {
            const component = this.scene.find(obj);
            if (component) {
                component.onClick();
                component.onSelect();
            }
            this.selected.set(obj, component);
        });
        // Fire event
        if (added.length > 0 || removed.length > 0) {
            this.selectionChange.next(this.getSelected());
        }
    }

    public toggleSelectedByPath(objectPaths: string[][]): void {
        this.toggleSelected(objectPaths.map(path => this.scene.findByPath(path))
            .filter(object => object != null && !object.isHelper())
            .map(object => object.getObject()));
    }

    public handleDoubleClick(objects: THREE.Object3D[]): void {
        console.log(objects);
        const comps = objects.map(obj => this.scene.find(obj))
            .filter(comp => comp != null && !comp.isHelper());
        console.log(comps);
        if (comps.length > 0) {
            this.doubleClick.next(comps[0]);
        }
    }

    public handleDoubleClickByPath(objectPaths: string[][]): void {
        this.handleDoubleClick(objectPaths.sort((p1, p2) => p1.length - p2.length)
            .map(path => this.scene.findByPath(path))
            .filter(object => object != null && !object.isHelper())
            .map(object => object.getObject()));
    }

    public getHovered(): AbstractObject3D<THREE.Object3D>[] {
        return Array.from(this.hovered.values()).filter((obj) => obj !== null);
    }

    public getSelected(): AbstractObject3D<THREE.Object3D>[] {
        return Array.from(this.selected.values()).filter((obj) => obj !== null);
    }
}
