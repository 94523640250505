<div class="fabber-sidebar">
    <div class="ml-1 mr-1 mt-1">
        <div class="fabber-sidebar-row" *ngIf="sheets!=null">
            <h5>Current Sheet</h5>
            <p>Select sheet to view and cut</p>
            <ngb-pagination class="d-flex justify-content-center" [disabled]="loading || drawingParts"
                            [collectionSize]="nSheets" [pageSize]="1" [(page)]="currentSheet" [maxSize]="3"
                            [rotate]="true" [boundaryLinks]="true" (pageChange)="selectSheet($event)"></ngb-pagination>
        </div>
        <div class="fabber-sidebar-row" *ngIf="!loading && !drawingParts">
            <h5>Edit Sheet</h5>
            <p>Enable editing of sheet nesting</p>
            <div class="btn-group">
                <button class="btn btn-outline-primary" id="enableEditing" (click)="toggleEditing()">
                    <fa-icon *ngIf="!editing" [icon]="['fas', 'lock-alt']"></fa-icon>
                    <fa-icon *ngIf="editing" [icon]="['fas', 'lock-open-alt']"></fa-icon>
                </button>
                <!-- <button *ngIf="editing" [disabled]="rotator == null"
                        class="btn btn-outline-primary" placement="bottom" ngbTooltip="Rotate -1 degree" id="rotateCCW15" (click)="rotator(-1)">
                    <fa-icon [icon]="['fas', 'undo']"></fa-icon>
                </button>
                <button *ngIf="editing" [disabled]="rotator == null"
                        class="btn btn-outline-primary" placement="bottom" ngbTooltip="Rotate -15 degrees" id="rotateCCW1" (click)="rotator(-15)">
                    <fa-icon [icon]="['fas', 'undo']"></fa-icon>
                </button>
                <button *ngIf="editing" [disabled]="rotator==null"
                        class="btn btn-outline-primary" placement="bottom" ngbTooltip="Rotate 15 degrees" id="rotateCW1" (click)="rotator(15)">
                    <fa-icon [icon]="['fas', 'redo']"></fa-icon>
                </button>
                <button *ngIf="editing" [disabled]="rotator==null"
                        class="btn btn-outline-primary" placement="bottom" ngbTooltip="Rotate 1 degree" id="rotateCW15" (click)="rotator(1)">
                    <fa-icon [icon]="['fas', 'redo']"></fa-icon>
                </button> -->
            </div>
        </div>
        <div class="fabber-sidebar-row" *ngIf="!loading && !drawingToolpath">
            <h5>Download Code</h5>
            <p>Download the code here to cut the current sheet</p>
            <app-post-process-button [projectId]="projectId" [sheetId]="sheetId"></app-post-process-button>
        </div>
        <div class="fabber-sidebar-row" *ngIf="(currentPart | async) as loadedPart">
            <h5>Selected Part</h5>
            <p>{{loadedPart.name}}</p>
            <p>Instance {{currentPartInstance.replace(loadedPart.name + ':', '')}}
                of {{loadedPart.instances.length}}</p>

        </div>
    </div>
</div>
<div class="fabber-top-overlay">
    <div class="container-flex mt-1 ml-3 mr-3">
        <div class="row">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="['/project', projectId]">Select Parts</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Manufacture</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<div #viewerDiv id="viewerDiv" class="svgViewer"></div>
