import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Restangular} from 'ngx-restangular';
import {Observable, of} from 'rxjs';
import {Machine, Bit, BitMaterial, MaterialChoice, MaterialSheet, PostProcessor} from '../../../target/fabber-model';
import {UserService} from './user.service';
import {map} from 'rxjs/operators';
import {mergeMap} from 'rxjs/internal/operators/mergeMap';

@Injectable({
    providedIn: 'root'
})
export class MachineService {

    constructor(private router: Router, private restangular: Restangular, private userService: UserService) {
    }

    all(): Observable<Array<Machine>> {
        return this.restangular.all('machines').getList();
    }

    allBits(): Observable<Array<Bit>> {
        return this.restangular.all('bits').getList();
    }

    allMaterials(): Observable<Array<MaterialChoice>> {
        return this.restangular.all('materials').getList();
    }

    get(id: string): Observable<Machine> {
        return this.restangular.one('machines', id).get();
    }

    copy(machine: Machine): Observable<Machine> {
        return this.userService.me(false).pipe(mergeMap((me) => {
            const newMachine = Machine.fromData(machine);
            newMachine.id = null;
            newMachine.owner = me.id;
            newMachine.name = machine.name + ' - Copy';
            return this.add(newMachine);
        }));
    }

    copyBit(bit: Bit): Observable<Bit> {
        return this.userService.me(false).pipe(mergeMap((me) => {
            const newBit = Bit.fromData(bit);
            newBit.id = null;
            newBit.owner = me.id;
            newBit.name = bit.name + ' - Copy';
            return this.addBit(newBit);
        }));
    }

    remove(id: string): Observable<any> {
        return this.restangular.one('machines', id).remove();
    }

    removeBit(id: string): Observable<any> {
        return this.restangular.one('bits', id).remove();
    }

    add(machine: Machine): Observable<Machine> {
        return this.restangular.all('machines').post(machine);
    }

    addBit(bit: Bit): Observable<Bit> {
        return this.restangular.all('bits').post(bit);
    }

    brands(): Observable<string[]> {
        return this.all().pipe(map(machines => machines.map(m => m.brand).filter((m, i, ms) => ms.indexOf(m) === i)));
    }

    bits(machineId: string): Observable<Bit[]> {
        return this.restangular.one('machines', machineId).customGET('bits');
    }

    materials(machineId: string): Observable<Array<MaterialChoice>> {
        return this.restangular.one('machines', machineId).customGET('materials');
    }

    getBit(bitId: string): Observable<Bit> {
        return this.restangular.one('bits', bitId).get();
    }

    allMaterialSizes(): Observable<Array<MaterialSheet>> {
        return this.restangular.all('materials').all('sizes').getList();
    }

    materialSizes(material: string): Observable<Array<MaterialSheet>> {
        return this.restangular.all('materials').all('sizes').all(material).getList();
    }

    getPostProcessor(id: string): Observable<PostProcessor> {
        return this.restangular.one('postprocessors', id).get();
    }

    getPostProcessors(): Observable<PostProcessor[]> {
        return this.restangular.all('postprocessors').getList();
    }
}
