/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./login.component";
import * as i3 from "ng2-ui-auth";
import * as i4 from "@angular/router";
import * as i5 from "@angular/forms";
import * as i6 from "../services/form-helper.service";
import * as i7 from "angular-gtag";
import * as i8 from "@ngx-loading-bar/core";
import * as i9 from "../alert/alert.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Get Fabber!"], ["class", "img-fluid d-block mx-auto"], ["src", "assets/fabber-1line-2color-bold-lowres.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 14, "div", [["class", "row mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "mb-3 lead"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login here to access your Fabber projects"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-primary w-100 btn-lg"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.providerLogin("memberful") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "p", [["class", "mb-3 lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Don't have an account? "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["href", "https://getfabber.com/"], ["target", "getfabber"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign up"])), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "col-md-6"], ["style", ""]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inProgress; _ck(_v, 10, 0, currVal_0); }); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoginComponent, [i3.AuthService, i4.Router, i4.ActivatedRoute, i5.FormBuilder, i6.FormHelperService, i7.Gtag, i8.LoadingBarService, i9.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i2.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
