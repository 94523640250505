import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import { ProjectSummary, Part, CuttingList, CuttingMachineMaterial } from '../../../target/fabber-model';
import {ProjectService} from '../services/project.service';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from 'ng2-ui-auth';

@Component({
  selector: 'app-assembly',
  templateUrl: './assembly.component.html',
  styleUrls: ['./assembly.component.scss']
})
export class AssemblyComponent implements OnInit {
  parts: Part[];
  pi = Math.PI;
  halfpi = -1 * this.pi / 2;
  projectId: string;
  constructor(private projectService: ProjectService,
              private route: ActivatedRoute,
              public auth: AuthService) {
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.params.id;
    this.projectService.getParts(this.route.snapshot.params.id).subscribe((parts) => this.parts = parts);
  }

  geometryUrl(part: Part) {
    return  '/api/projects/' + this.route.snapshot.params.id
        + '/parts/' + part.id + '.stl?token=' + this.auth.getToken();
  }

}
