<div class="container">
    <div class="row">
        <div class="col-md-12"><img class="img-fluid d-block mx-auto" src="assets/fabber-1line-2color-bold-lowres.png" alt="Get Fabber!"></div>
    </div>
    <div class="row mt-2">
        <div class="px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center" style="">
            <p class="mb-3 lead" style="">Logging out, please wait...</p>
        </div>
        <div class="col-md-6" style=""></div>
    </div>
</div>

