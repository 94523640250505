import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {ProjectService} from '../services/project.service';
import {
    ProjectSummary, Sheets, SheetSet, SheetSize, Sheet, Placement, Toolpath, ToolpathGroup, ToolpathFeature, ToolpathPathSegment,
    ZJog, ZCut, XYJog, XYCut, XYArcCut, Slice, SliceFeature, LineArcPath, CirclePath, EllipsePath, Part, JogStart, JogHome
} from '../../../target/fabber-model';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from 'ng2-ui-auth';
import {Color} from './color';
import {DistanceOp} from 'jsts/dist/jsts';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UnitsService} from '../services/units/units.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {AlertService} from '../alert/alert.service';
import {Title} from '@angular/platform-browser';
import { LessDepth } from 'three';
import { ConsoleErrorListener } from 'antlr4ts';

declare const SVG: any;

@Component({
    selector: 'app-sheets',
    templateUrl: './sheets.component.html',
    styleUrls: ['./sheets.component.scss']
})
export class SheetsComponent implements OnInit, AfterViewInit, OnDestroy {
    selected = false;
    projectId: string;
    sheetId: string;
    project: ProjectSummary;
    loading = false;
    drawingParts = false;
    drawingToolpath = false;
    editing = false;
    currentSheet: number;
    sheetChange = new EventEmitter<number>();
    viewerHeight: number;
    sheets: Sheets;
    nSheets = 0;
    draw: any;
    currentPartId: string;
    @ViewChild('viewerDiv', {static: true}) viewerDiv: ElementRef;
    currentPartInstance: string;
    rotator: (angle: number) => void;
    currentPart: Observable<Part>;
    partMap = new Map<string, any>();
    halfPi = Math.PI / 2;

    constructor(private projectService: ProjectService,
                private route: ActivatedRoute,
                public auth: AuthService,
                private unitsService: UnitsService,
                private modalService: NgbModal,
                private loadingBar: LoadingBarService,
                private alertService: AlertService,
                private title: Title) {
    }

    ngOnInit() {
        this.projectId = this.route.snapshot.params.id;
        this.currentSheet = 1;
        this.viewerHeight = 0;
        this.loading = true;
        this.loadingBar.start();
        this.sheetChange.subscribe({
            next: (n) => {
                console.log('Selected sheet ' + n);
                const sheet = this.getSheet(n);
                this.sheetId = sheet.id;
                this.drawSheet(sheet, this.getSheetSize(n));
            },
            error: (err) => this.alertService.error(err)
        });
        this.projectService.get(this.projectId).subscribe({
            next: (project) => {
                this.project = project;
                this.title.setTitle(this.project.name + ' : Manufacture');
                this.projectService.getSheets(this.projectId).subscribe({
                    next: (sheets) => {
                        let n = 0;
                        sheets.sheetSets.forEach((sheetSet) => n += sheetSet.sheets.length);
                        this.nSheets = n;
                        this.sheets = sheets;
                        this.loading = false;
                        this.loadingBar.complete();
                        if (this.nSheets > 0) {
                            this.selectSheet(1);
                        }
                    },
                    error: (err) => {
                        this.loading = false;
                        this.loadingBar.complete();
                        console.log(err);
                        this.alertService.error(err);
                    }
                });
            }, error: (err) => {
                this.loading = false;
                this.loadingBar.complete();
                this.alertService.error(err);
            }
        });
    }

    ngOnDestroy(): void {
        this.loading = false;
        this.loadingBar.complete();
    }

    ngAfterViewInit() {
        this.viewerHeight = Math.max(this.viewerDiv.nativeElement.clientHeight, this.viewerDiv.nativeElement.clientWidth / 2);
        this.draw = SVG('viewerDiv').size(this.viewerDiv.nativeElement.clientWidth,
            this.viewerHeight).panZoom({zoomMin: 0.5, zoomMax: 20});
    }

    drawSheet(sheet: Sheet, size: SheetSize) {
        this.drawingParts = true;
        this.loadingBar.start();
        this.draw.clear();
        const xMax = this.unitsService.convertQuantity(size.length, 'mm');
        const yMax = this.unitsService.convertQuantity(size.width, 'mm');
        this.draw.viewbox({x: -1, y: -1, width: xMax + 2, height: yMax + 2});
        this.draw.rect(xMax, yMax).attr({'stroke-width': 1, stroke: '#263d47', fill: '#dde5e9'});
        const partsGroup = this.draw.group();
        const toolpathGroup = this.draw.group();
        partsGroup.transform({scaleY: -1, cy: yMax / 2});
        toolpathGroup.transform({scaleY: -1, cy: yMax / 2});
        this.projectService.getSheetSlices(this.projectId, sheet.id).subscribe({
            next: (slices) => {
                this.partMap.clear();
                sheet.parts.forEach((part) => this.drawPart(partsGroup, part, slices[part.part], toolpathGroup));
                this.drawingParts = false;
                this.drawingToolpath = true;
                this.projectService.getToolpath(this.projectId, sheet.id).subscribe({
                    next: (toolpath) => {
                        this.drawToolpath(toolpathGroup, toolpath);
                        this.drawingToolpath = false;
                        this.loadingBar.complete();
                    },
                    error: (err) => {
                        this.drawingToolpath = false;
                        this.loadingBar.complete();
                        this.alertService.error(err);
                    }
                });
            },
            error: (err) => {
                this.drawingParts = false;
                this.drawingToolpath = false;
                this.loadingBar.complete();
                this.alertService.error(err);
            }
        });
    }

    drawPart(group: any, part: Placement, slice: Slice, toolpathGroup: any) {
        const partGroup = group.group();
        const xyOriginal = part.translation;
        const initRotation = part.rotation;
        const matrix = new SVG.Matrix(part.rotation[0], part.rotation[1], -part.rotation[1],
                        part.rotation[0], part.translation[0], part.translation[1]);
        const outerPath = this.drawPath(partGroup, slice.outer, matrix, 'outer', 0);
        const depth = this.unitsService.convertQuantity(slice.thickness, 'mm');
        slice.features
            .sort((a, b) => this.unitsService.convertQuantity(a.depth, 'mm')
                - this.unitsService.convertQuantity(b.depth, 'mm'))
            .forEach((feature) => {
                const cutDepth = feature.hole ? 1 : this.unitsService.convertQuantity(feature.depth, 'mm') / depth;
                console.log('Drawing feature at depth of ' + cutDepth);
                this.drawPath(partGroup, feature.path, matrix, feature.hole ? 'hole' : 'pocket', cutDepth);
                feature.innerPaths.forEach(island => {
                    const islandDepth = this.unitsService.convertQuantity(feature.startDepth, 'mm') / depth;
                    console.log('Drawing island at depth of ' + islandDepth);
                    console.log(island);
                    this.drawPath(partGroup, island, matrix, 'top',
                        islandDepth);
                });
            });
        this.partMap.set(part.instance, partGroup);   
        // TODO: Add editing capabilities such as dragging, rotating
        partGroup.on('mouseover', (ev) => {           
            // TODO: Set rotator function
            outerPath.stroke({width: 5});
        });
        partGroup.on('mouseout', (ev) => {
            outerPath.stroke({width: 0.5});
        });
        partGroup.on('dragend', (e, h, b) => {
            const drag = partGroup.transform().matrix;
            this.movePart(part, drag.e + xyOriginal[0], drag.f + xyOriginal[1], [1,0], toolpathGroup);
        });

        partGroup.on('click', (ev) => {
            //console.log(group);        
            // group.forEach(element => {
            //     element.selected = false;
            // });

            partGroup.selected = true; 
            if (partGroup.selected == true){
                //change filling color
                outerPath.attr({'fill': '#9DB2B6'});
            } else {outerPath.attr({'fill': '#000000'})}; 
            
            this.setPart(part.part, part.instance);

            this.rotator = (rotateBy: number) => {
                console.log('rotateBy: ' + rotateBy, partGroup.transform());
                console.log(partGroup.transform().rotation);
                //partGroup.rotate with positive angle rotates counterclockwise and with a negative angle rotates clockwise (hence the '-')
                partGroup.rotate(partGroup.transform().rotation - rotateBy, xyOriginal[0] , xyOriginal[1]);
                console.log(partGroup.transform());
                //this.movePart(part,xyOriginal[0] , xyOriginal[1] , [ partGroup.transform().rotation, rotateBy] , toolpathGroup);
                this.movePart(part,xyOriginal[0] , xyOriginal[1] , [partGroup.transform().matrix.a, partGroup.transform().matrix.b], toolpathGroup);

                // this.movePart(part,xyOriginal[0] , xyOriginal[1] , [Math.cos(partGroup.transform().rotation-rotateBy), 
                //     Math.sin(partGroup.transform().rotation-rotateBy)] , toolpathGroup);
            };
        });
    }

    
    
    movePart(part: Placement, x: number, y: number, rotateBy: Array<number>, toolpathGroup: any): void {
        console.log('Move ' + part.part + ':' + part.instance + ' to ' + x + ', ' + y);
        const theSheet = this.getSheet(this.currentSheet);
        console.log('theSheet:' ,theSheet);
        const newSheet = new Sheet();
        newSheet.id = theSheet.id;
        newSheet.parts = theSheet.parts.map(p => {
            if (p.part === part.part && p.instance === part.instance) {
                const newP = new Placement();
                // Calculate effect of the rotation on the translation
                const xr = 0;//p.translation[0] - (p.translation[0] * Math.cos(rotateBy) - p.translation[1] * Math.sin(rotateBy));
                const yr = 0;//p.translation[1] - (p.translation[0] * Math.sin(rotateBy) + p.translation[1] * Math.cos(rotateBy));
                newP.part = p.part;
                newP.instance = p.instance;
                console.log(rotateBy[0],rotateBy[1]);
                newP.rotation = p.rotation;
                
                // newP.rotation =[Math.cos(Math.acos(p.rotation[0]) + Math.acos(rotateBy[0])), 
                //                 Math.sin(Math.asin(p.rotation[1]) + Math.asin(rotateBy[1]))];                
                //newP.rotation =[Math.cos(rotateBy[0]),Math.sin(rotateBy[0])];            
                console.log('initial rotation: ' + p.rotation + ' , extra rotation: ' + rotateBy + ' , newP.rotation: ' + newP.rotation);  
                newP.translation = new Array<number>();
                newP.translation.push(x + xr);
                newP.translation.push(y + yr);
                newP.startX = p.startX + (x + xr - p.translation[0]);
                newP.startY = p.startY + (y + yr - p.translation[1]);
                // newP.translation = [0,0];
                // newP.startX = 0;
                // newP.startY = 0;
                return newP;
            } else {
                return p;
            }
        })
        //console.log(newSheet);
        toolpathGroup.clear();
        this.loadingBar.start();
        this.projectService.updateSheet(this.projectId, theSheet.id, newSheet).subscribe({
            next: (returnedSheet) => {
                // Redraw toolpaths
                console.log('Updated on server');
                this.setSheet(newSheet);
                this.drawingToolpath = true;
                this.projectService.getToolpath(this.projectId, theSheet.id).subscribe({
                    next: (toolpath) => {
                        this.drawToolpath(toolpathGroup, toolpath);
                        this.drawingToolpath = false;
                        this.loadingBar.complete();
                    },
                    error: (err) => {
                        this.drawingToolpath = false;
                        this.loadingBar.complete();
                        this.alertService.error(err);
                    }
                });
            }, error: (err) => {
                this.loadingBar.complete();
                this.alertService.error(err);
            }
        });
    }

    setPart(partId: string, instance: string) {
        this.currentPartId = partId;
        this.currentPartInstance = instance;
        this.currentPart = this.projectService.getPart(this.projectId, this.currentPartId);
    }

    drawPath(group: any, path: LineArcPath | CirclePath | EllipsePath, matrix: any, type: string, cutDepth: number): any {
        const topColor = new Color('#d6c7b9');
        const deepPocketColor = new Color('#5c4f45');
        const holeColor = new Color('#dde5e9');
        const outerLineColor = new Color('#b1a08b');
        const innerLineColor = new Color('#68594d');
        const fillColor = type === 'hole' ? holeColor : topColor.interpolate(deepPocketColor, cutDepth);
        const lineColor = new Color('#68594d'); // type === 'pocket' ? innerLineColor : outerLineColor;
        // console.log(matrix);
        switch (path.type) {
            case 'linearc':
                const lineArcPath = path as LineArcPath;
                return group.path(lineArcPath.path)
                    .stroke({width: 0.5, color: lineColor.toHex()}).transform(matrix)
                    .attr({'fill': fillColor.toHex()});
            case 'circle':
                const circlePath = path as CirclePath;
                return group.circle(circlePath.r * 2)
                    .move(circlePath.x - circlePath.r, circlePath.y - circlePath.r)
                    .attr({'fill': fillColor.toHex()})
                    .stroke({width: 0.5, color: lineColor.toHex()}).transform(matrix);
            case 'ellipse':
                const ellipsePath = path as EllipsePath;
                return group.ellipse(ellipsePath.rx * 2, ellipsePath.ry * 2)
                    .move(ellipsePath.x - ellipsePath.rx, ellipsePath.y - ellipsePath.ry)
                    .rotate(ellipsePath.rotation)
                    .attr({'fill': fillColor.toHex()})
                    .stroke({width: 0.5, color: lineColor.toHex()}).transform(matrix);
        }
        return null;
    }

    drawToolpath(group: any, toolpath: Toolpath) {
        toolpath.groups.forEach((toolpathGroup) => this.drawToolpathGroup(group, toolpathGroup));
    }

    drawToolpathGroup(group: any, toolpathGroup: ToolpathGroup) {
        toolpathGroup.features.forEach((feature) => this.drawToolpathFeature(group, feature));
    }

    drawToolpathFeature(group: any, feature: ToolpathFeature) {
        let color: string;
        let xy = [this.unitsService.convertQuantity(feature.start.x, 'mm'),
            this.unitsService.convertQuantity(feature.start.y, 'mm')];
        this.drawMarker(group, xy);
        switch (feature.featureType) {
            case 'drill':
                color = '#F0FF00'; //yellow
                break;
            case 'hole':
                color = '#005FFF'; //blue
                break;
            case 'pocket':
                color = '#00BE0C'; //green
                break;
            case 'outline':
                color = '#FF05BF'; //purple
                break;
        }   
        feature.segments.forEach((segment) => {
            xy = this.drawToolpathSegment(group, xy, segment, color);
        });
    }

    drawMarker(group: any, xy: number[]) {
        // group.circle(4).move(xy[0] - 2, xy[1] - 2).attr({'fill': '#ff0000'});
    }

    drawToolpathSegment(group: any, xy: number[], segment: ToolpathPathSegment, color_str: string) {
        
        const endxy = [xy[0].valueOf(), xy[1].valueOf()];
        const startxy = new Array<number>(2);
        switch (segment.type) {
            case 'xyJog':
                const xyJog = segment as XYJog;
                endxy[0] = this.unitsService.convertQuantity(xyJog.x, 'mm');
                endxy[1] = this.unitsService.convertQuantity(xyJog.y, 'mm');
                startxy[0] = endxy[0] - this.unitsService.convertQuantity(xyJog.dx, 'mm');
                startxy[1] = endxy[1] - this.unitsService.convertQuantity(xyJog.dy, 'mm');
                group.line(xy[0], xy[1], endxy[0], endxy[1]).stroke({width: 0.5, color: color_str});
                break;
            case 'JogStart':
                const jogStart = segment as JogStart;
                endxy[0] = this.unitsService.convertQuantity(jogStart.x, 'mm');
                endxy[1] = this.unitsService.convertQuantity(jogStart.y, 'mm');
                startxy[0] = endxy[0] - this.unitsService.convertQuantity(jogStart.dx, 'mm');
                startxy[1] = endxy[1] - this.unitsService.convertQuantity(jogStart.dy, 'mm');
                group.line(xy[0], xy[1], endxy[0], endxy[1]).stroke({width: 0.5, color: color_str});
                break;
            case 'JogHome':
                const jogHome = segment as JogHome;
                endxy[0] = this.unitsService.convertQuantity(jogHome.x, 'mm');
                endxy[1] = this.unitsService.convertQuantity(jogHome.y, 'mm');
                startxy[0] = endxy[0] - this.unitsService.convertQuantity(jogHome.dx, 'mm');
                startxy[1] = endxy[1] - this.unitsService.convertQuantity(jogHome.dy, 'mm');
                group.line(xy[0], xy[1], endxy[0], endxy[1]).stroke({width: 0.5, color: color_str});
                break;
            case 'xyCut':
                const xyCut = segment as XYCut;
                // console.log(xyCut);
                endxy[0] = this.unitsService.convertQuantity(xyCut.x, 'mm');
                endxy[1] = this.unitsService.convertQuantity(xyCut.y, 'mm');
                startxy[0] = endxy[0] - this.unitsService.convertQuantity(xyCut.dx, 'mm');
                startxy[1] = endxy[1] - this.unitsService.convertQuantity(xyCut.dy, 'mm');
                group.line(startxy[0], startxy[1], endxy[0], endxy[1]).stroke({width: 0.5, color: color_str});
                break;
            case 'xyArcCut':
                const xyArcCut = segment as XYArcCut;
                // console.log(xyArcCut);
                endxy[0] = this.unitsService.convertQuantity(xyArcCut.x, 'mm');
                endxy[1] = this.unitsService.convertQuantity(xyArcCut.y, 'mm');
                startxy[0] = endxy[0] - this.unitsService.convertQuantity(xyArcCut.dx, 'mm');
                startxy[1] = endxy[1] - this.unitsService.convertQuantity(xyArcCut.dy, 'mm');
                const centre = [this.unitsService.convertQuantity(xyArcCut.xc, 'mm'),
                    this.unitsService.convertQuantity(xyArcCut.yc, 'mm')];
                const radius = Math.sqrt(Math.pow(centre[0] - endxy[0], 2) + Math.pow(centre[1] - endxy[1], 2));
                group.path('M ' + startxy[0] + ' ' + startxy[1] + ' A ' +
                    [radius, radius, 0.0, this.largeArc(xy, centre, endxy, xyArcCut.clockwise) ? 1 : 0,
                        xyArcCut.clockwise ? 0 : 1, endxy[0], endxy[1]].join(' '))
                    .attr({'fill': null, 'fill-opacity': 0, 'stroke-width': 0.5, 'stroke': color_str});
                break;
            default:
            // console.log(xy);
            // console.log(segment);
        }
        return endxy;
    }

    largeArc(start: number[], centre: number[], end: number[], clockwise: boolean): boolean {
        // If centre is on clockwise (right) side, it is !clockwise, else clockwise
        const centreIsClockwise = 0 < (centre[0] - start[0]) * (end[1] - start[1]) - (centre[1] - start[1]) * (end[0] - start[0]);
        return centreIsClockwise !== clockwise;
    }

    reload() {
        this.loading = true;
        this.loadingBar.start();
        this.projectService.getSheets(this.projectId).subscribe({
            next: (sheets) => {
                console.log(sheets);
                let n = 0;
                sheets.sheetSets.forEach((sheetSet) => n += sheetSet.sheets.length);
                this.nSheets = n;
                this.sheets = sheets;
                this.loading = false;
                this.loadingBar.complete();
                if (this.nSheets > 0) {
                    this.selectSheet(1);
                }
            },
            error: (err) => {
                this.loading = false;
                this.loadingBar.complete();
                this.alertService.error(err);
            }
        });
    }

    selectSheet(n: number) {
        this.sheetChange.emit(n);
    }

    getSheet(n: number): Sheet {
        const allSheets = new Array<Sheet>();
        this.sheets.sheetSets.forEach((sheetSet) => sheetSet.sheets.forEach((sheet) => allSheets.push(sheet)));
        return allSheets[n - 1];
    }

    setSheet(newSheet: Sheet): void {
        const newSheets = new Sheets();
        newSheets.projectId = this.sheets.projectId;
        newSheets.created = this.sheets.created;
        newSheets.edited = new Date();
        newSheets.id = this.sheets.id;
        newSheets.sheetSets = this.sheets.sheetSets.map(sheetSet => {
            const newSet = new SheetSet();
            newSet.sheetSize = sheetSet.sheetSize;
            newSet.bitId = sheetSet.bitId;
            newSet.machineId = sheetSet.machineId;
            newSet.material = sheetSet.material;
            newSet.thickness = sheetSet.thickness;
            newSet.sheets = sheetSet.sheets.map(sheet => sheet.id === newSheet.id ? newSheet : sheet);
            return newSet;
        });
        this.sheets = newSheets;
    }

    getSheetSize(n: number): SheetSize {
        const sheetSetIndices = new Array<number>();
        this.sheets.sheetSets.forEach((sheetSet, i) => sheetSet.sheets.forEach((sheet) => sheetSetIndices.push(i)));
        return this.sheets.sheetSets[sheetSetIndices[n - 1]].sheetSize;
    }

    toggleEditing() {
        this.editing = !this.editing;
        this.partMap.forEach((value, key) => value.draggable(this.editing));
    }
}
