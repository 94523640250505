import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-post-processor',
  templateUrl: './admin-post-processor.component.html',
  styleUrls: ['./admin-post-processor.component.scss']
})
export class AdminPostProcessorComponent implements OnInit {
    content: string;

  constructor() {
    this.content = 'G12\nM1\n';
}

  ngOnInit() {
    // TODO: Fetch the postprocessor definition
  }

  update($event: any) {

  }
}
