<div class="fabber-top-overlay">
    <div class="container-flex mt-1 ml-3 mr-3">
        <div class="row">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="['/project', projectId]">Select Parts</a></li>
                    <li class="breadcrumb-item active" aria-current="page">... Viewing
                        part {{ (part | async)?.name }}</li>
                    <li class="breadcrumb-item"><a [routerLink]="['/project', projectId, 'sheets']">Manufacture</a></li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<three-toolframe>
    <three-toolbar placement="Bottom">
        <app-camera-controls [button]="true" [zoomToFitInitial]="true"></app-camera-controls>
    </three-toolbar>
    <three-webgl-renderer clearColor="#7f7f7f" clearOpacity="1">
        <three-orthographic-camera [fov]=6 positionX=500 positionY=500 positionZ=500>
            <three-directional-light color="white" intensity="1" distance="10" translateX=0 translateY=0
                                     translateZ=0></three-directional-light>
        </three-orthographic-camera>

        <three-scene [rotateX]="halfpi">
            <three-axes-helper size=2></three-axes-helper>
            <three-hemisphere-light groundColor="grey" skyColor="white" intensity="0.3"
                                    translateZ="1"></three-hemisphere-light>
            <three-directional-light color="white" intensity="0.3" distance="10" translateX=1 translateY=-1
                                     translateZ=1></three-directional-light>
            <three-directional-light color="white" intensity="0.3" distance="10" translateX=1 translateY=1
                                     translateZ=1></three-directional-light>
            <three-directional-light color="white" intensity="0.3" distance="10" translateX=-1 translateY=1
                                     translateZ=1></three-directional-light>
            <three-stl-loader [model]="geometryUrl"></three-stl-loader>
            <ng-container *ngFor="let slice of slices">
                <app-slice-geometry [slice]="slice"></app-slice-geometry>
            </ng-container>
        </three-scene>

        <three-hovered-outline-pass></three-hovered-outline-pass>
    </three-webgl-renderer>
</three-toolframe>
