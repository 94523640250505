export const environment = {
    location: 'https://fabber.app',
    beta: true,
    production: true,
    memberful: {
        clientid: 'tLLe12hFpfW86qqqdLBPdMBR',
        redirecturl: 'https://fabber.app/api/auth/redirect',
    },
    google: {
        clientid: '1002349224119-72lbd345ulsncjpe776i2de8vhd64fn0.apps.googleusercontent.com',
        redirecturl: 'https://fabber.app/api/auth/redirect',
        analytics: {
            uid: 'UA-131430495-1'
        }
    },
    onshape: {
        clientid: 'YA4AQPDSBGH2T4NAG77NBH5LCBCPZD3R7BOJIVQ=',
        redirecturl: 'https://fabber.app/api/auth/redirect'
    },
    autodesk: {
        clientid: '0WMJkI4iG05g67fQvTFp7OH5G7wM57GJ',
        redirecturl: 'https://fabber.app/api/auth/redirect'
    }
};
