import {APP_INITIALIZER, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { Ng2UiAuthModule } from 'ng2-ui-auth';
import { GtagModule } from 'angular-gtag';
import { DragulaModule } from 'ng2-dragula';
import { FileUploadModule } from 'ng2-file-upload';
import { HttpClientModule } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NgSelectModule } from '@ng-select/ng-select';
import {AngularSplitModule} from 'angular-split';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { View2dComponent } from './view2d/view2d.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { HeaderComponent } from './header/header.component';
import { LandingComponent } from './landing/landing.component';

import { fas as ffas } from '@fortawesome/free-solid-svg-icons';
import { far as ffar } from '@fortawesome/free-regular-svg-icons';
import { fas as pfas } from '@fortawesome/pro-solid-svg-icons';
import { far as pfar } from '@fortawesome/pro-regular-svg-icons';
import { CadpickerComponent } from './cadpicker/cadpicker.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SketchupService } from './services/sketchup.service';
import { Fusion360Service } from './services/fusion360.service';
import { OnshapeService } from './services/onshape.service';
import { ParameterSchemaService } from './services/parameter-schema.service';
import { RestangularModule} from 'ngx-restangular';
import { AuthService } from 'ng2-ui-auth';
import { ProjectsComponent } from './projects/projects.component';
import { PartsListComponent } from './parts-list/parts-list.component';
import { ProfileComponent } from './profile/profile.component';

import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminProjectsComponent } from './admin/admin-projects/admin-projects.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AdminTasksComponent } from './admin/admin-tasks/admin-tasks.component';
import { AdminFilesComponent } from './admin/admin-files/admin-files.component';

import { environment } from '../environments/environment';
import { SketchupComponent } from './sketchup/sketchup.component';
import { MachinesComponent } from './machines/machines.component';
import { AdminInvitationsComponent } from './admin/admin-invitations/admin-invitations.component';
import { AdminMachinesComponent } from './admin/admin-machines/admin-machines.component';
import { MachinePickerComponent } from './machine-picker/machine-picker.component';
import { MaterialPickerComponent } from './material-picker/material-picker.component';

import { ThreeModule } from './three/three.module';
import { ThreeToolsModule } from './three-tools/three-tools.module';

import { Ng5SliderModule } from 'ng5-slider';

import 'codemirror/mode/handlebars/handlebars';
import 'codemirror/mode/xml/xml';
import './js/mode/gcode/gcode';
import './js/mode/shopbot/shopbot';


import { AdminPostProcessorComponent } from './admin/admin-post-processor/admin-post-processor.component';
import { MachineEditorComponent } from './machine-editor/machine-editor.component';
import { SheetsComponent } from './sheets/sheets.component';
import { ParameterFieldComponent } from './parameter-field/parameter-field.component';
import { PartComponent } from './part/part.component';
import { MaterialSizePickerComponent } from './material-size-picker/material-size-picker.component';
import { ToolpathStrategyEditorComponent } from './toolpath-strategy-editor/toolpath-strategy-editor.component';
import { TOSComponent } from './tos/tos.component';
import { AssemblyComponent } from './assembly/assembly.component';
import { PathGeometryDirective } from './part/path-geometry.directive';
import { BitPickerComponent } from './bit-picker/bit-picker.component';
import { PostProcessButtonComponent } from './post-process-button/post-process-button.component';
import { BitEditorComponent } from './bit-editor/bit-editor.component';
import { TasksComponent } from './tasks/tasks.component';
import { UnitSetEditorComponent } from './services/units/unit-set-editor/unit-set-editor.component';
import { UnitPipe } from './services/units/unit.pipe';
import {BASE_UNITSET, UnitSet} from './services/units/unit-set';
import {UnitsService} from './services/units/units.service';
import { LogoutComponent } from './logout/logout.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WaitingComponent } from './waiting/waiting.component';
import { IntercomModule } from 'ng-intercom';
import { TextFieldComponent } from './parameter-field/text-field/text-field.component';
import { NumberFieldComponent } from './parameter-field/number-field/number-field.component';
import {AlertService} from './alert/alert.service';
import {AlertComponent} from './alert/alert.component';
import { MomentPipe } from './services/moment.pipe';
import {UserResolver} from './guards/user-resolver';
import { PostProcessorEditorComponent } from './post-processor-editor/post-processor-editor.component';
import { VersionComponent } from './header/version/version.component';
import { StrategyPickerComponent } from './machine-editor/strategy-picker/strategy-picker.component';
import { LayerSetGeometryDirective } from './part/layer-set-geometry.directive';
import { SliceGeometryDirective } from './part/slice-geometry.directive';
import { ProjectComponent } from './project/project.component';
import { WorkshopComponent } from './workshop/workshop.component';

export const APP_BASEUNITS: UnitSet = {
    units: [
        {unit: 'm', precision: 6},
        {unit: 'cm', precision: 3},
        {unit: 'mm', precision: 2},
        {unit: 'inch', precision: 3},
        {unit: 'm/s', precision: 3},
        {unit: 'mm/s', precision: 1},
        {unit: 'mm/minute', precision: 1},
        {unit: 'inch/s', precision: 2},
        {unit: 'inch/minute', precision: 2},
        {unit: 'rad/s', precision: 3},
        {unit: 'rpm', precision: 0}
    ]
};
export function RestangularConfigFactory (RestangularProvider, auth: AuthService) {
    RestangularProvider.setBaseUrl(environment.location + '/api');
    // Add request handler to add header each time, so that only when auth'd
    RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
        const bearerToken = auth.getToken();
        return {
            headers: Object.assign({}, headers, {Authorization: `Bearer ${bearerToken}`})
        };
    });
    // Add error handler to update token if required

}

export function loadSchema(parameterSchemaService: ParameterSchemaService) {
    return () => parameterSchemaService.loadSchema();
}

@NgModule({
    declarations: [
        AppComponent,
        View2dComponent,
        PageNotFoundComponent,
        HeaderComponent,
        LandingComponent,
        CadpickerComponent,
        LoginComponent,
        SignupComponent,
        ProjectsComponent,
        PartsListComponent,
        ProfileComponent,
        AdminDashboardComponent,
        AdminProjectsComponent,
        AdminUsersComponent,
        AdminTasksComponent,
        SketchupComponent,
        MachinesComponent,
        AdminFilesComponent,
        AdminInvitationsComponent,
        AdminMachinesComponent,
        MachinePickerComponent,
        MaterialPickerComponent,
        AdminPostProcessorComponent,
        MachineEditorComponent,
        SheetsComponent,
        ParameterFieldComponent,
        PartComponent,
        MaterialSizePickerComponent,
        ToolpathStrategyEditorComponent,
        TOSComponent,
        AssemblyComponent,
        PathGeometryDirective,
        BitPickerComponent,
        PostProcessButtonComponent,
        BitEditorComponent,
        TasksComponent,
        UnitSetEditorComponent,
        UnitPipe,
        LogoutComponent,
        WaitingComponent,
        TextFieldComponent,
        NumberFieldComponent,
        AlertComponent,
        MomentPipe,
        PostProcessorEditorComponent,
        VersionComponent,
        StrategyPickerComponent,
        LayerSetGeometryDirective,
        SliceGeometryDirective,
        ProjectComponent,
        WorkshopComponent
    ],
    imports: [
        NgSelectModule,
        ThreeModule,
        ThreeToolsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgbCollapseModule,
        NgbModalModule,
        NgbDropdownModule,
        FontAwesomeModule,
        FileUploadModule,
        DragulaModule.forRoot(),
        HttpClientModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        Ng5SliderModule,
        AngularSplitModule,
        GtagModule.forRoot({trackingId: environment.google.analytics.uid, trackPageviews: true}),
        SweetAlert2Module.forRoot(),
        Ng2UiAuthModule.forRoot({
            baseUrl: environment.location + '/api',
            providers: {
                memberful: {
                    name: 'memberful',
                    url: '/auth/memberful',
                    authorizationEndpoint: 'https://getfabber.memberful.com/oauth',
                    oauthType: '2.0',
                    popupOptions: {width: 600, height: 600},
                    clientId: environment.memberful.clientid,
                    redirectUri: environment.memberful.redirecturl
                },
                memberfulsignup: {
                    name: 'memberful',
                    url: '/auth/memberful',
                    authorizationEndpoint: 'https://getfabber.memberful.com/checkout?plan=38910',
                    oauthType: '2.0',
                    popupOptions: {width: 600, height: 600},
                    clientId: environment.memberful.clientid,
                    redirectUri: environment.memberful.redirecturl
                },
                onshape: {
                    name: 'onshape',
                    url: '/auth/onshape',
                    authorizationEndpoint: 'https://oauth.onshape.com/oauth/authorize',
                    oauthType: '2.0',
                    popupOptions: {width: 600, height: 600},
                    clientId: environment.onshape.clientid,
                    redirectUri: environment.onshape.redirecturl
                },
                autodesk: {
                    name: 'autodesk',
                    url: '/auth/autodesk',
                    authorizationEndpoint: 'https://developer.api.autodesk.com/authentication/v1/authorize',
                    oauthType: '2.0',
                    popupOptions: {width: 600, height: 600},
                    clientId: environment.autodesk.clientid,
                    scope: ['data:read', 'data:write', 'data:create', 'data:search',
                        'bucket:create', 'bucket:read', 'bucket:update', 'bucket:delete',
                        'code:all',
                        'account:read', 'account:write',
                        'user-profile:read',
                        'viewables:read'],
                    scopeDelimiter: ' '
                },
                google: {
                    url: '/auth/google',
                    clientId: environment.google.clientid
                }
            }
        }),
        RestangularModule.forRoot([AuthService], RestangularConfigFactory),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: true}),
        IntercomModule.forRoot({
            appId: 't7tq0zk6',
            updateOnRouterChange: true
        }),
        CodemirrorModule
    ],
    providers: [CookieService,
        SketchupService,
        Fusion360Service,
        OnshapeService,
        ParameterSchemaService,
        UnitsService,
        AlertService,
        UserResolver,
        { provide: BASE_UNITSET, useValue: APP_BASEUNITS },
        {
            provide: APP_INITIALIZER,
            useFactory: loadSchema,
            deps: [ParameterSchemaService],
            multi: true
        }],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(ffas, ffar, pfas, pfar);
    }
}
