/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-process-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./post-process-button.component";
import * as i7 from "@angular/common/http";
import * as i8 from "../services/project.service";
import * as i9 from "ng-intercom";
var styles_PostProcessButtonComponent = [i0.styles];
var RenderType_PostProcessButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostProcessButtonComponent, data: {} });
export { RenderType_PostProcessButtonComponent as RenderType_PostProcessButtonComponent };
function View_PostProcessButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(2, 2)], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "fas", "download"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PostProcessButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"], spin: [1, "spin"] }, null), i1.ɵpad(2, 2)], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "fas", "sync-alt"); var currVal_3 = true; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_PostProcessButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn-outline-primary"], ["id", "postProcess"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostProcessButtonComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostProcessButtonComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.inProgress; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.inProgress; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inProgress || _co.disabled); _ck(_v, 0, 0, currVal_0); }); }
export function View_PostProcessButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-process-button", [], null, null, null, View_PostProcessButtonComponent_0, RenderType_PostProcessButtonComponent)), i1.ɵdid(1, 114688, null, 0, i6.PostProcessButtonComponent, [i7.HttpClient, i8.ProjectService, i9.Intercom], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostProcessButtonComponentNgFactory = i1.ɵccf("app-post-process-button", i6.PostProcessButtonComponent, View_PostProcessButtonComponent_Host_0, { projectId: "projectId", sheetId: "sheetId", disabled: "disabled" }, {}, []);
export { PostProcessButtonComponentNgFactory as PostProcessButtonComponentNgFactory };
