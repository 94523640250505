import { Component, OnInit } from '@angular/core';
import {UserService} from '../services/user.service';
import {AlertService} from '../alert/alert.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  inProgress = false;
  constructor(private userService: UserService, private alertService: AlertService) {
  }

  ngOnInit() {
  }

  signup() {
    this.inProgress = true;
    this.userService.signup().subscribe({
      error: (err: any) => {
        this.inProgress = false;
        this.alertService.error(err);
      },
      complete: () => {
        this.inProgress = false;
      }
    });
  }

}
