import { Pipe, PipeTransform } from '@angular/core';
import * as Qty from 'js-quantities';
import {UnitsService} from './units.service';

@Pipe({
  name: 'unit'
})
export class UnitPipe implements PipeTransform {

  constructor(private unitsService: UnitsService) {
  }

  transform(value: string | Qty.Type, args?: any): string {
    let q;
    if (typeof(value) === 'string') {
      q = Qty(value);
    } else {
      q = value as Qty.Type;
    }
    return this.unitsService.format(q);
  }

}
