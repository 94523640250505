import { ProjectSummary, Part, Slice, CuttingList, Sheets, Toolpath, Sheet } from '../../../target/fabber-model';
import { Restangular } from 'ngx-restangular';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "ngx-restangular";
export class ProjectService {
    constructor(router, restangular) {
        this.router = router;
        this.restangular = restangular;
    }
    all() {
        return this.restangular.all('projects').getList();
    }
    get(id) {
        return this.restangular.one('projects', id).get();
    }
    getParts(id) {
        return this.restangular.one('projects', id).all('parts').getList();
    }
    getPart(id, partid) {
        return this.restangular.one('projects', id).one('parts', partid).get();
    }
    getSlices(id, partid) {
        return this.restangular.one('projects', id).one('parts', partid).all('slices').getList();
    }
    getCuttingList(projectId) {
        return this.restangular.one('projects', projectId).customGET('cuttinglist');
    }
    saveCuttingList(projectId, cl) {
        return this.restangular.one('projects', projectId).customPUT(cl, 'cuttinglist');
    }
    remove(id) {
        return this.restangular.one('projects', id).remove();
    }
    create(name) {
        const newProject = new ProjectSummary();
        newProject.name = name;
        return this.restangular.all('projects').post(newProject);
    }
    createAndUpload(name) {
        this.create(name).subscribe(project => this.navigateToUpload(project));
    }
    navigateToProject(project) {
        this.router.navigate(['/project', project.id]);
    }
    navigateToUpload(project) {
        this.router.navigate(['/project', project.id]);
    }
    getSheets(id) {
        return this.restangular.one('projects', id).customGET('sheets');
    }
    updateSheets(id, sheets) {
        return this.restangular.one('projects', id).customPUT('sheets', sheets);
    }
    updateSheet(id, sheetId, sheet) {
        return this.restangular.one('projects', id).one('sheets', sheetId).customPUT(sheet);
    }
    getToolpath(projectId, sheetId) {
        return this.restangular.one('projects', projectId).one('sheets', sheetId).customGET('toolpath');
    }
    getSheetSlices(projectId, sheetId) {
        return this.restangular.one('projects', projectId).one('sheets', sheetId).customGET('slices');
    }
}
ProjectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectService_Factory() { return new ProjectService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Restangular)); }, token: ProjectService, providedIn: "root" });
