<div class="container">
    <div class="row">
        <div class="col-md-12"><img class="img-fluid d-block mx-auto" src="assets/fabber-1line-2color-bold-lowres.png" alt="Get Fabber!"></div>
    </div>
</div>
<div class="container mt-2" *ngIf="beta && invitation_code==null">
    <div class="row">
        <div class="col-md-12">
            <p class="lead">Fabber is in invitation-only Beta.</p>
            <p>Please fill out our form to request an invitation code, this will be emailed to you, we look forward to welcoming you to Fabber!</p>
            <a class="btn btn-primary btn-lg" href="https://goo.gl/forms/REgnr8c3SNeZHaq23" target="beta">Request access to our Beta!</a>
        </div>
    </div>
</div>
<div class="container mt-2"*ngIf="!beta || invitation_code!=null">
    <div class="row">
        <div class="col-md-6" style="">
            <form class="justify-content-center" [formGroup]="form" (ngSubmit)="signup(form.value)">
                <div class="form-group" [ngClass]="{'has-error':fh.hasError(form, 'name')}">
                    <label for="name">Name:</label>
                    <div class="alert alert-danger" *ngIf="fh.hasError(form, 'name')">Name must be at least 3 characters
                        long.</div>
                    <input type="text" class="form-control" id="name" name="name" [formControl]="form.controls['name']">
                </div>
                <div class="form-group" [ngClass]="{'has-error':fh.hasError(form, 'email')}">
                    <label for="email">Email address:</label>
                    <div class="alert alert-danger" *ngIf="fh.hasError(form, 'email')">Email must be at least 3 characters
                        long.</div>
                    <input type="text" class="form-control" id="email" name="email" [formControl]="form.controls['email']">
                </div>
                <div [formGroup]="form.controls['password-group']">
                    <div class="form-group" [ngClass]="{'has-error':fh.hasError(form, 'password-group')}">
                        <label for="pwd">Password:</label>
                        <div class="alert alert-danger" *ngIf="fh.hasError(form, 'password-group', 'password')">Password must be at
                            least 6 characters long.</div>
                        <input type="password" class="form-control" id="pwd" name="password" [formControl]="fh.getControl(form, 'password-group', 'password')">
                    </div>
                    <div class="form-group" [ngClass]="{'has-error':fh.hasError(form, 'password-group', 'confirm-password')}">
                        <label for="confirm-pwd">Confirm Password:</label>
                        <div class="alert alert-danger" *ngIf="fh.hasError(form, 'password-group')">Password confirmation does not
                            match the password.</div>
                        <input type="password" class="form-control" id="confirm-pwd" name="confirm-password" [formControl]="fh.getControl(form, 'password-group', 'confirm-password')">
                    </div>
                </div>
                <button type="submit" class="btn btn-primary w-100 btn-lg" [disabled]="inProgress || !form.valid || !termsAccepted">Join Fabber!</button>
            </form>
            <br><p class="mb-3 lead">Already a Fabber user? <a [routerLink]="['/login']" [queryParams]="{to: '/upload'}">Click here to login</a>.</p>
        </div>
        <div class="col-md-6" style="">
            <button class="btn btn-primary w-100 btn-lg" [swal]="terms" (confirm)="acceptTerms()">Review terms and conditions</button>
            <div style="margin-top: 20px;">
                <button class="btn btn-primary w-100 btn-lg" (click)="providerSignup('onshape')" [disabled]="inProgress || !termsAccepted">Join with my Onshape account</button>
            </div>
            <div style="margin-top: 20px;">
                <button class="btn btn-primary w-100 btn-lg" (click)="providerSignup('autodesk')" [disabled]="inProgress || !termsAccepted">Join with my Autodesk account</button>
            </div>
            <div style="margin-top: 20px;">
                <button class="btn btn-primary w-100 btn-lg" (click)="providerSignup('google')" [disabled]="inProgress || !termsAccepted">Join with my Google account</button>
            </div>
        </div>
    </div>
</div>
<swal #terms title="Terms of Use">
    <div *swalPartial style="height: 50ps; overflow-y: scroll;">
        <h1>Privacy Policy</h1>

        <p class="lead">Effective date: January 03, 2019</p>

        <p class="lead">Fabber Inc ("us", "we", or "our") operates the www.getfabber.com website
            (hereinafter referred to as the "Service").</p>

        <p class="lead">This page informs you of our policies regarding the collection, use and
            disclosure of personal data when you use our Service and the choices you have
            associated with that data.</p>

        <p class="lead">We use your data to provide and improve the Service. By using the Service, you
            agree to the collection and use of information in accordance with this policy.
            Unless otherwise defined in this Privacy Policy, the terms used in this
            Privacy Policy have the same meanings as in our Terms and Conditions,
            accessible from www.getfabber.com</p>

        <h2>Definitions</h2>
        <dl class="row">
            <dt class="col-sm-3">Service</dt>
            <dd class="col-sm-9">Service is the www.getfabber.com website operated by Fabber Inc.</dd>
            <dt class="col-sm-3">Personal Data</dt>
            <dd class="col-sm-9">Personal Data means data about a living individual who can be identified
                from those data (or from those and other information either in our
                possession or likely to come into our possession).</dd>
            <dt class="col-sm-3">Usage Data</dt>
            <dd class="col-sm-9">Usage Data is data collected automatically either generated by the use of
                the Service or from the Service infrastructure itself (for example, the
                duration of a page visit).</dd>
            <dt class="col-sm-3">Cookies</dt>
            <dd class="col-sm-9">Cookies are small files stored on your device (computer or mobile device).</dd>
            <dt class="col-sm-3">Data Controller</dt>
            <dd class="col-sm-9">Data Controller means the natural or legal person who (either alone or
                jointly or in common with other persons) determines the purposes for which
                and the manner in which any personal information are, or are to be,
                processed.
                <footer class="blockquote-footer">For the purpose of this Privacy Policy, we are a Data Controller of your
                    Personal Data.</footer></dd>
            <dt class="col-sm-3">Data Processors (or Service Providers)</dt>
            <dd class="col-sm-9">Data Processor (or Service Provider) means any natural or legal person who
                processes the data on behalf of the Data Controller.
                <footer class="blockquote-footer">We may use the services of various Service Providers in order to process
                    your data more effectively.</footer></dd>
            <dt class="col-sm-3">Data Subject (or User)</dt>
            <dd class="col-sm-9">Data Subject is any living individual who is using our Service and is the
                subject of Personal Data.</dd>
        </dl>

        <h2>Information Collection and Use</h2>
        <p>We collect several different types of information for various purposes to
            provide and improve our Service to you.</p>

        <h2>Types of Data Collected</h2>
        <h3>Personal Data</h3>

        <p>While using our Service, we may ask you to provide us with certain personally
            identifiable information that can be used to contact or identify you
            ("Personal Data"). Personally identifiable information may include, but is not
            limited to:</p>
        <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Cookies and Usage Data</li>
        </ul>
    </div>
</swal>
