/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./landing.component";
import * as i5 from "../services/user.service";
import * as i6 from "../alert/alert.service";
var styles_LandingComponent = [i0.styles];
var RenderType_LandingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingComponent, data: {} });
export { RenderType_LandingComponent as RenderType_LandingComponent };
export function View_LandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Get Fabber!"], ["class", "img-fluid d-block mx-auto"], ["src", "assets/fabber-1line-2color-bold-lowres.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 17, "div", [["class", "row mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 14, "div", [["class", "px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Design to fabrication in seconds"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "mb-3 lead"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload your model into Fabber. We'll figure out how to fabricate and send you back ready to run G Code for your machine"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "btn btn-primary w-100 btn-lg"], ["href", "https://www.getfabber.com"], ["target", "getfabber"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Let's go!"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 6, "p", [["class", "mb-3 lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Already a Fabber user? "])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(17, 1), (_l()(), i1.ɵted(-1, null, ["Click here to login"])), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["class", "col-md-6"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "img", [["class", "img-fluid d-block"], ["src", "https://static.pingendo.com/img-placeholder-tablet.svg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _ck(_v, 17, 0, "/login"); _ck(_v, 16, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 16).target; var currVal_1 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_0, currVal_1); }); }
export function View_LandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing", [], null, null, null, View_LandingComponent_0, RenderType_LandingComponent)), i1.ɵdid(1, 114688, null, 0, i4.LandingComponent, [i5.UserService, i6.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingComponentNgFactory = i1.ɵccf("app-landing", i4.LandingComponent, View_LandingComponent_Host_0, {}, {}, []);
export { LandingComponentNgFactory as LandingComponentNgFactory };
