/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-tasks.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./admin-tasks.component";
var styles_AdminTasksComponent = [i0.styles];
var RenderType_AdminTasksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminTasksComponent, data: {} });
export { RenderType_AdminTasksComponent as RenderType_AdminTasksComponent };
export function View_AdminTasksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" admin-tasks works!\n"]))], null, null); }
export function View_AdminTasksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-tasks", [], null, null, null, View_AdminTasksComponent_0, RenderType_AdminTasksComponent)), i1.ɵdid(1, 114688, null, 0, i2.AdminTasksComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminTasksComponentNgFactory = i1.ɵccf("app-admin-tasks", i2.AdminTasksComponent, View_AdminTasksComponent_Host_0, {}, {}, []);
export { AdminTasksComponentNgFactory as AdminTasksComponentNgFactory };
