<div class="container mt-2">
    <div class="row">
        <div class="col-md-12">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Signed Up</th>
                        <th scope="col">Last Login</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of (users$ | async); index as i">
                        <th scope="row">{{user.name}}</th>
                        <td>{{user.email}}</td>
                        <td>{{formatDate(user.signup_date)}}</td>
                        <td>{{formatDate(user.last_login)}}</td>
                        <td>
                            <button class="btn btn-primary btn-sm">Reset Password</button>
                            <button class="btn btn-primary btn-sm ml-1">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
