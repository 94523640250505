/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-users.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./admin-users.component";
import * as i4 from "ngx-restangular";
var styles_AdminUsersComponent = [i0.styles];
var RenderType_AdminUsersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminUsersComponent, data: {} });
export { RenderType_AdminUsersComponent as RenderType_AdminUsersComponent };
function View_AdminUsersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "th", [["scope", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-primary btn-sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reset Password"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary btn-sm ml-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.email; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.formatDate(_v.context.$implicit.signup_date); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.formatDate(_v.context.$implicit.last_login); _ck(_v, 8, 0, currVal_3); }); }
export function View_AdminUsersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "container mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 16, "table", [["class", "table table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Signed Up"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Last Login"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Actions"])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AdminUsersComponent_1)), i1.ɵdid(18, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform(_co.users$)); _ck(_v, 18, 0, currVal_0); }, null); }
export function View_AdminUsersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-users", [], null, null, null, View_AdminUsersComponent_0, RenderType_AdminUsersComponent)), i1.ɵdid(1, 114688, null, 0, i3.AdminUsersComponent, [i4.Restangular], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminUsersComponentNgFactory = i1.ɵccf("app-admin-users", i3.AdminUsersComponent, View_AdminUsersComponent_Host_0, {}, {}, []);
export { AdminUsersComponentNgFactory as AdminUsersComponentNgFactory };
