import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Observable, timer} from 'rxjs';

export interface OnshapeListener {
    show();

    hide();

}

@Injectable({
    providedIn: 'root'
})
export class OnshapeService {
// https://dev-portal.onshape.com/doc/clientmessaging.html
    constructor(private router: Router, private route: ActivatedRoute) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event) => {
                let r = this.route;
                while (r.firstChild) {
                    r = r.firstChild;
                }
                r.params.subscribe(params => {
                    this.init(params);
                });
            });
    }

    private init(params: Params): void {
        if (params['documentId']) {
            window.addEventListener('keydown', (event: KeyboardEvent) => this.handleKeydown(event), false);
            window.addEventListener('message', (event: MessageEvent) => this.handleMessage(event), false);
            timer(10000, 10000).subscribe(() => this.postMessage('keepAlive'));
        }
    }

    private handleKeydown(event: KeyboardEvent): void {
        // Shift-S
        if (event.shiftKey && event.key === 's') {
            this.postMessage('saveAVersion');
        }
        // Shift-?
        if (event.shiftKey && event.key === '?') {
            this.postMessage('showKeyboardShortcutsHelp');
        }
    }

    private handleMessage(event: MessageEvent): void {
        if (event.data && event.data.messageName) {
            switch (event.data.messageName) {
                case 'show':
                case 'hide':
            }
        }

    }

    protected postMessage(type: string, data?: object) {
        const message = {messageName: type};
        if (data) {
            Object.keys(data).forEach(key => message[key] = data[key]);
        }
        window.parent.postMessage(message, '*');
    }

    public showMessageBubble(message: string = ''): void {
        this.postMessage('showMessageBubble', {message: message});
    }

    public showLoadingSpinner(message: string = ''): () => void {
        this.postMessage('startLoadingSpinner', {message: message});
        return () => this.postMessage('stopLoadingSpinner');
    }

    public showWorkingSpinner(): () => void {
        this.postMessage('startWorkingSpinner');
        return () => this.postMessage('stopWorkingSpinner');
    }

}
