import { Component, OnInit } from '@angular/core';
import { Alert, AlertService, AlertType } from './alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  alerts: Array<Alert> = [];

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.alertService.getStatus()
      .subscribe(alert => this.showAlert(alert));
  }

  getAlertType(alert: Alert): string {
    switch (alert.type) {
      case AlertType.SUCCESS:
        return 'success';
      case AlertType.DANGER:
        return 'danger';
      case AlertType.INFO:
        return 'info';
      case AlertType.WARNING:
        return 'warning';
      default:
        return 'primary';
    }
  }

  getAlertTitle(alert: Alert): string {
    switch (alert.type) {
      case AlertType.SUCCESS:
        return 'Success';
      case AlertType.DANGER:
        return 'Error';
      case AlertType.INFO:
        return 'Info';
      case AlertType.WARNING:
        return 'Warning';
    }
  }

  close(index: number) {
    this.alerts.splice(index, 1);
  }

  showAlert(alert: Alert) {
    if (!alert) { return; }

    const index = this.alerts.push(alert) - 1;
    setTimeout(_ => this.close(index), alert.time);
  }

}
