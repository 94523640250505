<div class="container mt-2" *ngIf="(machine$ | async) as machine">
    <h3><span *ngIf="editable(machine)">Edit</span><span *ngIf="!editable(machine)">View</span>
        Machine: {{machine.name}}</h3>
    <div class="form-group">
        <label for="inputName">Name</label>
        <app-text-field id="inputName" type="machine"
                        [field]="['name']" [(object)]="machine"
                        [disabled]="!editable(machine)"></app-text-field>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputBrand">Brand</label>
            <app-text-field id="inputBrand" type="machine"
                            [field]="['brand']" [(object)]="machine"
                            [options]="brands"
                            [disabled]="!editable(machine)"></app-text-field>
        </div>
        <div class="form-group col-md-6">
            <label for="inputModel">Model</label>
            <app-text-field id="inputModel" type="machine"
                            [field]="['model']" [(object)]="machine"
                            [disabled]="!editable(machine)"></app-text-field>
        </div>
    </div>
    <h4>Machine Dimensions and Speeds</h4>
    <div class="form-group">
        <label for="inputCuttingArea">Cutting area</label>
        <app-parameter-field id="inputCuttingArea" type="machine" [field]="['xLength','yLength', 'zHeight']"
                             [(object)]="machine" [disabled]="!editable(machine)"></app-parameter-field>
    </div>
    <div class="form-group">
        <label for="inputSpindleSpeed">Spindle speed</label>
        <app-parameter-field id="inputSpindleSpeed" type="machine" [field]="['minSpindleSpeed', 'maxSpindleSpeed']"
                             [(object)]="machine" [disabled]="!editable(machine)"></app-parameter-field>
    </div>
    <div class="form-group">
        <label for="inputColletSize">Collet size</label>
        <app-parameter-field id="inputColletSize" type="machine" [field]="['minColletSize', 'maxColletSize']"
                             [(object)]="machine" [disabled]="!editable(machine)"></app-parameter-field>
    </div>
    <div class="form-group">
        <label for="inputCuttingSpeed">Cutting speed</label>
        <app-parameter-field id="inputCuttingSpeed" type="machine" [field]="['minCuttingSpeed', 'maxCuttingSpeed']"
                             [(object)]="machine" [disabled]="!editable(machine)"></app-parameter-field>
    </div>
    <h4>Nesting Configuration</h4>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="nestingGap">Nesting gap</label>
            <app-parameter-field id="nestingGap" type="toolpathstrategy" [field]="['nestingGap']"
                                 [(object)]="machine.toolpathStrategy"
                                 [disabled]="!editable(machine)"></app-parameter-field>
        </div>
        <div class="form-group col-md-6">
            <label for="nestingRotations">Nesting rotations</label>
            <div class="input-group input-group-sm">
                <input type="number" [(ngModel)]="machine.toolpathStrategy.nestingRotations" id="nestingRotations"
                       [min]="1"
                       [max]="16"
                       style="text-align: right; width: 100%;">
            </div>
        </div>
    </div>
    <h4>Toolpath Configuration</h4>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="orderStrategy">Order strategy</label>
            <app-strategy-picker id="orderStrategy" [(object)]="machine.toolpathStrategy" field="orderStrategy"
                                 [options]="[{name: 'By Feature Type', value: 'ByFeatureType'}, {name: 'By Part', value: 'ByPart'}]"></app-strategy-picker>
            <label for="infillStrategy">Infill strategy</label>
            <app-strategy-picker id="infillStrategy" [(object)]="machine.toolpathStrategy" field="infillStrategy"
                                 [options]="[{name: 'Spiral In', value: 'SpiralIn'}, {name: 'Raster', value: 'Raster'}, {name: 'None', value: 'None'}]"></app-strategy-picker>
            <!--            <label for="rasterAngle">Raster angle</label>-->
            <!--            <app-parameter-field id="rasterAngle" type="toolpathstrategy" [field]="['rasterAngle']"-->
            <!--                                 [(object)]="machine.toolpathStrategy"-->
            <!--                                 [disabled]="!editable(machine) || machine.toolpathStrategy.infillStrategy!=='Raster'"></app-parameter-field>-->
            <label for="jogHeightStrategy">Jog height strategy</label>
            <app-strategy-picker id="jogHeightStrategy" [(object)]="machine.toolpathStrategy" field="jogHeightStrategy"
                                 [options]="[{name: 'Set Height', value: 'SetHeight'}, {name: 'Safe Z', value: 'SafeZ'}]"></app-strategy-picker>
            <label for="jogHeight">Jog height</label>
            <app-parameter-field id="jogHeight" type="toolpathstrategy" [field]="['jogHeight']"
                                 [(object)]="machine.toolpathStrategy"
                                 [disabled]="!editable(machine) || machine.toolpathStrategy.jogHeightStrategy==='SafeZ'"></app-parameter-field>
            <label for="zeroStrategy">Zero strategy</label>
            <app-strategy-picker id="zeroStrategy" [(object)]="machine.toolpathStrategy" field="zeroStrategy"
                                 [options]="[{name: 'Table', value: 'Table'}, {name: 'Top of Material', value: 'TopOfMaterial'}]"></app-strategy-picker>
            <label for="xyHome">Home position</label>
            <app-parameter-field id="xyHome" type="toolpathstrategy" [field]="['xHome', 'yHome']"
                                 [(object)]="machine.toolpathStrategy"
                                 [disabled]="!editable(machine)"></app-parameter-field>
            <label for="drillStrategy">Drill strategy</label>
            <app-strategy-picker id="drillStrategy" [(object)]="machine.toolpathStrategy" field="drillStrategy"
                                 [options]="[{name: 'None', value: 'None'}, {name:'Peck', value:'Peck'}]"></app-strategy-picker>
        </div>
        <div class="form-group col-md-6">
            <label for="internalCornerStrategy">Internal corner strategy</label>
            <app-strategy-picker id="internalCornerStrategy" [(object)]="machine.toolpathStrategy"
                                 field="internalCornerStrategy"
                                 [options]="[{name: 'None', value: 'None'}, {name: 'T-Bone', value: 'TBone', beta: true}, {name: 'Dog-Bone', value: 'DogBone'}]"></app-strategy-picker>
            <label for="externalCornerStrategy">External corner strategy</label>
            <app-strategy-picker id="externalCornerStrategy" [(object)]="machine.toolpathStrategy"
                                 field="externalCornerStrategy"
                                 [options]="[{name: 'Round', value: 'Round'}, {name: 'Fly Past', value: 'FlyPast'}]"></app-strategy-picker>
            <label for="edgeStrategy">Edge strategy</label>
            <app-strategy-picker id="edgeStrategy" [(object)]="machine.toolpathStrategy" field="edgeStrategy"
                                 [options]="[{name: 'None', value: 'None'}, {name:'Super Edge', value:'SuperEdge'}]"></app-strategy-picker>
            <label for="infillStepover">Infill stepover</label>
            <div class="input-group input-group-sm">
                <input type="number" [ngModel]="machine.toolpathStrategy.infillStepover" [min]="1" [max]="99"
                       id="infillStepover" style="text-align: right; width: 100%;">
            </div>
            <label for="holdingStrategy">Holding strategy</label>
            <app-strategy-picker id="holdingStrategy" [(object)]="machine.toolpathStrategy" field="holdingStrategy"
                                 [options]="[{name: 'Vacuum', value: 'Vacuum'}, {name:'Super Skin', value:'SuperSkin'}]"></app-strategy-picker>
            <label for="punchThroughDepth">Punch through depth</label>
            <app-parameter-field id="punchThroughDepth" type="toolpathstrategy" [field]="['punchThroughDepth']"
                                 [(object)]="machine.toolpathStrategy"
                                 [disabled]="!editable(machine) || machine.toolpathStrategy.holdingStrategy==='SuperSkin'"></app-parameter-field>
            <label for="superSkinThickness">Super Skin thickness</label>
            <app-parameter-field id="superSkinThickness" type="toolpathstrategy" [field]="['superSkinThickness']"
                                 [(object)]="machine.toolpathStrategy"
                                 [disabled]="!editable(machine) || machine.toolpathStrategy.holdingStrategy!=='SuperSkin'"></app-parameter-field>

        </div>
    </div>
    <h4>Post-Processor</h4>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputPostProcessor">Postprocessor Format</label>
            <div id="inputPostProcessor" class="btn-group-sm btn-group-toggle btn-group-vertical w-100" ngbRadioGroup
                 [(ngModel)]="machine.postProcessStrategy.postProcessor">
                <label ngbButtonLabel class="btn-outline-primary text-truncate"
                       *ngFor="let pp of postProcessors$ | async">
                    <input ngbButton type="radio" [value]="pp.id" [disabled]="!editable(machine)">{{pp.name}}
                </label>
            </div>
        </div>
        <div class="col-md-6">
            <cae-unit-set-editor [minDecimalPlaces]="0" [maxDecimalPlaces]="8"
                                 [(unitSet)]="machine.postProcessStrategy.units"></cae-unit-set-editor>
            <label for="zDirectionStrategy">Z Direction strategy</label>
            <app-strategy-picker id="zDirectionStrategy" [(object)]="machine.postProcessStrategy" field="zDirectionStrategy"
                                 [options]="[{name: 'Positive Up', value: 'PositiveUp'}, {name:'Positive Down', value:'PositiveDown'}]"></app-strategy-picker>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputCutSpeed">Cut speed</label>
            <ng5-slider id="inputCutSpeed" [(value)]="machine.postProcessStrategy.cutRate"
                        [options]="speedSliderOptions"></ng5-slider>
        </div>
        <div class="col-md-6">
            <label for="inputJogSpeed">Jog speed</label>
            <div class="input-group input-group-sm">
                <div class="input-group-prepend"></div>
                <ng5-slider id="inputJogSpeed" [(value)]="machine.postProcessStrategy.jogRate"
                            [options]="speedSliderOptions"></ng5-slider>
                <div class="input-group-append">
                    <fa-icon [icon]="['fas', 'fa-adjust']"></fa-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-md-9">
        </div>
        <div class="col-md-3">
            <button *ngIf="editable(machine)" class="btn btn-primary btn-lg float-right" (click)="save(machine)">
                <fa-icon [icon]="['fas', 'save']"></fa-icon>
                Save
            </button>
            <button *ngIf="editable(machine)" class="btn btn-primary-outline btn-lg float-right" (click)="cancel()">
                <fa-icon [icon]="['fas', 'ban']"></fa-icon>
                Cancel
            </button>
            <button *ngIf="!editable(machine)" class="btn btn-primary btn-lg float-right" (click)="cancel()">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                Back
            </button>
        </div>
    </div>

</div>
