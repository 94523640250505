import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {Unit, UnitSet} from '../unit-set';
import * as Qty from 'js-quantities';
import {UnitsService} from '../units.service';

@Component({
    selector: 'cae-unit-set-editor',
    templateUrl: './unit-set-editor.component.html',
    styleUrls: ['./unit-set-editor.component.scss']
})
export class UnitSetEditorComponent implements OnInit {
    @Input() baseUnitSet: UnitSet;
    @Input() unitSet: UnitSet;
    @Input() minDecimalPlaces = 0;
    @Input() maxDecimalPlaces = 6;

    @Output() unitSetChange = new EventEmitter<UnitSet>();

    constructor(private unitsService: UnitsService) {
    }

    ngOnInit() {

    }

    quantities(): string[] {
        return this.unitsService.quantities();
    }

    availableUnits(q: string): string[] {
        return this.unitsService.unitsForQuantity(q);
    }

    selectedUnit(q: string): string {
        return this.unitsService.selectedForQuantity(q, this.unitSet);
    }

    selectUnit(q: string, u: string): void {
        const newUnit = new Unit();
        newUnit.unit = u;
        newUnit.precision = this.selectedDecimal(q);
        this.unitSet.units.forEach((value, index, array) => {
            if (Qty(value.unit).kind() === q) {
                array.splice(index, 1);
            }
        });
        this.unitSet.units.push(newUnit);
        this.unitSetChange.emit(this.unitSet);
    }

    availableDecimals(q: string): number[] {
        const selected = this.selectedDecimal(q);
        const out = new Array<number>();
        for (let i = this.minDecimalPlaces; i <= this.maxDecimalPlaces; i++) {
            if (selected !== i) {
                out.push(i);
            }
        }
        return out;
    }

    selectedDecimal(q: string): number {
        return this.unitsService.decimalForUnit(this.selectedUnit(q), this.unitSet);
    }

    selectDecimal(q: string, d: number): void {
        const newUnit = new Unit();
        newUnit.unit = this.selectedUnit(q);
        newUnit.precision = d;
        this.unitSet.units.forEach((value, index, array) => {
            if (value.unit === newUnit.unit) {
                array.splice(index, 1);
            }
        });
        this.unitSet.units.push(newUnit);
        this.unitSetChange.emit(this.unitSet);
    }

    decimalString(d: number): string {
        let option = '';
        if (d > 0) {
            option += '0.';
            for (let j = 1; j < d; j++) {
                option += '0';
            }
        }
        option += '1';
        return option;
    }
}
