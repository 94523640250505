/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sketchup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./sketchup.component";
import * as i5 from "ng2-ui-auth";
import * as i6 from "ngx-restangular";
import * as i7 from "../services/user.service";
import * as i8 from "../alert/alert.service";
var styles_SketchupComponent = [i0.styles];
var RenderType_SketchupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SketchupComponent, data: {} });
export { RenderType_SketchupComponent as RenderType_SketchupComponent };
function View_SketchupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sketchup to fabrication in seconds"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "mb-3 lead"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please wait while we connect Sketchup to Fabber..."]))], null, null); }
function View_SketchupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sketchup to fabrication in seconds"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "mb-3 lead"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you for connecting Sketchup to Fabber. Signup or Login below to start uploading"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-primary w-100 btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Let's go!"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "p", [["class", "mb-3 lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Already a Fabber user? "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.login() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], null, null), (_l()(), i1.ɵted(-1, null, ["Click here to login"])), (_l()(), i1.ɵted(-1, null, [". "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11).target; var currVal_1 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_0, currVal_1); }); }
function View_SketchupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sketchup to fabrication in seconds"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "mb-3 lead"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you for connecting Sketchup to Fabber. Please return to Sketchup to start uploading."]))], null, null); }
export function View_SketchupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Get Fabber!"], ["class", "img-fluid d-block mx-auto"], ["src", "assets/fabber-1line-2color-bold-lowres.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SketchupComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SketchupComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SketchupComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "col-md-6"], ["style", ""]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inProgress; _ck(_v, 7, 0, currVal_0); var currVal_1 = (!_co.inProgress && !_co.loggedIn); _ck(_v, 9, 0, currVal_1); var currVal_2 = (!_co.inProgress && _co.loggedIn); _ck(_v, 11, 0, currVal_2); }, null); }
export function View_SketchupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sketchup", [], null, null, null, View_SketchupComponent_0, RenderType_SketchupComponent)), i1.ɵdid(1, 114688, null, 0, i4.SketchupComponent, [i5.AuthService, i2.ActivatedRoute, i2.Router, i6.Restangular, i7.UserService, i8.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SketchupComponentNgFactory = i1.ɵccf("app-sketchup", i4.SketchupComponent, View_SketchupComponent_Host_0, {}, {}, []);
export { SketchupComponentNgFactory as SketchupComponentNgFactory };
