import {Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { ProjectSummary, Part, Slice, SliceFeature, Path, CirclePath, EllipsePath, LineArcPath } from '../../../target/fabber-model';
import { ProjectService } from '../services/project.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'ng2-ui-auth';


declare const SVG: any;

@Component({
  selector: 'app-view2d',
  templateUrl: './view2d.component.html',
  styleUrls: ['./view2d.component.scss']
})
export class View2dComponent implements OnInit, AfterViewInit {
  project: ProjectSummary;
  slices: Slice[];
  @ViewChild('view2d', { static: true }) view2d: ElementRef;
  @ViewChild('view2dcontainer', { static: true }) view2dcontainer: ElementRef;
  constructor(private projectService: ProjectService,
      private route: ActivatedRoute,
      public auth: AuthService) {
  }

  ngAfterViewInit() {
      this.route.params.subscribe(
          params => {
              this.projectService.get(params.id).subscribe(project => {
                  this.project = project;
                  this.projectService.getSlices(params.id, params.partid).subscribe(slices => {
                      this.slices = slices;
                      console.log(this.slices);
                      const draw = SVG('view2d').size(this.view2dcontainer.nativeElement.clientWidth,
                          this.view2dcontainer.nativeElement.clientHeight).panZoom();
                      // Now load the toolpath geometry for the part
                      if (this.slices.length > 0) {
                          const slice = this.slices[0];
                          const group = draw.group();
                          this.drawSlice(group, slice);
                          group.draggable();
                          const box = draw.viewbox();
                          const bbox = group.bbox();
                          console.log(box);
                          console.log(bbox);
                          bbox.x = box.w / 2 - (bbox.x + bbox.w / 2);
                          bbox.y = box.h / 2 - (bbox.y + bbox.h / 2);
                      }
                  });
              });
      });
  }

  drawSlice(target: any, slice: Slice) {
      const outer = this.drawPath(target, slice.outer);
      outer.stroke({ color: '#f06', width: 1, linecap: 'round', linejoin: 'round' });
      outer.opacity(0.5);
      const n = slice.features.length;
      for (let i = 0; i < n; i++) {
          this.drawFeature(target, slice.features[i]);
      }
  }

  drawPath(target: any, path: Path) {
      switch (path.type) {
          /*case 'circle':
              const circlePath = path as CirclePath;
              return target.circle(circlePath.r.valueOf() * 2).move(circlePath.x.valueOf(), circlePath.y.valueOf());
          case 'ellipse':
              const ellipsePath = path as EllipsePath;
              return target.ellipse(ellipsePath.rx.valueOf() * 2, ellipsePath.ry.valueOf() * 2).move(ellipsePath.x.valueOf(), ellipsePath.y.valueOf());*/
          case 'linearc':
              const lineArcPath = path as LineArcPath;
              return target.path(lineArcPath.path);
          default:
          // do nothing
      }
  }

  drawFeature(target: any, feature: SliceFeature) {
      const path = this.drawPath(target, feature.path);
      path.stroke({ color: '#f06', width: 1 });
  }

  ngOnInit() {

  }

}
