<div class="container">
    <div class="row">
        <div class="col-md-12"><img class="img-fluid d-block mx-auto" src="assets/fabber-1line-2color-bold-lowres.png" alt="Get Fabber!">
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div *ngIf="inProgress" class="px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center" style="">
            <h1>Sketchup to fabrication in seconds</h1>
            <p class="mb-3 lead" style="">Please wait while we connect Sketchup to Fabber...</p>
        </div>
        <div *ngIf="!inProgress && !loggedIn" class="px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center" style="">
            <h1>Sketchup to fabrication in seconds</h1>
            <p class="mb-3 lead" style="">Thank you for connecting Sketchup to Fabber. Signup or Login below to start
                uploading</p>
            <button class="btn btn-primary w-100 btn-lg" (click)="signup()">Let's go!</button>
            <br>
            <p class="mb-3 lead">Already a Fabber user? <a [routerLink]="" (click)="login()">Click here to login</a>.
            </p>
        </div>
        <div *ngIf="!inProgress && loggedIn" class="px-md-5 p-3 col-md-6 d-flex flex-column align-items-start justify-content-center" style="">
            <h1>Sketchup to fabrication in seconds</h1>
            <p class="mb-3 lead" style="">Thank you for connecting Sketchup to Fabber. Please return to Sketchup to start
                uploading.</p>
        </div>
        <div class="col-md-6" style=""></div>
    </div>
</div>
