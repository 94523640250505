// From https://stackoverflow.com/questions/34177221/angular2-how-to-inject-window-into-an-angular2-service
import { Injectable } from '@angular/core';


interface Sketchup {
    setToken(token: string);
    setProject(project: string);
    launchSignup();
    updateCAD();
    close();
}

interface IFabberWindow extends Window {
    sketchup: Sketchup;
}

function getWindow (): any {
    return window;
}

@Injectable()
export class SketchupService {

    constructor() {

    }

    get nativeWindow (): IFabberWindow {
        return getWindow();
    }

    inSketchup(): boolean {
        if (this.nativeWindow.sketchup) {
            // this.route.snapshot.queryParams.skp
            return true;
        }
        return false;
    }

    setToken(token: string) {
        this.nativeWindow.sketchup.setToken(token);
    }

    launchSignup() {
        this.nativeWindow.sketchup.launchSignup();
    }

    updateCAD() {
        console.log(this.nativeWindow.sketchup);
        this.nativeWindow.sketchup.updateCAD();
    }

    setProject(project_id: string) {
        this.nativeWindow.sketchup.setProject(project_id);
    }

    close() {
        this.nativeWindow.sketchup.close();
    }
}
