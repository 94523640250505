import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Machine } from '../../../target/fabber-model';
import {MachineService} from '../services/machine.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-material-picker',
  templateUrl: './material-picker.component.html',
  styleUrls: ['./material-picker.component.scss']
})
export class MaterialPickerComponent implements OnInit, OnChanges {

  @Input() machine: Machine;
  public materials: Observable<Array<string>>;
  public material: string;

  constructor(private machineService: MachineService) { }

  ngOnInit() {
    //this.materials = this.machineService.materials(this.machine);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('Machine changed');
    //this.materials = this.machineService.materials(changes.machine.currentValue);
  }
}
