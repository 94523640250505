import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {User, Application} from '../../../target/fabber-model';
import {Observable} from 'rxjs';
import {AuthService} from 'ng2-ui-auth';
import {UnitSet} from '../services/units/unit-set';
import {AlertService} from '../alert/alert.service';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    constructor(private userService: UserService,
                private auth: AuthService,
                private alertService: AlertService,
                private title: Title) {
    }

    user$: Observable<User>;
    inProgress = false;
    applications$: Observable<Application[]>;

    ngOnInit() {
        this.user$ = this.userService.me(false);
        this.applications$ = this.userService.applications();
        this.title.setTitle('Fabber : My Profile');
    }

    providerLink(provider: string) {
        this.inProgress = true;
        this.auth.authenticate(provider).subscribe({
            error: (err: any) => {
                this.inProgress = false;
                this.alertService.error(err);
            },
            complete: () => {
                this.inProgress = false;
                this.user$ = this.userService.me(true);
            }
        });
    }

    providerUnlink(provider: string) {
        this.inProgress = true;
        this.auth.unlink(provider).subscribe({
            error: (err: any) => {
                this.inProgress = false;
                this.alertService.error(err);
            },
            complete: () => {
                this.inProgress = false;
                this.user$ = this.userService.me(true);
            }
        });
    }

    saveUnits(units: UnitSet) {
        this.userService.saveUnits(units);
    }

    removeApp(app: Application) {
        this.userService.removeApplication(app.id).subscribe(() => this.applications$ = this.userService.applications());
    }
}
