import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {
    ProjectSummary,
    Part,
    CuttingList,
    CuttingMachineMaterial,
    SheetSize
} from '../../../target/fabber-model';
import {ProjectService} from '../services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'ng2-ui-auth';
import {DragulaService} from 'ng2-dragula';
import {debounceTime} from 'rxjs/operators';
import {UnitsService} from '../services/units/units.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {AlertService} from '../alert/alert.service';
import {Title} from '@angular/platform-browser';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';

@Component({
    selector: 'app-parts-list',
    templateUrl: './parts-list.component.html',
    styleUrls: ['./parts-list.component.scss']
})
export class PartsListComponent implements OnInit {
    projectId: string;
    project: ProjectSummary;
    parts: Part[];
    projectOff = 1;
    loading = false;
    saving = false;
    cuttingList: CuttingList;
    cuttingListChange = new EventEmitter<CuttingList>();
    cuttingListReady = false;
    collapseLeft = true;
    minimumSheetSize: SheetSize[];
    maximumBitRadii: string[];
    prompted = false;
    @ViewChild('manufacturePrompt', {static: true}) private manufacturePrompt: SwalComponent;

    constructor(private projectService: ProjectService,
                private route: ActivatedRoute,
                public auth: AuthService,
                private dragulaService: DragulaService,
                private unitsService: UnitsService,
                private loadingBar: LoadingBarService,
                private alertService: AlertService,
                private title: Title,
                private router: Router) {
    }

    ngOnInit() {
        this.projectId = this.route.snapshot.params.id;
        this.minimumSheetSize = new Array<SheetSize>();
        this.loading = true;
        this.loadingBar.start();
        this.projectService.get(this.projectId).subscribe({
            next: (project) => {
                this.project = project;
                this.title.setTitle(this.project.name + ' : Select Parts');
                this.projectService.getParts(this.projectId).subscribe({
                    next: (parts) => {
                        this.parts = parts;
                        this.projectService.getCuttingList(this.projectId).subscribe({
                            next: (cl) => {
                                this.cuttingList = cl;
                                this.projectOff = this.cuttingList.projectOff;
                                this.minimumSheetSize = this.getSheetSizes(cl);
                                this.maximumBitRadii = this.getBitMaximumRadii(cl);
                                this.loading = false;
                                this.loadingBar.stop();
                                this.cuttingListReady = this.ready(cl);
                            },
                            error: (err) => {
                                this.loading = false;
                                this.loadingBar.stop();
                                this.alertService.error(err.error);
                            }
                        });
                    }, error: (err) => {
                        this.loading = false;
                        this.loadingBar.stop();
                        this.alertService.error(err.error);
                    }
                });
            }, error: (err) => {
                this.loading = false;
                this.loadingBar.stop();
                this.alertService.error(err.error);
            }
        });
        this.dragulaService.drop('PARTS').subscribe({
            next: () => this.change(),
            error: (err) => this.alertService.error(err.error)
        });
        this.cuttingListChange.pipe(debounceTime(1000)).subscribe({
            next: (cl) => this.save(cl),
            error: (err) => this.alertService.error(err.error)
        });
        this.cuttingListChange.subscribe((cl) => {
            this.minimumSheetSize = this.getSheetSizes(cl);
            this.maximumBitRadii = this.getBitMaximumRadii(cl);
        });
    }

    addConfiguration() {
        const column = new CuttingMachineMaterial();
        column.instances = [];
        this.cuttingList.machines.push(column);
        this.change();
    }

    updateOff() {
        if (this.projectOff < 1) {
            this.projectOff = 1;
        }
        // Set each off (in a "being manufactured" column) to n*projectOff
        this.cuttingList.projectOff = this.projectOff;
        this.cuttingList.machines.forEach((column, i) => {
            if (i === 0) {
                column.instances.forEach((instance) => instance.off = 0);
            } else {
                column.instances.forEach((instance) => instance.off = instance.instances * this.projectOff);
            }
        });
        this.change();
    }

    updateMachineId(i: number, machineId: string) {
        this.cuttingList.machines[i].machineId = machineId;
        this.cuttingListChange.emit(this.cuttingList);
    }

    updateMaterial(i: number, material: string) {
        this.cuttingList.machines[i].material = material;
        this.cuttingListChange.emit(this.cuttingList);
    }

    updateBitId(i: number, bitId: string) {
        this.cuttingList.machines[i].bitId = bitId;
        this.cuttingListChange.emit(this.cuttingList);
    }

    change() {
        for (let i = 0; i < this.cuttingList.machines.length; i++) {
            let t: string;
            if (i > 0) {
                this.cuttingList.machines[i].instances.forEach(instance => {
                    if (instance.slice) {
                        if (t) {
                            t = '' + Math.max(this.unitsService.convertQuantity(t, 'm'),
                                this.unitsService.convertQuantity(instance.slice.thickness, 'm')) + ' m';
                        } else {
                            t = instance.slice.thickness;
                        }
                    }
                });
            }
            this.cuttingList.machines[i].thickness = t;
        }
        if (!this.loading) {
            console.log(this.cuttingList);
            this.cuttingListChange.emit(this.cuttingList);
        }
    }

    save(value: CuttingList) {
        console.log('SAVING');
        this.saving = true;
        this.loadingBar.start();
        this.projectService.saveCuttingList(this.projectId, value).subscribe({
            next: (cl) => {
                this.saving = false;
                this.loadingBar.stop();
                this.cuttingListReady = this.ready(cl);
                if (!this.prompted && this.cuttingListReady) {
                    this.manufacturePrompt.fire();
                }
            },
            error: (err) => {
                this.saving = false;
                this.loadingBar.stop();
                this.alertService.error(err.error);
            }
        });
    }

    deleteColumn(i: number) {
        // Move all instances one to the left
        this.cuttingList.machines[i].instances.forEach((instance) => {
            this.cuttingList.machines[i - 1].instances.push(instance);
        });
        this.cuttingList.machines.splice(i, 1);
        this.change();
    }

    reload() {
        this.loading = true;
        this.loadingBar.start();
        this.projectService.getCuttingList(this.projectId).subscribe((cl) => {
            this.cuttingList = cl;
            this.projectOff = this.cuttingList.projectOff;
            this.loading = false;
            this.loadingBar.stop();
        });
    }

    countInstances(i: number) {
        let n = 0;
        this.cuttingList.machines[i].instances.forEach((instance) => n += instance.off);
        return n;
    }

    ready(cuttingList: CuttingList): boolean {
        let res = true;
        if (!cuttingList) {
            return false;
        }
        cuttingList.machines.forEach((column, i) => {
            if (i > 0) {
                if (!(column.machineId && column.material && column.bitId
                    && column.sheetSize && column.sheetSize.width && column.sheetSize.length)) {
                    res = false;
                }
            }
        });
        return res;
    }

    updateSheetSize(i: number, $event: SheetSize) {
        // console.log('sheet size change to ' + $event.length + ' x ' + $event.width);
        this.cuttingList.machines[i].sheetSize = $event;
        console.log(this.cuttingList);
        this.cuttingListChange.emit(this.cuttingList);
    }

    getSheetSizes(cl: CuttingList): SheetSize[] {
        const out = new Array<SheetSize>();
        for (let i = 0; i < cl.machines.length; i++) {
            let ss = new SheetSize();
            ss.length = '0 m';
            ss.width = '0 m';
            if (i > 0) {
                this.cuttingList.machines[i].instances.forEach(instance => {
                    if (instance.slice && instance.slice.minimumSheetSize) {
                        ss = this.combineSheetSizes(ss, instance.slice.minimumSheetSize);
                    }
                });
            }
            out.push(ss);
        }
        return out;
    }

    getBitMaximumRadii(cl: CuttingList): string[] {
        const out = new Array<string>();
        for (let i = 0; i < cl.machines.length; i++) {
            let r = Number.POSITIVE_INFINITY;
            if (i > 0) {
                this.cuttingList.machines[i].instances.forEach(instance => {
                    if (instance.slice && instance.slice.minimumInternalRadius) {
                        r = Math.min(r, this.unitsService.convertQuantity(instance.slice.minimumInternalRadius, 'm'));
                    }
                });
            }
            out.push('' + r + ' m');
        }
        return out;
    }

    combineSheetSizes(a: SheetSize, b: SheetSize) {
        const length = Math.max(
            Math.max(this.unitsService.convertQuantity(a.length, 'm'), this.unitsService.convertQuantity(a.width, 'm')),
            Math.max(this.unitsService.convertQuantity(b.length, 'm'), this.unitsService.convertQuantity(b.width, 'm')));
        const width = Math.max(
            Math.min(this.unitsService.convertQuantity(a.length, 'm'), this.unitsService.convertQuantity(a.width, 'm')),
            Math.min(this.unitsService.convertQuantity(b.length, 'm'), this.unitsService.convertQuantity(b.width, 'm')));
        console.log('x');
        const ss = new SheetSize();
        ss.length = '' + length + ' m';
        ss.width = '' + width + ' m';
        return ss;
    }

    manufacture(yes: boolean): void {
        this.prompted = true;
        if (yes) {
            this.router.navigate(['/project', this.projectId, 'sheets']);
        }
    }
}
