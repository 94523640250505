<div class="container mt-1">
    <div class="row">
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg" [routerLink]="" (click)="reload()"><fa-icon [spin]="loading" [icon]="['fas', 'sync-alt']"></fa-icon></button>
        </div>
        <div class="col-md-6"></div>
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg float-right" [routerLink]="['/']">Go to my Projects</button>
        </div>
    </div>
</div>
<div class="container mt-2">
    <div class="row">
        <div class="col-md-6">
            <p class="lead">My Machines.</p>
            <p>Add and customize your machines here</p>
        </div>
        <div class="col-md-6">
            <p class="lead">My Bits.</p>
            <p>Add and customize your bits here</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <a id="add-machine-button" [routerLink]="" [swal]="{ title: 'Create new machine', input: 'text', inputValue: newMachineName, allowEnterKey: true }" (confirm)="add($event)" class="float-right mb-1 btn btn-primary btn-sm"><fa-icon [icon]="['fas', 'plus']"></fa-icon> Add Machine</a>
        </div>
        <div class="col-md-6">
            <a id="add-bit-button" [routerLink]="" [swal]="{ title: 'Create new bit', input: 'text', inputValue: newBitName, allowEnterKey: true }" (confirm)="addBit($event)" class="float-right mb-1 btn btn-primary btn-sm"><fa-icon [icon]="['fas', 'plus']"></fa-icon> Add Bit</a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 list-group">
            <li class="list-group-item list-group-item-action flex-column align-items-start" *ngFor="let machine of myMachines()">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{machine.name}}</h5>
                    <small><b>{{machine.brand}}</b> {{machine.model}}</small>
                </div>
                <p class="mb-1">Cutting area: {{machine.xLength | unit}} x {{machine.yLength | unit}}, with maximum z height of {{machine.zHeight | unit}}.</p>
                <p class="mb-1">Spindle speed {{machine.minSpindleSpeed | unit}} to {{machine.maxSpindleSpeed | unit}}.</p>
                <div class="btn-group float-right" role="group">
                    <button type="button" class="btn btn-primary btn-sm" placement="top" ngbTooltip="Edit {{machine.name}}" [routerLink]="['/machines', machine.id]"><fa-icon [icon]="['fas', 'edit']"></fa-icon></button>
                    <button type="button" class="btn btn-primary btn-sm" (click)="copy(machine)"><fa-icon [icon]="['fas', 'copy']"></fa-icon></button>
                    <button type="button" class="btn btn-danger btn-sm" [swal]="deleteSwal"><fa-icon [icon]="['fas', 'trash-alt']"></fa-icon></button>
                    <swal
                        #deleteSwal
                        title="Delete {{ machine.name }}?"
                        text="This cannot be undone"
                        type="question"
                        [showCancelButton]="true"
                        [focusCancel]="true"
                        (confirm)="remove(machine)">
                    </swal>
                </div>
                <!--<small>Last edited ?</small>-->
            </li>
        </div>
        <div class="col-md-6 list-group">
            <li class="list-group-item list-group-item-action flex-column align-items-start" *ngFor="let bit of myBits()">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{bit.name}}</h5>
                    <small><b>Diameter</b> {{bit.diameter | unit}}</small>
                </div>
                <div class="btn-group float-right" role="group">
                    <button type="button" class="btn btn-primary btn-sm" [routerLink]="['/bits', bit.id]"><fa-icon [icon]="['fas', 'edit']"></fa-icon></button>
                    <button type="button" class="btn btn-primary btn-sm" (click)="copyBit(bit)"><fa-icon [icon]="['fas', 'copy']"></fa-icon></button>
                    <button type="button" class="btn btn-danger btn-sm" [swal]="deleteSwal"><fa-icon [icon]="['fas', 'trash-alt']"></fa-icon></button>
                    <swal
                        #deleteSwal
                        title="Delete {{ bit.name }}?"
                        text="This cannot be undone"
                        type="question"
                        [showCancelButton]="true"
                        [focusCancel]="true"
                        (confirm)="removeBit(bit)">
                    </swal>
                </div>
                <!--<small>Last edited ?</small>-->
            </li>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-6">
            <p class="lead">Public Machines.</p>
            <p>Use our standard machine configurations.</p>
        </div>
        <div class="col-md-6">
            <p class="lead">Public Bits.</p>
            <p>Use our standard bit configurations.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 list-group">
            <li class="list-group-item list-group-item-action flex-column align-items-start" *ngFor="let machine of publicMachines()">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{machine.name}}</h5>
                    <small><b>{{machine.brand}}</b> {{machine.model}}</small>
                </div>
                <p class="mb-1">Cutting area: {{machine.xLength | unit}} x {{machine.yLength | unit}}, with maximum z height of {{machine.zHeight | unit}}.
                <br>Spindle speed {{machine.minSpindleSpeed | unit}} to {{machine.maxSpindleSpeed | unit}}.
                <br>Cutting speed {{machine.minCuttingSpeed | unit}} to {{machine.maxCuttingSpeed | unit}}.</p>
                <div class="btn-group float-right" role="group">
                    <button type="button" class="btn btn-primary btn-sm" placement="top" ngbTooltip="View {{machine.name}}" [routerLink]="['/machines', machine.id]"><fa-icon [icon]="['fas', 'eye']"></fa-icon></button>
                    <button type="button" class="btn btn-primary btn-sm" placement="top" ngbTooltip="Duplicate {{machine.name}}" (click)="copy(machine)"><fa-icon [icon]="['fas', 'copy']"></fa-icon></button>
                </div>
                <!--<small>Last edited ?</small>-->
            </li>
        </div>
        <div class="col-md-6 list-group">
            <li class="list-group-item list-group-item-action flex-column align-items-start" *ngFor="let bit of publicBits()">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{bit.name}}</h5>
                </div>
                <p class="mb-1">Materials: {{materialNames(bit)}}.
                <br>Diameter: {{bit.diameter | unit}} ({{bit.shaftDiameter | unit}} at shaft).</p>
                <div class="btn-group float-right" role="group">
                    <button type="button" class="btn btn-primary btn-sm" placement="top" ngbTooltip="View {{bit.name}}" [routerLink]="['/bits', bit.id]"><fa-icon [icon]="['fas', 'eye']"></fa-icon></button>
                    <button type="button" class="btn btn-primary btn-sm" placement="top" ngbTooltip="Duplicate {{bit.name}}" (click)="copyBit(bit)"><fa-icon [icon]="['fas', 'copy']"></fa-icon></button>
                </div>
                <!--<small>Last edited ?</small>-->
            </li>
        </div>
    </div>
</div>
