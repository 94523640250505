import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';
import {Bit, BitMaterial, MaterialChoice} from '../../../target/fabber-model';
import {MachineService} from '../services/machine.service';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import {AlertService} from '../alert/alert.service';

@Component({
    selector: 'app-bit-editor',
    templateUrl: './bit-editor.component.html',
    styleUrls: ['./bit-editor.component.scss']
})
export class BitEditorComponent implements OnInit {
    loading: boolean;
    bit$: Observable<Bit>;
    newMaterial: BitMaterial;
    allMaterials: string[];

    constructor(private machineService: MachineService, private route: ActivatedRoute, private location: Location, private alertService: AlertService) {
        this.newMaterial = new BitMaterial();
        this.allMaterials = new Array<string>();
    }

    ngOnInit() {
        this.loading = false;
        this.bit$ = this.machineService.getBit(this.route.snapshot.params.id);
        this.machineService.allMaterials().subscribe(mats => mats.forEach(mat => this.allMaterials.push(mat.material)));
    }

    addMaterial(bit: Bit) {
        bit.materialSettings.push(this.newMaterial);
        this.newMaterial = new BitMaterial();
    }

    editable(bit: Bit): boolean {
        return bit.owner != null;
    }

    save(bit: any) {
        bit.save().subscribe({
            next: () => this.location.back(),
            error: (err) => this.alertService.error(err)
        });
    }

    cancel() {
        this.location.back();
    }

    deleteMaterial(bit: Bit, material: BitMaterial) {
        bit.materialSettings.splice(bit.materialSettings.indexOf(material), 1);
    }

}
