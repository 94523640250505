import { Restangular } from 'ngx-restangular';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'ng2-ui-auth';
import { UnitsService } from './units/units.service';
import { Intercom } from 'ng-intercom';
import * as i0 from "@angular/core";
import * as i1 from "ngx-restangular";
import * as i2 from "ng2-ui-auth";
import * as i3 from "./units/units.service";
import * as i4 from "ng-intercom";
function getWindow() {
    return window;
}
export class UserService {
    constructor(restangular, auth, unitsService, intercom) {
        this.restangular = restangular;
        this.auth = auth;
        this.unitsService = unitsService;
        this.intercom = intercom;
    }
    get nativeWindow() {
        return getWindow();
    }
    me(force) {
        if (!force && this.meValue) {
            return of(this.meValue);
        }
        return this.restangular.one('users', 'me').get().pipe(map((user) => {
            this.meValue = user;
            this.setAnalyticsUserId(this.meValue.id);
            this.intercom.update({
                app_id: 't7tq0zk6',
                email: this.meValue.email,
                name: this.meValue.name,
                user_id: this.meValue.id,
                created_at: this.meValue.signup_date,
                fabberVersion: (!window['fabberVersion'] || window['fabberVersion'] === '${VERSION}') ? 'dev' : window['fabberVersion']
            });
            this.unitsService.setWorkingUnits(user.units);
            return user;
        }));
    }
    setAnalyticsUserId(id) {
        this.nativeWindow.gtag('config', 'UA-131430495-1', { 'send_page_view': false, 'user_id': id });
    }
    signup() {
        return this.auth.authenticate('memberfulsignup');
    }
    saveUnits(units) {
        this.meValue.units = units;
        this.unitsService.setWorkingUnits(units);
        return this.restangular.one('users', 'me').customPUT(this.meValue);
    }
    applications() {
        return this.restangular.one('users', 'me').all('applications').getList();
    }
    removeApplication(id) {
        return this.restangular.one('users', 'me').one('applications', id).remove();
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.Restangular), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.UnitsService), i0.ɵɵinject(i4.Intercom)); }, token: UserService, providedIn: "root" });
