import {SVGLoader} from 'three/examples/jsm/loaders/SVGLoader';
import {CirclePath, EllipsePath, LineArcPath, PathUnion} from '../../../target/fabber-model';
import {Path, Shape, ShapePath} from 'three';


export class Paths {

    static toPaths(path: PathUnion | PathUnion[]): Path[] {
        return [].concat(this.toShapePaths(path)
            .map(shapePath => shapePath.subPaths));
    }

    static toShapes(path: PathUnion | PathUnion[]): Shape[] {
        return [].concat(this.toShapePaths(path)
            .map(shapePath => shapePath.toShapes(false, false)));
    }

    static toShapePaths(path: PathUnion | PathUnion[]): ShapePath[] {
        return new SVGLoader().parse(this.toSVG(path)).paths;
    }

    static toSVG(path: PathUnion | PathUnion[]): string {
        return '<svg>\n\t'
            + (Array.isArray(path)
                    ? (path as PathUnion[]).map(subpath => this.toSVGElement(subpath)).join('\n\t')
                    : this.toSVGElement(path as PathUnion)
            )
            + '\n</svg>';
    }

    static toSVGElement(path: PathUnion): string {
        if (path instanceof CirclePath) {
            const circlePath = path as CirclePath;
            return `<circle cx="${circlePath.x}" cy="${circlePath.y}" r="${circlePath.r}"/>`;
        } else if (path instanceof EllipsePath) {
            const ellipsePath = path as EllipsePath;
            return `<ellipse cx="${ellipsePath.x}" cy="${ellipsePath.y}" rx="${ellipsePath.rx}" ry="${ellipsePath.ry}"/>`;
        } else {
            const lineArcPath = path as LineArcPath;
            return `<path d="${lineArcPath.path}"/>`;
        }
    }

}
