import { Router } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';
import { Machine, Bit, MaterialChoice, MaterialSheet, PostProcessor } from '../../../target/fabber-model';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "ngx-restangular";
import * as i3 from "./user.service";
export class MachineService {
    constructor(router, restangular, userService) {
        this.router = router;
        this.restangular = restangular;
        this.userService = userService;
    }
    all() {
        return this.restangular.all('machines').getList();
    }
    allBits() {
        return this.restangular.all('bits').getList();
    }
    allMaterials() {
        return this.restangular.all('materials').getList();
    }
    get(id) {
        return this.restangular.one('machines', id).get();
    }
    copy(machine) {
        return this.userService.me(false).pipe(mergeMap((me) => {
            const newMachine = Machine.fromData(machine);
            newMachine.id = null;
            newMachine.owner = me.id;
            newMachine.name = machine.name + ' - Copy';
            return this.add(newMachine);
        }));
    }
    copyBit(bit) {
        return this.userService.me(false).pipe(mergeMap((me) => {
            const newBit = Bit.fromData(bit);
            newBit.id = null;
            newBit.owner = me.id;
            newBit.name = bit.name + ' - Copy';
            return this.addBit(newBit);
        }));
    }
    remove(id) {
        return this.restangular.one('machines', id).remove();
    }
    removeBit(id) {
        return this.restangular.one('bits', id).remove();
    }
    add(machine) {
        return this.restangular.all('machines').post(machine);
    }
    addBit(bit) {
        return this.restangular.all('bits').post(bit);
    }
    brands() {
        return this.all().pipe(map(machines => machines.map(m => m.brand).filter((m, i, ms) => ms.indexOf(m) === i)));
    }
    bits(machineId) {
        return this.restangular.one('machines', machineId).customGET('bits');
    }
    materials(machineId) {
        return this.restangular.one('machines', machineId).customGET('materials');
    }
    getBit(bitId) {
        return this.restangular.one('bits', bitId).get();
    }
    allMaterialSizes() {
        return this.restangular.all('materials').all('sizes').getList();
    }
    materialSizes(material) {
        return this.restangular.all('materials').all('sizes').all(material).getList();
    }
    getPostProcessor(id) {
        return this.restangular.one('postprocessors', id).get();
    }
    getPostProcessors() {
        return this.restangular.all('postprocessors').getList();
    }
}
MachineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MachineService_Factory() { return new MachineService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Restangular), i0.ɵɵinject(i3.UserService)); }, token: MachineService, providedIn: "root" });
