<div class="container mt-1">
    <div class="row">
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg" [routerLink]="['users']">Manage Users</button>
        </div>
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg" [routerLink]="['invitations']">Manage Invitations</button>
        </div>
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg" [routerLink]="['files']">Manage Files</button>
        </div>
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg" [routerLink]="['tasks']">Manage Tasks</button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg" [routerLink]="['machines']">Manage Machines</button>
        </div>
        <div class="col-md-3">
            <button class="btn btn-primary btn-lg" [routerLink]="['projects']">Manage Projects</button>
        </div>
        <div class="col-md-3">
        </div>
        <div class="col-md-3">
        </div>
    </div>
</div>
<router-outlet></router-outlet>