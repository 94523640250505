/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toolbar.component";
var styles_ToolbarComponent = [i0.styles];
var RenderType_ToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarComponent, data: {} });
export { RenderType_ToolbarComponent as RenderType_ToolbarComponent };
export function View_ToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "three-tools-toolbar btn-toolbar"], ["role", "toolbar"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "toolbar-left": 0, "toolbar-right": 1, "toolbar-bottom": 2, "toolbar-top": 3 }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "btn-group btn-group-justified"], ["role", "group"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "btn-group": 0, "flex-column": 1 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "three-tools-toolbar btn-toolbar"; var currVal_1 = _ck(_v, 3, 0, (_co.placement == "Left"), (_co.placement == "Right"), (_co.placement == "Bottom"), (_co.placement == "Top")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "btn-group btn-group-justified"; var currVal_3 = _ck(_v, 7, 0, ((_co.placement == "Top") || (_co.placement == "Bottom")), ((_co.placement == "Left") || (_co.placement == "Right"))); _ck(_v, 6, 0, currVal_2, currVal_3); }, null); }
export function View_ToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "three-toolbar", [], null, null, null, View_ToolbarComponent_0, RenderType_ToolbarComponent)), i1.ɵdid(1, 114688, null, 1, i3.ToolbarComponent, [], null, null), i1.ɵqud(603979776, 1, { tools: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarComponentNgFactory = i1.ɵccf("three-toolbar", i3.ToolbarComponent, View_ToolbarComponent_Host_0, { placement: "placement" }, {}, ["*"]);
export { ToolbarComponentNgFactory as ToolbarComponentNgFactory };
