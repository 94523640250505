/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./projects.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@sweetalert2/ngx-sweetalert2";
import * as i5 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i6 from "@fortawesome/angular-fontawesome";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../node_modules/@sweetalert2/ngx-sweetalert2/sweetalert2-ngx-sweetalert2.ngfactory";
import * as i9 from "../services/moment.pipe";
import * as i10 from "./projects.component";
import * as i11 from "../services/project.service";
import * as i12 from "ng2-ui-auth";
import * as i13 from "../alert/alert.service";
var styles_ProjectsComponent = [i0.styles];
var RenderType_ProjectsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectsComponent, data: {} });
export { RenderType_ProjectsComponent as RenderType_ProjectsComponent };
function View_ProjectsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "card"], ["style", "width: 18rem;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "card-img-top"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", "btn btn-primary"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 2), (_l()(), i1.ɵted(-1, null, ["Open project"])), (_l()(), i1.ɵeld(9, 16777216, null, null, 5, "a", [["class", "btn btn-danger float-right"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], null, null), i1.ɵdid(11, 212992, null, 0, i4.SwalDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver], { swal: [0, "swal"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i5.View_FaIconComponent_0, i5.RenderType_FaIconComponent)), i1.ɵdid(13, 573440, null, 0, i6.FaIconComponent, [i7.DomSanitizer, i6.FaConfig, i6.FaIconLibrary, [2, i6.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(14, 2), (_l()(), i1.ɵeld(15, 0, null, null, 1, "swal", [["text", "This cannot be undone"], ["type", "question"]], null, [[null, "confirm"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("confirm" === en)) {
        var pd_0 = (_co.deleteProject(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_SwalComponent_0, i8.RenderType_SwalComponent)), i1.ɵdid(16, 770048, [["deleteSwal", 4]], 0, i4.SwalComponent, [i4.SweetAlert2LoaderService, i4.ɵc], { title: [0, "title"], text: [1, "text"], showCancelButton: [2, "showCancelButton"], focusCancel: [3, "focusCancel"] }, { confirm: "confirm" }), (_l()(), i1.ɵeld(17, 0, null, null, 3, "div", [["class", "card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "small", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["Last edited: ", ""])), i1.ɵppd(20, 1)], function (_ck, _v) { var currVal_5 = _ck(_v, 7, 0, "/project", _v.context.$implicit.id); _ck(_v, 6, 0, currVal_5); var currVal_8 = i1.ɵnov(_v, 16); _ck(_v, 11, 0, currVal_8); var currVal_11 = _ck(_v, 14, 0, "fas", "trash-alt"); _ck(_v, 13, 0, currVal_11); var currVal_12 = i1.ɵinlineInterpolate(1, "Delete ", _v.context.$implicit.name, "?"); var currVal_13 = "This cannot be undone"; var currVal_14 = true; var currVal_15 = true; _ck(_v, 16, 0, currVal_12, currVal_13, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "/api/projects/", _v.context.$implicit.id, ".png?token=", _co.auth.getToken(), ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 6).target; var currVal_4 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 10).target; var currVal_7 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 13).title; var currVal_10 = i1.ɵnov(_v, 13).renderedIconHTML; _ck(_v, 12, 0, currVal_9, currVal_10); var currVal_16 = i1.ɵunv(_v, 19, 0, _ck(_v, 20, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.edited)); _ck(_v, 19, 0, currVal_16); }); }
export function View_ProjectsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i9.MomentPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "container mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["class", "btn btn-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.reload() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i5.View_FaIconComponent_0, i5.RenderType_FaIconComponent)), i1.ɵdid(7, 573440, null, 0, i6.FaIconComponent, [i7.DomSanitizer, i6.FaConfig, i6.FaIconLibrary, [2, i6.FaStackItemSizeDirective]], { icon: [0, "icon"], spin: [1, "spin"] }, null), i1.ɵpad(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["class", "btn btn-primary btn-lg float-right"], ["id", "machines-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), (_l()(), i1.ɵted(-1, null, ["Go to my Machines"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "container card-columns mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectsComponent_1)), i1.ɵdid(17, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 8, 0, "fas", "sync-alt"); var currVal_3 = _co.loading; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _ck(_v, 13, 0, "/machines"); _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.projects; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).title; var currVal_1 = i1.ɵnov(_v, 7).renderedIconHTML; _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_ProjectsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-projects", [], null, null, null, View_ProjectsComponent_0, RenderType_ProjectsComponent)), i1.ɵdid(1, 114688, null, 0, i10.ProjectsComponent, [i11.ProjectService, i12.AuthService, i13.AlertService, i7.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectsComponentNgFactory = i1.ɵccf("app-projects", i10.ProjectsComponent, View_ProjectsComponent_Host_0, {}, {}, []);
export { ProjectsComponentNgFactory as ProjectsComponentNgFactory };
