import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'ng2-ui-auth';
import { FormHelperService } from '../services/form-helper.service';
import { Gtag } from 'angular-gtag';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {AlertService} from '../alert/alert.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  inProgress: boolean;
  form: FormGroup;

  constructor(private auth: AuthService,
      private router: Router,
      private route: ActivatedRoute,
      private fb: FormBuilder,
      public fh: FormHelperService,
      private gtag: Gtag,
      private loadingBar: LoadingBarService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.inProgress = false;
    this.form = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.minLength(3)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
  }

  login(loginData: { email: string; password: string }) {
    this.inProgress = true;
    this.loadingBar.start();
    this.auth.login(loginData).subscribe({
      error: (err: any) => {
          this.inProgress = false;
          this.loadingBar.complete();
          this.alertService.error(err);
      },
      complete: () => this.navigateAfterLogin()
    });
  }

  providerLogin(provider: string) {
    this.inProgress = true;
    this.auth.authenticate(provider).subscribe({
      error: (err: any) => {
        this.inProgress = false;
        this.loadingBar.complete();
        this.alertService.error(err);
      },
      complete: () => this.navigateAfterLogin()
    });
  }

  navigateAfterLogin() {
      // TODO: This code is awful Javascript, but can't work out Typescript equivalent
    const otherParams = {};
    let url = this.route.snapshot.queryParamMap['params']['to'];
    Object.keys(this.route.snapshot.queryParamMap['params']).forEach((key) => {
      if ('to' !== key) {
        otherParams[key] = this.route.snapshot.queryParamMap['params'][key];
      }
    });
    if (!url) {
      url = '';
    }
    this.loadingBar.complete();
    this.router.navigate([url], {queryParams: otherParams});
  }
}

